import React from "react";

export default function ProfileAccount(props) {
  var loggedUserData = JSON.parse(localStorage.getItem("userData"));

  return (
    <React.Fragment>
      <div className="my_profile">
        <div className="my_profile_center">
          <label className="lbl_my_profile">{props.headerValue}</label>
          <div className="user_profile_info">
            <span className="hello_user">
              Hello,{" "}
              <span
                className="user_firstname"
                value={loggedUserData?.first_name}
              >
                {loggedUserData?.first_name?.toUpperCase()}{" "}
              </span>{" "}
            </span>
            <span className="username" value={loggedUserData?.user_name}>
              {loggedUserData?.user_name}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
