import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../user-menu/UserMenu.css";
import { signOut } from "@aws-amplify/auth";
import UseLoading from "../../hooks/UseLoading";
import ApiContext from "../../providers/Api/ApiContext";
import UserContext from "../../providers/User/UserContext";

const UserMenu = React.forwardRef((props, ref) => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [userId, setUserId] = useState([]);
  const [loader, showLoader, hideLoader] = UseLoading();
  const goToThisPage = async (e) => {
    var path = e.target.getAttribute("data-path");
    var mode = e.target.getAttribute("data-mode");
    if (mode === "logout") {
      showLoader();
      localStorage.setItem("user_status", "unlogged");
      //Added setTimeout because of aws-amplify lib issue link: https://github.com/aws-amplify/amplify-js/issues/10198
      setTimeout(async () => {
        try {
          setUser(null);
          await signOut();

          // resetAxios();
          // setIsAuthenticated(false);
          hideLoader();
          navigate("/index");
        } catch (error) {
          hideLoader();
          console.log("error signing out: ", error);
        }
      });
    } else {
      navigate("/" + path + "");
    }
  };

  useEffect(() => {
    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (loggedUserData !== null) {
      var userId = loggedUserData.user_id;
      setUserId(userId);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="user_menu_center position-relative">
        <div
          ref={ref}
          className="user_menu"
          style={props.displayMenu ? { display: "block" } : { display: "none" }}
        >
          <button
            className="btn_myprofile header_label"
            onClick={goToThisPage}
            data-path="profile"
          >
            {!props.labels.my_profile
              ? "My Profile"
              : props.labels.my_profile.label_name}
          </button>
          <button
            className="btn_myaccount header_label"
            onClick={goToThisPage}
            data-path="account"
          >
            {!props.labels.my_account
              ? "My Account"
              : props.labels.my_account.label_name}
          </button>
          <button
            className="btn_safergabmling header_label"
            onClick={goToThisPage}
            data-path="safer_gambling?type=gambling"
          >
            {!props.labels.safer_gambling
              ? "Safer Gambling"
              : props.labels.safer_gambling.label_name}
          </button>
          <button
            className="btn_logout header_label"
            onClick={goToThisPage}
            data-path="index"
            data-mode="logout"
          >
            {!props.labels.logout ? "Log Out" : props.labels.logout.label_name}
          </button>
        </div>
      </div>
      {loader}
    </React.Fragment>
  );
});

export default UserMenu;
