import React from "react";
import "./oddsImage.css";
function Section2() {
  return (
    <div className="primary-background ">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-center py-4 gap-5">
        <div className="d-flex flex-column text-white gap-2">
          <h2 className="w-100 w-md-75 text-center text-md-start font-weight-bold oddsimage-title">
            Stay Updated for Accurate Predictions
          </h2>
          <p className="w-100 w-md-75 text-center text-md-start">
            Click the (i) for all the latest match news available to help you make correct
            prediction.
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <img
            src="assets/image/home/challange7-new1.png"
            className="phone-image"
            // style={{  maxHeight: "570px" , height:"100%" , width:"100%" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Section2;
