import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import SportsBanner from "../home/SportsBanner";
import { Helmet } from "react-helmet";
import Footer from "../footer/Footer";
import UseLoading from "../../hooks/UseLoading";
import UseAlert from "../../hooks/UseAlert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiContext from "../../providers/Api/ApiContext";
import UserContext from "../../providers/User/UserContext";
import StaticSportsBanner from "../home/StaticSportsBanner";
import LoginHeader from "../login/LoginHeader";

const Leagues = () => {
  const { api, soccerApi } = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const [loader, showLoader, hideLoader] = UseLoading();
  const [alert, alert_notify] = UseAlert();
  const [leagues, setLeagues] = useState([]);
  const [leagueId, setLeagueId] = useState(null);
  const { sportId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    secondLandingPage();
  }, [sportId]);
  const secondLandingPage = () => {
    if (sportId == 1) {
      var leagues_url = `get_leagues`;
      showLoader();
      soccerApi
        .get(leagues_url)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.content) {
              console.log(response.data.data.content);
              setLeagues(response.data.data.content);
            }
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Error);
          }
          hideLoader();
        });
    } else {
      var secondLandingUrl = `/api/second_landing_page/get_leagues`;
      showLoader();
      api
        .post(secondLandingUrl, { sport_id: sportId })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.content) {
              console.log(response.data.data.content);
              setLeagues(response.data.data.content);
            }
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
            localStorage.removeItem("session_key");
          } else {
            alert_notify("error", err.response.data.Error);
          }
          hideLoader();
        });
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor</title>
        <meta
          name="description"
          content="Sports Newest and Most Interactive Prediction Game"
        />
      </Helmet>
      {user ? <Header showSports={true} /> : <LoginHeader />}
      {user ? <SportsBanner activeSport={state?.id} /> : <SportsBanner activeSport={state?.id} adjustPaddingForSmallDevices={true} paddingTop={100}/>}
      <div className="primary-background">
        <div className="page_content_center p-4">
          {state?.sport_name ? (
            <h1 className="coming_soon_text" style={{ fontSize: "25px" }}>
              Select League
            </h1>
          ) : null}
          {leagues.length > 0 ? (
            <div className="p-5">
              {leagues.map((league) => {
                return (
                  <h1
                    className="display_name_text fs-5 py-1"
                    onClick={(e) =>
                      navigate(`/sports_leagues/${sportId}/${league.league_id}`)
                    }
                  >
                    {league.league_name == "SERIE A" &&
                    league.league_country == "BRAZIL"
                      ? `${league.league_country} ${league.league_name}`
                      : league.league_name}
                  </h1>
                );
              })}
            </div>
          ) : (
            <div className="p-4 h-30">
              <h1 className="text-center coming_soon_text fs-1">
                Competitions Coming Soon...
              </h1>
            </div>
          )}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
};

export default Leagues;
