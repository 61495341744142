import React, { useState, useEffect, useContext } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import LoginHeader from "../login/LoginHeader";
import "./TermsConditions.css";
import Parser from "html-react-parser";
import useLoading from "../../hooks/UseLoading";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";
import ApiContext from "../../providers/Api/ApiContext";

export default function TermsConditions() {
  const { api } = useContext(ApiContext);
  const [loggedStatus, setLoggedStatus] = useState("");
  const [termsContentText, setTermsContentText] = useState("");
  const [termsName, setTermsName] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();

  useEffect(() => {
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }

    var terms_url = "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "terms_and_condition" };
    showLoader();
    api
      .post(terms_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var page_content = data.content.page_content;
          setTermsContentText(page_content);
          var page_name = data.content.page_name;
          setTermsName(page_name);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | Terms and Conditions</title>
      </Helmet>
      {loggedStatus === "logged" ? <Header /> : <LoginHeader />}
      <ImageContainer />
      <div className="terms_and_conditions_container row m-0 justify-content-between">
        <div className="page_content_center">
          <div className="terms_and_conditions_title col-md-12">
            <h2 className="text-uppercase">{Parser(termsName)}</h2>
          </div>
          {/* <div className="terms_and_conditions_content col-md-12">
                        <span className="terms_and_conditions_content_tittle">Definitions and Interpretations</span>
                        <ol className="mt-3">
                                <li className="li_title">
                                    The following definitions can be found in the relevant paragraphs, or as set out below.
                                    <ul className="terms_conditions_ul">
                                        <li>
                                            Account: A User Account Registered On The Site;
                                        </li>
                                        <li>
                                            An Event Outside Our Control: See Paragraph 13.7;   
                                        </li>
                                        <li>
                                            Application: See Paragraph 5.1;
                                        </li>
                                        <li>
                                            Bonus Abuse: See Paragraph 7.4;
                                        </li>
                                        <li>
                                            CEDR: See Paragraph 19.7;
                                        </li>
                                        <li>
                                            Competition: A Skill-Based Pool Betting Competition Made Available To Players On The Site In Which Players Are Able To Make Predictions On The Outcome Of Matches Using Their Knowledge And The Information Provided On The Relevant Gamecard For A Chance To Win Prizes;
                                        </li>
                                        <li>
                                            Gambling Commission: The Gambling Commission Of Great Britain;
                                        </li>
                                        <li>
                                            Gamecard: See Paragraph 3.3;
                                        </li>
                                        <li>
                                            IBAS: See Paragraph 18.3;
                                        </li>
                                        <li>
                                            Inactive Account: See Paragraph 5.14;
                                        </li>
                                        <li>
                                            Match A Sporting Event Forming The Basis Of A Prediction Within A Competition;
                                        </li>
                                        <li>
                                            Privacy Policy: See Paragraph 4.1;
                                        </li>
                                        <li>
                                            Self-Exclusion: See Paragraph 10.3;
                                        </li>
                                        <li>
                                            Services: The Pool Betting And Information Services That We Provide On The Site;
                                        </li>
                                        <li>
                                            Site: The Toppredictor.Com Website
                                        </li>
                                        <li>
                                            Taxes And Duties: See Paragraph 8.4;
                                        </li>
                                        <li>
                                            Terms: These Terms And Conditions As Updated By Us From Time To Time;
                                        </li>
                                        <li>
                                            Top Predictor, We, Us, Our: See Paragraph 2.1;
                                        </li>
                                        <li>
                                            Transaction Log: An Automated Ledger Of All Competition Entries; And
                                        </li>
                                        <li>
                                            Wallet: The E-Wallet Attached To Your Account.
                                        </li>
                                    </ul>
                                </li>
                                <li className="li_title">
                                    Unless the context otherwise requires, words in the singular shall include the plural and in the plural shall include the singular.
                                </li>
                                <li className="li_title">
                                    Any words following the terms including, include, in particular, for example or any similar expression shall be construed as illustrative and shall not 
                                    limit the sense of the words, description, definition, phrase or term preceding those terms.
                                </li>
                                <li className="li_title">
                                    A reference to writing or written includes email but not fax.
                                </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Introduction</span>
                        <ol className="mt-3 pl-2">
                            <li>
                                TPG Technology Limited (Top Predictor, we, us, our) is a company registered in England with registration number 09770596 whose registered office is at 107 Cleethorpe Road, Grimsby, DN31 3ER, England, United Kingdom
                            </li>
                            <li>
                                This website is operated under license by Sunseven NV (#100554), E-Commerce park, Willemstad, Curacao under the license No. 8048/JAZ issued by Antillephone, authorised and regulated by the Government of Curacao. Management and compliance services are provided through GamblingTec.com which is a brand of Sunseven NV.
                            </li>
                            <li>
                                We operate the Site. These Terms govern all access to and/or use of the Site. All references to the Site in these Terms shall apply equally to any “Top Predictor” mobile application as introduced by us from time to time.
                            </li>
                            <li>
                                In making use of the Site, you confirm that you have read and understood these Terms (including any documents expressly referred to herein) and you agree to be legally bound by them in relation to your use of the Site and your receipt of the Services. If you refuse to accept these Terms, you will be prohibited from opening an Account, entering
                            </li>
                            <li>
                            Each time you use the Site, the Terms in force at that time will apply. As such, you should check these Terms (via the link on the Site) each time you make use of the Services.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Entering Competitions</span>
                        <ol className="mt-3 pl-2">
                            <li>
                            The Site provides Players with the opportunity to enter Competitions (determined by the outcome of sporting Matches) subject to these Terms.
                            </li>
                            <li>
                            In order to enter a Competition you will need to have sufficient funds in your Wallet to be able to pay the entry fee stated (except where the Competition is free-to-play). In making a decision whether to enter a Competition you should consider your financial status and all your financial commitments. It is your responsibility to ensure that you can afford to enter our Competitions. Internet gambling may be illegal in the jurisdiction in which you are located, if so you are not authorised to use your payment card to complete transactions. 
                            </li>
                            <li>
                            A gamecard showing the teams or individuals participating in each of the selected Matches (Gamecard) will be published on the Site for each Competition.
                            </li>
                            <li>
                            You are able to select a Competition and enter it by making the predictions on the relevant Gamecard and paying the stated entry fee, following the instructions set out on the Site.
                            </li>
                            <li>
                            In addition to these Terms, Competitions will usually also be subject to a particular set of Game Rules (relating to the relevant sport or series of Matches). These will be linked to on the Competition page and you should ensure you understand and accept these in full before entering the Competition.
                            </li>
                            <li>
                            In order to win a Competition, you must accumulate the most points in that Competition. Points are awarded to Players for correctly predicting Match outcomes. Points are accumulated through the course of each Competition to determine the leaders (while the Competition is live) and the winners (at the end of each Competition).
                            </li>
                            <li>
                            Match results will be as determined by the official sporting body for the relevant sport and/or Match (for example FIFA in respect of the FIFA World Cup). If no official result from a relevant sporting body is available, the result will be determined by Top Predictor using information from independent sources.
                            </li>
                            <li>
                            Top Predictor reserves the right to suspend settlement of any Competition for any reason, and to declare any Competition or match/leg therein null and void.
                            </li>
                            <li>
                            Top Predictor also reserves the right to resettle a Competition if it has been settled in error, be that human or technical. All Players will be liable for any 'negative' balances they may accrue as a result of a resettlement.
                            </li>
                            <li>
                            In the event that more than one Player (in a prize-winning position) has accumulated the same number of points at the end of a Competition, the relevant prize(s) will be split. The allocated prize money, and no more, will be split between the relevant Players. For example, if the three highest-scoring Players finish a Competition with the same number of points, the prizes allocated for 1st, 2nd and 3rd will be added together, and split evenly between those three Players.
                            </li>
                            <li>
                            Subject to all entry places being filled in respect of a Competition, we guarantee that the stated prizes will be available. In the event that all places are not filled, we reserve our right to reduce the stated prizes proportionately (to reflect the lower aggregate total of entry fees received) or to cancel the Competition entirely. We will only cancel the Competition in the event that there are not a sufficient number of Players to make running the Competition viable. In the event we do decide to withdraw the Competition, your entry fee will be refunded to you in full.
                            </li>
                            <li>
                            To ensure the Competition result is clear, predictions relating to a number of goals or points achieved in a Match will usually be expressed using a 0.5 goal/point margin (i.e. ‘over 2.5 goals’).
                            </li>
                            <li>
                            We may run several different Competitions in relation to the same Match.
                            </li>
                            <li>
                            You may only play a Joker (treble prize points) or an Ace (double prize points) once in any single Competition.
                            </li>
                            <li>
                            Any winnings due to you will be credited to your Wallet as soon as reasonably possible after the end of the relevant Competition (and in any event within 24 hours) save where there is a genuine and material dispute over the result of a relevant Match.
                            </li>
                            <li>
                            You are fully responsible for your predictions entered in respect of any Competition entry and we will not be liable for any losses suffered by you as a result of any errors, omissions or unintended predictions made by you.
                            </li>
                            <li>
                            A Competition entry is not valid until it has been confirmed (on the Site) by us and your Competition entry shows up in your My Account part of the Site. This should happen immediately following receipt of your selection and entry fee but we do not accept any liability in respect of any Competition entries not confirmed and validated in accordance with this paragraph 3.17. In the event you have any questions about whether or not a Competition entry is valid, please contact us using the details set out at paragraph 19.
                            </li>
                            <li>
                            You may not amend a Competition entry after any Match (forming a part of your Competition entry predictions) has started or at any time thereafter. You may amend a Competition entry before this time.
                            </li>
                            <li>
                            You may not cancel a Competition entry at any time after it has been validated by us in accordance with paragraph 3.17.
                            </li>
                            <li>
                            In the event of any dispute over the time or details of a Competition entry, the information recorded on our Transaction Log will be deemed determinative whenever it is relevant.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Your Personal Information</span>
                        <ol className="mt-3 pl-2">
                            <li>
                            We will only use your personal information in accordance with our privacy policy, which can be found at www.toppredictor.com (Privacy Policy). Please take some time to read our Privacy Policy as it includes important terms which apply to you and our use of your personal data.
                            </li>
                            <li>
                            You acknowledge and agree that, at any time, we may use any reasonable means that we consider necessary to verify your identity with any third party providers and to permit us to comply with all applicable laws in relation to our provision of the Services.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Your Account</span>
                        <ol className="mt-3 pl-2">
                                <li>
                                    Before you can use our Services, you will need to open an Account. To apply for an Account, you must complete the online application form and provide all of the information requested (Application).
                                </li>
                                <li>
                                    It is your responsibility to keep your contact details up-to-date on your Account. We may, from time to time, send you important information using the details that you have provided to us. If those details are incorrect or not up-to-date you may not receive important information relating to your Account, the Services or these Terms.
                                </li>
                                <b>
                                    <li>
                                        In order to prevent amongst other things collusion and money laundering, the use of multiple accounts by a Player is strictly prohibited. Upon the suspicion of the multiple registration by a Player or by Players acting in collusion or as a syndicate, the set-up of fictitious accounts or the use of front men, the Company reserves the right to change or terminate any bonus offer, cancel any winnings and close account of the Player(s).
                                    </li>
                                </b>
                                <li>
                                    We may refuse any Application to open an Account for any reason (without providing the reason to you).
                                </li>
                                <li>
                                    You must keep your Account details (including your password and username) strictly confidential.
                                </li>
                                <li>
                                    Your Account is non-transferable and must not be used by anyone other than you. We reserve the right to terminate your Account if we have reason to believe it is being used by anyone other than you and/or you have not kept your log in details confidential.
                                </li>
                                <li>
                                    You shall be liable for all activities that are undertaken using your Account and you shall compensate us for all and any losses, damages, costs and expenses we may suffer as a result of any failure by you to keep your password strictly confidential.
                                </li>
                                <li>
                                    As part of our ongoing compliance with anti-money laundering and counter-terrorism financing rules, we may at any time ask you to verify your identity and address as well as request proof of ownership for any payment methods used to deposit funds into, or withdraw funds from, your Wallet. This includes the right to seek verification information regarding the source of funds deposited into your Wallet and suspending your ability to make deposits into or withdrawals from your Wallet until such verification has been completed.
                                </li>
                                <li>
                                    When considering your Application and certain deposit and withdrawal transactions, we may use an independent third party to verify your age and identity or any other information that you provide to us. In performing these checks, the independent third party may keep a record of your information.
                                </li>
                                <li>
                                    You may not be able to make any deposits or enter any Competitions until such time that we have completed our verification processes.
                                </li>
                                <li>
                                    You acknowledge that your Account (and/or Wallet) may be frozen immediately to enable us to comply with any of our regulatory (including self-regulatory) and legal obligations in relation to your Account. You will not be able to access the Funds in your Wallet, deposit monies into your Wallet or use any Services whilst your Account is frozen. We will notify you of such action as soon as reasonably practicable.
                                </li>
                                <b>
                                    <li>
                                        In consideration of our accepting your Application to open an Account, you warrant that:
                                    </li>
                                
                                    <li>
                                        You are 18 years of age or over;
                                    </li>
                                </b>
                                <li>
                                    You are of sound mind and capable of taking responsibility for your own actions and you can enter into a legally binding contract with us;
                                </li>
                                <li>
                                    You have the legal right to use the debit card (and/or other payment method) registered on your Account;
                                </li>
                                <li>
                                    You are registering the Account in your own name, for your sole benefit and not for the purpose of betting or gaming on behalf of anyone else;
                                </li>
                                <li>
                                    The details submitted in your application are true, accurate and not misleading;
                                </li>
                                <li>
                                    You will inform us immediately if any of the information you provided in your application changes;
                                </li>
                                <li>
                                    You are not an undischarged bankrupt and you are not in a voluntary arrangement with your creditors;
                                </li>
                                <b>
                                    <li>
                                        You are not located in a jurisdiction where use of the services is prohibited and/or would be an illegal activity;
                                    </li>
                                </b>
                                <li>
                                    You will, at all times whilst holding an Account, comply with all applicable legislation in the United Kingdom and, if different, in the jurisdiction in which you are located;
                                </li>
                                <li>
                                    You are not in a period of Self-Exclusion (please see paragraph 11 for further details);
                                </li>
                                <li>
                                    You are not a professional or semi-professional sportsperson playing in, or in any way involved in any capacity with, any match in respect of which you have the ability to directly or indirectly influence the outcome of such match;
                                </li>
                                <li>
                                    You will not breach in any way any rules or regulations which apply to any sports which you play on a professional or semi-professional basis by using the services; and
                                </li>
                                <li>
                                    You will not at any time while holding an Account, seek to manipulate or in any way affect or influence the result of any match.
                                </li>
                                <li>
                                    If we believe that you have (a) registered and/or used more than one Account; or (b) you have acted in collusion with one or more other individuals through a number of different Accounts in breach of these Terms; or (c) done or omitted to do anything to fraudulently, dishonestly or recklessly manipulate a Match or increase the likelihood of you winning; or (d) otherwise acted in breach of any law, statute, regulation or code of conduct or done anything which may put our licence granted by the Minister of Justice in Curacao at risk, we reserve the right to terminate, with immediate effect, any one or more of those Accounts, at our sole and absolute discretion, and/or withhold any winnings payable to any one of the applicable Accounts.
                                </li>
                                <li>
                                    If you do not access your Account by logging onto your Account using your username and password and either: Make A Deposit; Or Enter A Competition, for any consecutive period of 12 months, then after those 12 months your Account will be deemed inactive (an Inactive Account).
                                </li>
                                <li>
                                    If you have an Inactive Account and you log back in and make a deposit, your Account will, at that stage, be considered re-activated.
                                </li>
                                <li>
                                    In the event that your Account remains inactive for 36 months with no further activity or any claims for the funds held in respect of your Inactive Account, your Account will be permanently deleted and any funds remaining in the Inactive Account will be donated to a charity of our choice after we have deducted from such amount any administration fees in respect of closing the Account. We will notify you in writing (by email to the email address we hold in our records) prior to permanently closing an Inactive Account.
                                </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Funding Your Wallet</span>
                        <ol className="mt-3 pl-2">
                            <li>
                                Subject to compliance in full with these Terms, you can deposit funds into your Wallet and withdraw funds from your Wallet at any time in accordance with this paragraph 6.
                            </li>
                            <li>
                                All deposits into your Wallet must be made by debit card or via an associated payment provider. Full details of the cards which we accept and our associated payment providers can be found on the Site (as updated from time to time). We do not accept deposits from credit cards.
                            </li>
                            <li>
                                You are not permitted to have more than one debit card registered to your Account at any one time.
                            </li>
                            <b>
                                <li>
                                    There is a €5 minimum deposit. We do not allow daily deposits to be made in excess of €1,000. Any deposits above said maximum amount may be cancelled and returned by the Company. You may not hold deposited funds (i.e. not including winnings) totalling in excess of €1000 in your Wallet at any one time. In the event you attempt to exceed these limits, your transaction request may be rejected and any repeated attempts to exceed these limits may be deemed suspicious.
                                </li>
                                <li>
                                    The Company has the right to request the Player to provide to the Company source of funds when the Player makes/has made a deposit of an amount greater than €2,000 (or local currency equivalent).
                                </li>
                                <li>
                                    The maximum winnings amount per day/24 hours is €100,000 (or local currency equivalent), any winnings above said maximum amount may be cancelled by the Company.
                                </li>
                            </b>
                            <li>
                                You accept that all transactions may be checked by us to prevent money laundering and that any transactions made by you which we deem suspicious, may be reported by us to the appropriate authorities.
                            </li>
                            <li>
                                The name on the debit card used to fund your Wallet must be exactly the same as the name on your Account.
                            </li>
                            <li>
                                Withdrawals must be made to the same card or payment method registered to your Account and you should allow up to 5 working days for the transaction to take place (this will depend on the destination account and transfer method used). We operate a ‘closed-loop’ policy whereby your net withdrawals must match or exceed your total deposits before you will be permitted to withdraw funds via a different method.
                            </li>
                            <li>
                                Transactions will show up on your bank (or payment provider) statement as “TPG Technology Limited”
                            </li>
                            <li>
                                All funds held in your Wallet are held in accordance with paragraph 16.
                            </li>
                            <li>
                                You undertake that:
                            </li>
                            <b>
                                <li>
                                    Deposited funds rightfully belong to you and that these funds have not been obtained or are derived from any illegal means. The company retains the right to request additional source of funds based on a case-by-case basis.
                                </li>
                            </b>
                            <li>
                                All payments made into your wallet are authorised and you will not attempt to reverse any payment made into your wallet or take any action which will cause such payment to be reversed by a third party.
                            </li>
                            <li>
                                You will inform us immediately on becoming aware of any errors with respect to your Wallet and/or Account and, in particular, any deposits shown on your Wallet that you were not expecting. If such an error is as a result of a technical fault or human error we shall be permitted to correct the error once we have been made aware of such error, with no further liability to you.
                            </li>
                            <li>
                                We reserve the right to reverse or correct any payment to or from your Wallet in circumstances where we have debited or credited an incorrect amount from or to your Wallet if the result of a Match changes after a Competition has ended (as a result of a steward’s enquiry, for example). We will use our reasonable endeavours to inform you by email, without delay, in circumstances of our having made (or become entitled to make) such a corrective payment.
                            </li>
                            <li>
                                At no point during the period in which funds are held in your Account, will any interest accrued on such funds become due to you.
                            </li>
                            <li>
                                All funds which are deposited into your Wallet should be used, or you should have the intention to use such funds, for betting purposes. Your Wallet must not be used as a bank account. If we consider that you are using your Wallet as such, we reserve the right to terminate your Account with immediate effect and return all deposited funds to you.
                            </li>
                            <li>
                                Any Competition entry attempted by you when there are not sufficient funds in your Wallet (for any reason, including due to a declined deposit transaction) will be deemed invalid.
                            </li>
                            <li>
                                Funds may not be transferred directly from one Player’s Account to another Player’s Account.
                            </li>
                            <li>
                                You acknowledge and agree that it is your responsibility to account for any tax or duty imposed on you as a result of any of your wagers.
                            </li>
                            <li>
                                For the avoidance of doubt, we do not offer credit.
                            </li>
                            <li>
                                All details relating to available Funds and winnings will be managed by us and, in the absence of manifest error, our decision in respect of such funds shall be final and deemed to be accurate.
                            </li>
                            <b>
                                <li>
                                    All withdrawals must be done through the same payment method and details, chosen by the Player when placing a deposit, unless the Company is unable to do so or decides otherwise. This is at the discretion of the Company. If the Player has deposited using several payment methods, the Company reserves the right to split the Player’s withdrawal across such payment methods and process each part through the respective payment method at its discretion and in accordance with anti-money laundering policies and regulations.
                                </li>
                                <li>
                                    Players agree that all deposits/withdrawals made to/from their Player account are transferred to/from bank account(s), debit cards, wallet or any other payment options available in the Player’s country, which belong solely to the Player and are in the Player’s name.
                                </li>
                                <li>
                                    It is prohibited to make any withdrawal from a Player Account, bank account(s), debit card, wallet or any other payment options which do not belong solely to the Player. This is in order to prevent that the Company transfers funds to persons who have not been duly identified by the Company, in accordance with anti-money laundering policies and regulations.
                                </li>
                                <li>
                                    Players may only withdraw the maximum amount of €1,000 (or local currency equivalent) in any twenty-four-hour period unless a larger amount has been agreed upon between the Player and the Company. The Company may reject a withdrawal request if the suspicion arises those funds are being withdrawn for any fraudulent or money laundering reasons, pending the investigation thereto. Not withstanding the maximum withdrawal amount as defined in this article, the maximum withdrawal amount is limited by the limitations set by the payment method chosen by the Player when placing a deposit.
                                </li>
                                <li>
                                    We recommend you retain copies of all transactions, merchant policies and rules.
                                </li>
                            </b>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Promotionst</span>
                        <ol className="mt-3 pl-2">
                            <li>
                                We may, at our sole discretion, add bonuses and/or promotions to your Account or directly into your Wallet from time to time.
                            </li>
                            <li>
                                Any such bonus and/or promotion will be subject to specific terms and conditions made available on the Site or emailed to you for your reference.
                            </li>
                            <li>
                                In the event that we believe that you have undertaken any form of Bonus Abuse (see paragraph 8.4), we reserve the right to immediately close your Account(s). In such circumstances, any deposited funds will be returned to you within a reasonable a time period. You will not be permitted to receive any winnings which have not yet been withdrawn and you may be required to pay back any winnings which have been withdrawn from any Account opened by you or on your behalf. Your entries into any live Competitions will also be cancelled.
                            </li>
                            <li>
                                For the purposes of these Terms, Bonus Abuse means the creation of multiple accounts with the intention, or effect, of taking advantage of bonuses or other promotional offers intended to attract new players to the Site. Examples of Bonus Abuse include: (a) using multiple email addresses or multiple bank cards as a way of appearing to be a new Player when opening a new Account; (b) opening Accounts on behalf of family members or others living at the same address as you; (c) using a false date of birth, address or other details as a way of appearing to be a new Player; (d) any other deliberate attempt to knowingly claim a bonus in bad faith and/or in contravention of these Terms. This list of examples is not exhaustive and we reserve our right (acting reasonably) to deem any other action that we see fit to be a form of Bonus Abuse.
                            </li>
                            <li>
                                We reserve the right to report all forms of Bonus Abuse and share such information with other third parties if we consider it necessary to do so.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Conduct</span>
                        <ol className="mt-3 pl-2">
                            <b>
                                <li>
                                    Players may make use of the Services strictly in their personal non-professional capacity, on their own behalf and expressly not on the behalf of any other person or company. Players may only make use of the Services for recreation and entertainment purposes, in accordance with these Terms and Conditions, together with all applicable laws, rules, and regulations. Amu use of the Services by a corporate player or for commercial purposes is prohibited. Upon the suspicion of any use of the Services in breach of this article, the Company reserves the right to change or terminate any bonus offer, cancel any winnings and close the Player Accounts involved.
                                </li>
                            </b>
                            <li>
                                You will, at all times while you are registered as a Player, comply with these Terms and all applicable laws, statutes, regulations and codes of conduct.
                            </li>
                            <li>
                                You must ensure that your username is not offensive or blasphemous and that it could not be deemed to promote any third party brand or infringe any third party intellectual property rights. You should bear in mind that your username will be displayed publicly on the Site. You may be requested to change your username in the event that it is deemed by us (at our sole discretion) to be in breach of this paragraph or otherwise inappropriate or unacceptable in any way. We shall also be entitled to change your username at any time without any notice or liability to you if we consider it necessary to take such action.
                            </li>
                            <li>
                                You will inform us as soon as you become aware of any errors in calculations with respect to any Competition entries. We reserve the right to declare null and void any entries which are the subject of any such error. We will notify you (using the email address we have on our records) of any such declaration and any entry fees paid by you in connection with the Competition will be returned to your Wallet.
                            </li>
                            <li>
                                You shall be responsible for the payment of all taxes (including any income tax, corporation tax and any other applicable taxes), all gambling and betting levies and all other duties which may arise in respect of your use of the App (Taxes and Duties)
                            </li>
                            <li>
                                You will not disguise or interfere in any way with the IP address of the computer or mobile device you are using to access the Site or otherwise take steps to prevent us from correctly identifying the actual IP address of your computer or mobile device.
                            </li>
                            <li>
                                You agree that you will not attempt to open or access any other Account to use any of our Services during any period of self-exclusion (please see paragraph 10 for further details).
                            </li>
                            <li>
                                You undertake to use our Services for legitimate wagering purposes only. In particular, you undertake not to: Engage in any activity which has the purpose or effect of causing damage to or in any way hindering our business operations; Or bet on or manipulate any individual match in a manner which we believe has the purpose or effect of adversely affecting the integrity of any match.
                            </li>
                            <li>
                                Collusion is strictly prohibited.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Suspension Or Termination Of Your Account</span>
                        <ol className="mt-3 pl-2">
                            <li>You may suspend or terminate your Account at any time by contacting us in writing. On receipt of any request, we will suspend or terminate the Account as soon as we can. We shall not be liable to you for any losses suffered by you as a result of any delay in the suspension or termination of your Account.</li>
                            <li>If we suspect that you are under 18 years of age or were under 18 when you entered any Competition on the Site we will:</li>
                            <li>Immediately suspend your account and prevent you from entering any further competitions or making any withdrawals from your wallet;</li>
                            <li>Investigate the claim that you are or were under age;</li>
                            <li> If you were under the age of 18 at the time that you entered any competition on the site, We will refund any deposit(s) made into your wallet back to the payment method used to make such deposit(s), but will not pay out any winnings which have been credited to your wallet.</li>
                            <li>If we know or suspect that you are:</li>
                            <li>In breach of any provision of these terms;</li>
                            <li>Using bots designed to automatically place wagers within certain parameters on any match;</li>
                            <li>Located in a country in which it is or may be illegal to use or access the services and/or enter a competition;</li>
                            <li>Involved in any form of collusion with any other player;</li>
                            <li>Undertaking any action which may have a detrimental effect on us holding and being permitted to continue to hold our gambling licence;</li>
                            <li>Registered on an account that is linked with fraudulent or dishonest activity;</li>
                            <li>Registered on more than one account;</li>
                            <li>We reserve the right to suspend or terminate your Account (at our absolute discretion) and report any such findings to a regulatory authority and, if we have reasonable grounds to do so, prevent any funds from being withdrawn from your Wallet pending the result of any internal or external investigation.</li>
                            <li>In circumstances where your Account is suspended or terminated, any live Competition entries will be treated as follows:</li>
                            <li>If you have opted to suspend or terminate your account in accordance with paragraph 9.2, all competition entries will remain open and valid and any winnings will be settled in accordance with paragraph 3.15;</li>
                            <li>If we suspend or terminate your account as a result of a serious breach of these terms, all competition entries will be deemed null and void immediately on the suspension or termination of your account and all entry fees will be forfeited by you and will not be refunded to you, unless we agree otherwise;</li>
                            <li>If we are required to suspend or terminate your account by law or at the request or instruction of any regulatory authority, any entry fees and any winnings due to you will be held by us pending the outcome or determination of any associated investigation; and</li>
                            <li>If we suspend or terminate your account (other than for the reasons set out at 9.5(B) and (C) above), all wagers will remain open and valid and winnings will be settled in accordance with paragraph 3.15.</li>
                            <li>Following suspension or termination of your Account (whether by you or us) we will, subject to paragraph 9.7, return any Funds held in your Wallet to you. Funds will be returned to the debit card or other payment method which has previously been registered to your Account. We cannot return Funds to you by any other means or to any other card or payment method which is not registered to your Account.</li>
                            <li>In certain circumstances, following the suspension or termination of your Account, we reserve the right to withhold the funds in your Wallet pending an investigation. Such an investigation may be necessary in the event that we suspect that you have breached these Terms or any other applicable agreement between you and us or we are required to act in accordance with a request or instruction from any governmental, regulatory or enforcement authority, or otherwise in accordance with applicable law. Following the determination of any such investigation, we reserve the right to seize, or permit the seizure by any regulatory, statutory or other enforcement body, some or all of the funds in your Wallet.</li>
                            <li>Once an Account registered in your name has been terminated by us you are not permitted to re-open any other Account.</li>
                            <li>We shall endeavour to return any Funds which are in your Wallet at the time that your Account is terminated or closed within 30 days from the date on which the Account is terminated or closed (including, to the extent that you are entitled to them, any winnings).</li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Responsible Gambling, Social Responsibility And Self-Exclusion</span>
                        <ol className="mt-3 pl-2">
                            <li>We do not provide credit facilities. You can only place a bet with funds credited to your Wallet after the card issuer has approved the transaction. Limits to Your Account</li>
                            <li>We provide you with facilities designed to assist you in staying within your personal budget for gambling. Although we already limit the size of deposits Players can make, you may also opt to set your own limits (whether in relation to deposit limit or loss limit). You can request such a limit be placed on your Account at any time. Any reductions to your limit will be implemented as soon as possible (usually immediately and always within 24 hours). We exercise a 1 week delay before any requested increase to your limit takes effect.</li>
                            <li>Our self-exclusion policy requires a joint commitment from you. We will take reasonable steps to prevent you from re-opening your Account or opening a new Account, and we have processes in place to detect duplicate Accounts that are subject to self-exclusion, but you must not attempt to circumvent such functions by trying to re-open your Account, by opening a new Account or by asking another person to open an Account for you. You agree that you will not attempt to open an Account or place any wagers whilst you are a self-excluded Player and that you are fully liable for your actions whilst you are a self-excluded Player. Upon detecting any such Accounts, we will return any deposits made by you and held in the Account (as at the date of your self-exclusion) within 14 days and delete the Account, in accordance with our responsible gambling policy.</li>
                            <li>If you want to find out further information about responsible gambling, please visit https://gamhelpkenya.com/ or http://responsiblegambling.or.ke/</li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Licence And Our Intellectual Property Rights</span>
                        <ol className="m-3 pl-2">
                            <li>We own, or are the licensor of, all intellectual property rights in and to the Site, including but not limited to:</li>
                            <li>All Copyright And Related Rights In And To The Site;</li>
                            <li>All Trade Mark Rights (Whether Registered Or Unregistered) In And To TopPredictor And</li>
                            <li>The Domain Name www.toppredictor.com</li>
                            <li>We hereby grant to you a revocable, royalty-free, non-exclusive licence to access the Site provided that you comply with these Terms. Your use of the Site confers no rights whatsoever to the content and related intellectual property rights contained within or on our Site.</li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Links To Other Sites</span>
                        <ol className="m-3 pl-2">
                            <li>
                                The Site may contain links through to third party websites, or information supplied by them. We have no responsibility in respect of any such sites, the information or accuracy provided therein.
                            </li>
                            <li>
                                You should be aware that any third party sites will have their own terms and conditions and privacy notices which shall apply to your use of such sites.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Our Liability And Disclaimer</span>
                        <ol className="m-3 pl-2">
                            <li>Nothing in these Terms limits or excludes our liability for:</li>
                            <li>Death Or Personal Injury Caused By Our Negligence;</li>
                            <li>Fraud Or Fraudulent Misrepresentation; And/Or</li>
                            <li>Any Other Liability Which Cannot Be Excluded Or Limited By Applicable Law.</li>
                            <li>Subject to paragraph 13.1, if we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms or our negligence, but we are not responsible for any loss or damage that is not foreseeable (including indirect, special or consequential loss).</li>
                            <li>Subject to paragraphs 13.1 and 13.2, our total liability to you in respect of all other losses arising under or in connection with these Terms, your Account and your use of the Site, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed the amount held in your Wallet at the time of such breach.</li>
                            <li>To the extent that any employee of Top Predictor may make any statement to you that may constitute financial advice or advice in respect of any Competition, Match or wager, you should not rely on it. If you do rely on it, such reliance will be a breach of these Terms and any resulting bet placed or laid will be at your sole risk.</li>
                            <li>We shall not be liable for the failure of any equipment or software howsoever caused that may prevent the operation of the Site, or our Services or may impede the entry into any Competitions or prevent you from being able to contact us.</li>
                            <li>Except as expressly stated in these Terms and to the extent permitted by law, we do not give any representation, warranties or undertakings in relation to your use of the Site. Any representation, condition or warranty which might be implied or incorporated into these Terms by statute, common law or otherwise is excluded to the fullest extent permitted by law.</li>
                            <li>We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Terms that is caused by an Event Outside Our Control. An Event Outside Our Control means any act or event beyond our reasonable control, including without limitation any act of God, strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster, or obstruction or failure of public or private telecommunications networks and/or the internet.</li>
                            <li>If an event outside our control takes place that affects the performance of our obligations to you:</li>
                            <li>We will contact you as soon as reasonably possible to notify you; and</li>
                            <li>Our obligations will be suspended and the time for performance of our obligations will be extended for the duration of the event outside our control.</li>
                            <li>You agree to indemnify and hold us (including all officers, directors, agents and employees of us) harmless from any liabilities, claims, losses or demands made by a third party arising out of your breach of these Terms or out of your violation of any law or the rights of any third party.</li>
                            <li>We make every effort to ensure that we do not make any errors when posting information relating to Competitions, Matches or otherwise on the Site. However, if as a result of a technical issue or human error or any other reason, information is posted to the Site that should not have, we reserve the right to withdraw or amend the information and we shall not be liable to you for any losses suffered other than crediting your Wallet with an amount equivalent to any entry fees paid in reliance on such an error.</li>
                            <li>We may, in our sole and absolute discretion, decide to suspend Competitions relating to a specific Match at any time. If we do, we shall post a notice on the Site.</li>
                            <li>It is your responsibility to verify that you are permitted to enter a Competition (which is likely to amount to betting activity) according to the jurisdiction in which you are located and you agree that you will not enter any Competition while you are located in a jurisdiction which prohibits you from doing so.</li>
                            <li>You understand that you may lose money by entering Competitions on the Site and accept that you are fully responsible for any such loss. You accept that under no circumstances will any amounts lost by you as a result of any Competition be recoverable from us and we have no liability whatsoever in connection with any such loss.</li>
                            <li>We shall not be liable in any event to pay any Taxes or Duties in respect of any action by you on the Site.</li>
                            <li>If the results of any Match which are posted on the Site are disputed by any Player, we may, at our sole discretion, investigate any such allegation. (Save in circumstances of manifest error) our decision in relation to any result which is posted on the Site will be final and binding on all Players. We shall not enter into any correspondence with anybody in connection with any results posted on the Site.</li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Our Rights</span>
                        <ol className="m-3 pl-2">
                            <li>We may restrict your access to the Site, suspend or terminate your Account, cancel any pending Competition entries or cancel and void any live Competition entries in our absolute discretion without cause at any time, including if:</li>
                            <li>There is a technical failure on our site;</li>
                            <li>We suspect that you are engaging in illegal or fraudulent activity; or</li>
                            <li>We suspect that you have (or may have) breached any part of these terms.</li>
                            <li>Notwithstanding any other terms set out in these Terms, in providing the Services we will be entitled to take any action as we consider necessary in our absolute discretion to ensure compliance with all applicable legislation.</li>
                            <li>We reserve the right to recover from you all charges which we incur on your behalf which relate specifically to you and your Account, including but not limited to, charges incurred in respect of identification and credit checks against you. All charges, will be deducted from funds held in your Wallet. If sufficient funds are not available in your Wallet, we reserve the right to recover these expenses from you directly.</li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Protection Of Player Funds</span>
                        <ol className="m-3 pl-2">
                            <li>
                                We hold all Player funds separate from company funds in a mixture of bank accounts held with our payment processor. This means that in the event of our insolvency your funds may not be protected. You will be unable to deposit funds in accordance with paragraph 6 above until you have clicked to accept this. In the event there is any material change to the way in which we hold Player funds, you will be notified and required to accept before being able to make further deposits.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Notices And Information From Us</span>
                        <ol className="m-3 pl-2">
                            <li>
                                You hereby agree to receiving all information from us electronically, including by email, using the email address that is registered to your Account.
                            </li>
                            <li>
                                Any notice given by you to us, or by us to you, will be deemed received and properly served immediately when posted on the Site or 24 hours after an email is sent. In proving the service of any notice by us to you, it will be sufficient to prove, in the case of an email, that such email was sent to the most recent e-mail address provided by you. The provisions of this paragraph shall not apply to the service of any proceedings or other documents in any legal action.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Varying These Terms</span>
                        <ol className="m-3 pl-2">
                            <li>
                                We may amend these Terms (including any Game Rules) from time to time. We will inform you of any material change to these Terms by emailing you at the email address we have for you on our records.
                            </li>
                            <li>
                                If we amend these Terms at any time whilst you are a registered Player, you will be asked to accept the amended Terms on the next time that you log-in to the Site. You will not be able to log-in to the Site until you have accepted the amended Terms. If you do not wish to accept the amended Terms, you should contact us at info@toppredictor.com and we will terminate your Account (and return to you any funds which are held in your Wallet).
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Disputes and Alternative Dispute Resolution (ADR)</span>
                        <ol className="m-3 pl-2">
                            <b>
                                <li>
                                    If any dispute arises out of the agreement governed by these Terms and Conditions, a representative of the director of the Company, will, within fourteen (14) working days of a written request from the Player to the Company or when the director of the Company has been made aware of such a written request (should a representative of a director of the Company not be/have been directly addressed) enter into good faith conversations in an effort to resolve the dispute.
                                </li>
                                <li>
                                    If the dispute is not wholly resolved after the abovementioned conversations, the Parties agree to enter into mediation in good faith to settle such a dispute and will do so before the Court of First Instance, Curacao. Unless otherwise agreed between the Parties, within fourteen (14) working days of the aforementioned agreement to enter into mediation, the mediator will be nominated by the director of the Company. To initiate the mediation a Party must give notice in writing to the other Party to the dispute, referring the dispute to mediation (the “ADR Notice”).
                                </li>
                                <li>
                                    Unless otherwise agreed, the mediation will start no later than eight (8) weeks after the date of the ADR Notice.
                                </li>
                                <li>
                                    No Party may commence any court proceedings/arbitration in relation to the dispute arising out of the agreement governed by these Terms and Conditions until it has attempted to settle the dispute by mediation and either the mediation has terminated, or the other Party has failed to participate in the mediation, provided that the right to issue proceedings is not prejudiced by a delay.
                                </li>
                            </b>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Contact Us</span>
                        <ol className="m-3 pl-2">
                            <li>
                                If you have any general queries in relation to the Services, the Site or these Terms please contact us by email at info@toppredictor.com.
                            </li>
                            <li>
                                In the event that any complaint raised by Kenyan Players cannot be resolved in accordance with our complaints procedure, they may contact the following: Betting Control and Licensing Board (BCLB) at info@bclb.go.ke or on +254-20-2220186, or the Chartered Institute of Arbitrators (CIArb) at https://www.ciarb.org/news/chartered-institute-of-arbitrators-kenyan-branch/ or on +254-20-2722724.
                            </li>
                            <li>
                                We retain copies of all complaints and may provide such records to any relevant authorised body on request.
                            </li>
                        </ol>
                        <span className="terms_and_conditions_content_tittle">Other Important Terms</span>
                        <ol className="m-3 pl-2">
                            <b>
                                <li>Seen the fact that an account is personal to that specific Player, whereby the Player has been identified, and that also the method of payments of any winnings is connected to the Player itself, in order to avoid possible money laundering, terrorist financing or other crimes, Player may not assign, sublicense or otherwise transfer or encumber in any matter whatsoever any of his/her rights or obligations under the Terms and Conditions.</li>
                                <li>In case the Player encumbers any of his/her rights or obligations under the Terms and Conditions, the Player shall forfeit all his/her rights under the Terms and Conditions towards the Company.</li>
                                <li>
                                    In case by law, or by a decision of a court the transfer of rights and/or obligations of the Player is deemed legal, all withdrawals shall be done through the same payment method and payment details chosen by the Player when placing a deposit, to ensure compliance with anti-money laundering policies and regulation.
                                </li>
                            </b>
                            <li>We may transfer our rights and obligations under these Terms to another organisation, but this will not affect your rights or our obligations under these Terms.</li>
                            <li>You may not assign these Terms to any third party.</li>
                            <li>This contract, created by you accepting these Terms, is between you and us. No other person shall have any rights to enforce any of its terms, whether under the Contracts (Rights of Third Parties Act) 1999 or otherwise.</li>
                            <li>Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.</li>
                            <li>If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</li>
                            <li>In the case of any conflict between the English language version of the Terms and any versions in other languages, the English language version shall prevail. For the avoidance of doubt, reliance should not be placed on translations of the Terms produced by online translation services (such as Google Translate).</li>
                            <li>If any dispute arises in connection with these Terms (that falls outside the scope of the BCLB), you and we will attempt to settle it by mediation in accordance with the Centre for Effective Dispute Resolution (CEDR) Model Mediation Procedure. Unless otherwise agreed between the Parties, the mediator will be nominated by CEDR.</li>
                            <li>These Terms are governed by the laws of England and Wales. This means that any dispute or claim arising out of or in connection with these Terms will be governed by the laws of England and Wales. You and we both agree to that the courts of England and Wales will have exclusive jurisdiction.</li>
                        </ol>
                    </div> */}
          <div className="terms_and_conditions_content col-md-12">
            {Parser(termsContentText)}
          </div>
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
