import React from "react";

const Wizard = (props, ref) => {
  var { activeStep, changeSteps, isSingleDayComp } = props;
  return (
    <div className="wizard-steps">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="step-1-container" onClick={() => changeSteps(1)}>
          <div className={"step-index active"}>
            <img src="/assets/icons/tick.svg" alt="gamecard-icon" />
          </div>
        </div>
        <div className="wizard_steps text-white"> Step 1</div>
      </div>
      <hr className={activeStep != 1 ? "active" : ""}></hr>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="step-2-container" onClick={() => changeSteps(isSingleDayComp ? 3 : 2)}>
          <div className={activeStep != 1 ? "step-index active" : "step-index"}>
            {activeStep != 1 ? (
              <img src="/assets/icons/tick.svg" alt="gamecard-icon" />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="wizard_steps text-white"> Step 2</div>
      </div>
      {
        !isSingleDayComp ? 
        <>
        <hr className={activeStep != 1 && activeStep != 2 ? "active" : ""}></hr>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="step-3-container" onClick={() => changeSteps(3)}>
              <div
                className={
                  activeStep != 1 && activeStep != 2
                    ? "step-index active"
                    : "step-index"
                }
              >
                {activeStep != 1 && activeStep != 2 ? (
                  <img src="/assets/icons/tick.svg" alt="gamecard-icon" />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="wizard_steps text-white"> Step 3</div>
          </div>
        </>
        : null
      }
    </div>
  );
};
export default Wizard;
