import React from "react";
import "./SportsContainer.css";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";

export default function SportsContainer(props) {
  var envMode = process.env.REACT_APP_MODE;
  var aws_url = process.env.REACT_APP_AWS_ASSETS_URL;

  var sports_types = {
    LOCAL: {
      football: { sport_id: 1, league_id: 15 },
      american_football: { sport_id: 3, league_id: 114 },
      ice_hockey: { sport_id: 16, league_id: 152 },
    },
    TEST: {
      football: { sport_id: 1, league_id: 15 },
      american_football: { sport_id: 3, league_id: 114 },
      ice_hockey: { sport_id: 16, league_id: 152 },
    },
    LOCAL: {
      football: { sport_id: 1, league_id: 15 },
      american_football: { sport_id: 3, league_id: 114 },
      ice_hockey: { sport_id: 16, league_id: 152 },
    },
    DEV: {
      football: { sport_id: 1, league_id: 15 },
      american_football: { sport_id: 3, league_id: 114 },
      ice_hockey: { sport_id: 16, league_id: 152 },
    },
    STAGING: {
      football: { sport_id: 1, league_id: 161 },
      american_football: { sport_id: 3, league_id: 4216 },
      ice_hockey: { sport_id: 16, league_id: 4088 },
    },
    PROD: {
      football: { sport_id: 1, league_id: 73 },
      american_football: { sport_id: 3, league_id: 114 },
      ice_hockey: { sport_id: 16, league_id: 121 },
    },
  };

  var footballSportId = sports_types[envMode].football.sport_id;
  var premierLeagueId = sports_types[envMode].football.league_id;
  var americanFootballId = sports_types[envMode].american_football.sport_id;
  var nflId = sports_types[envMode].american_football.league_id;
  var iceHockey = sports_types[envMode].ice_hockey.sport_id;
  var nhlId = sports_types[envMode].ice_hockey.league_id;

  return (
    <React.Fragment>
      <div
        className="sport_container"
        style={{
          backgroundColor: "#12332E",
        }}
      >
        <div id="sports" className="d-flex sports_center page_content_center">
          <div className="sports_content">
            {props.horizontalPage.length > 0
              ? props.horizontalPage.map((row) => {
                  if (row.cover) {
                    return (
                      <Link className="sports_container_link" to={row.link}>
                        {Parser('<img src="' + aws_url + row.cover + '" />')}
                      </Link>
                    );
                  }
                })
              : ""}
            {/* <Link className="sports_container_link" to={"/sports_leagues/"+americanFootballId+"/"+nflId}>
                            <div className="sports_content_nfl">
                                <img className="nfl_img" src="/assets/image/nfl2.png" alt="nfl-img"/>
                            </div>
                        </Link>
                        <Link className="sports_container_link" to={"/sports_leagues/"+iceHockey+"/"+nhlId}>
                            <div className="sports_content_international">
                                <img className="international_img" src="/assets/image/nhl.png" alt="international-img"/>
                            </div>
                        </Link> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
