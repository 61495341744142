import React, { createContext } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
const ApiContext = createContext();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const soccerApi = axios.create({
  baseURL: process.env.REACT_APP_SOCCER_MS_URL,
});

soccerApi.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

soccerApi.interceptors.request.use(
  async (config) => {
    const resSession = await fetchAuthSession();
    try {
      if (resSession) {
        if (resSession.tokens?.accessToken?.toString()) {
          config.headers[
            "Authorization"
          ] = `Bearer ${resSession.tokens?.accessToken?.toString()}`;
        }
      } else {
        config.headers["Authorization"] = "";
      }
      return config;
    } catch (err) {}
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  async (config) => {
    const resSession = await fetchAuthSession();
    try {
      if (resSession) {
        if (resSession.tokens?.accessToken?.toString()) {
          config.headers[
            "Authorization"
          ] = `Bearer ${resSession.tokens?.accessToken?.toString()}`;
        }
      } else {
        config.headers["Authorization"] = "";
      }
      return config;
    } catch (err) {}
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const ApiProvider = ({ children }) => {
  return (
    <ApiContext.Provider
      value={{
        api,
        soccerApi,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
