import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UseAlert = () => {
    return [
        <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            theme="colored"
            hideProgressBar={true}
            icon={false}
        />,
        (type, msg) => {
            if(type === 'success'){
                toast.success(msg)
            }else if(type === 'error'){
                toast.error(msg)
            }
        }
    ]
}
export default UseAlert