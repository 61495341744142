import React from 'react'
import '../account-header/AccountHeader.css'

export default function AccountHeader(props){
    return (
        <React.Fragment>
            <div className="account_header">
                <div className="page_center">
                    <div className="account_header_title text-center">
                        <h2 className="mb-3">{props.headerValue}</h2>
                    </div>
                    <div className="account_header_text text-center">
                        <p className="mb-0">{props.firstTextValue}</p>
                        <p>{props.secondTextValue}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
