import React, { useState, useEffect, useContext } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import LoginHeader from "../login/LoginHeader";
import "../privacy-policy/PrivacyPolicy.css";
import Parser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";
import ApiContext from "../../providers/Api/ApiContext";

export default function PrivacyPolicy() {
  const { api } = useContext(ApiContext);
  const [loggedStatus, setLoggedStatus] = useState("");
  const [privacyContentText, setPrivacyContentText] = useState("");
  const [privacyName, setPrivacyName] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();

  useEffect(() => {
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }

    var privacy_url = "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "privacy_policy" };
    showLoader();
    api
      .post(privacy_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var page_content = data.content.page_content;
          setPrivacyContentText(page_content);
          var page_name = data.content.page_name;
          setPrivacyName(page_name);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | Privacy Policy</title>
        <meta
          name="description"
          content="Top Predictor takes your data privacy seriously. Our privacy policy explains who we are, how we collect, share and use Personal Information."
        />
      </Helmet>
      {loggedStatus === "logged" ? <Header /> : <LoginHeader />}
      <ImageContainer />
      <div className="privacy_policy_container">
        <div className="page_content_center">
          <h2 className="privacy_policy_header text-uppercase">
            {Parser(privacyName)}
          </h2>
          {Parser(privacyContentText)}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
