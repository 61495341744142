import React from "react";
import "./oddsImage.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function Section1() {
  const navigate = useNavigate();
  return (
    <div className="oddsimage-container">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-center py-4 gap-5">
        <div className="d-flex justify-content-center">
          <img
            src="assets/image/home/odds1.png"
            className="phone-image"
            // style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div className="d-flex flex-column text-white gap-2">
          <h2 className="w-100 w-md-75 font-weight-bold oddsimage-title text-center text-md-start">
            New Sports Prediction Game
          </h2>
          <p className="w-100 w-md-75 text-center text-md-start">
          It is engaging,exciting and entertaining!
          </p>
          <p className="w-100 w-md-75 text-center text-md-start">
          Put your knowledge and expertise to the test, play against like minded sport fans and friends.
          </p>
          <p className="w-100 w-md-75 text-center text-md-start">
          Predict results correctly and score more points than them and see your name at the top of the leader board!
          </p>
          {/* <Button
            variant="light"
            size="sm"
            className="w-25 "
            onClick={() => {
              navigate("/sports_leagues/1");
            }}
          >
            Explore Now
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default Section1;
