import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { Carousel } from '@trendyol-js/react-carousel';
import Parser from "html-react-parser";
import { useThemeProvider } from "../../providers/theme/ThemeContext";
import Carousel from "react-bootstrap/Carousel";

export default function IndexSlider(props) {
  var aws_url = process.env.REACT_APP_AWS_ASSETS_URL;
  const { colors } = useThemeProvider();
  return (
    <React.Fragment>
      <div
        className={
          props.loggedStatus
            ? "slider_container home_slider_container"
            : "slider_container index_slider_container"
        }
        style={{
          backgroundColor: colors.primary,
        }}
      >
        <div className="page_center">
          <div className="slider">
            {props.verticalPage.length > 0
              ? props.verticalPage.map((row, index) => {
                  if (row.cover) {
                    return (
                      <Link
                        className="vertical_sport_link"
                        to={row.link}
                      >
                        <div>
                          {Parser(
                            '<img src="' +
                              aws_url +
                              row.cover +
                              '" style="width: 100%; height: 220px; user-select: none;" draggable="false"/>'
                          )}
                        </div>
                      </Link>
                    );
                  }
                })
              : ""}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
