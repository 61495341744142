export const staticSportsBanner = [
  {
    name: "Soccer",
    link: "",
    order: "1",
    status: "1",
    sport_id: "1",
    sport_name: "Football",
  },
  // {
  //   name: "MLB",
  //   link: "/sports_leagues/7",
  //   order: "4",
  //   status: "1",
  //   sport_id: "7",
  //   sport_name: "Baseball",
  // },
  // {
  //   name: "NBA",
  //   link: "/sports_leagues/8/689",
  //   order: "3",
  //   status: "1",
  //   sport_id: "8",
  //   sport_name: "Basketball",
  // },
  // {
  //   name: "GOLF",
  //   link: "/sports_leagues/13/1",
  //   order: "7",
  //   status: "1",
  //   sport_id: "13",
  //   sport_name: "Golf",
  // },
];
export const howItWorks = [
  {
    title: "Register and Verify",
    desc: "Fill in the required details to create your account. Verify your email and log in to get started.",
    imgPath: "assets/image/how-it-works/signup.png",
  },
  {
    title: "Choose Your Sport",
    desc: "Pick the league for the sport you wish to predict.",
    imgPath: "assets/image/how-it-works/select-league.png",
  },
  {
    title: "Explore Competitions",
    desc: "Browse through all available competitions. You can join both free and paid options.",
    imgPath: "assets/image/how-it-works/select-competition.png",
  },
  {
    title: "Make Your Prediction",
    desc: "Select your prediction, use wildcards to earn extra points, and save your choices.",
    imgPath: "assets/image/how-it-works/save-prediction.png",
  },
];

export const staticLeaguesBanner = [
  {
    name: "EPL",
    link: "/sports_leagues/1/26de2483-3d5a-4571-858d-b06b57c4f233",
    order: "1",
    status: "1",
    sport_id: "1",
    league_id:"26de2483-3d5a-4571-858d-b06b57c4f233",
    sport_name: "Football",
  },
  {
    name: "La Liga",
    link: "/sports_leagues/1/26de2483-3d5a-4571-858d-b06b57c4f233",
    order: "2",
    status: "1",
    sport_id: "1",
    league_id:"26de2483-3d5a-4571-858d-b06b57c4f233",
    sport_name: "Football",
  },
  // {
  //   name: "Series A",
  //   link: "/sports_leagues/8/689",
  //   order: "3",
  //   status: "1",
  //   sport_id: "8",
  //   sport_name: "Basketball",
  // },
  // {
  //   name: "GOLF",
  //   link: "/sports_leagues/13/1",
  //   order: "7",
  //   status: "1",
  //   sport_id: "13",
  //   sport_name: "Golf",
  // },
];