import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import HowItWorks from "../home/HowItWorks";
import "../how-to-play/HowToPlay.css";
import LoginHeader from "../login/LoginHeader";

export default function NewHowToPlay() {
  const [userDataUserId, setUserId] = useState();

  useEffect(() => {
    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (loggedUserData !== null) {
      setUserId(loggedUserData);
    }
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | How to Play</title>
        <meta
          name="description"
          content="4 easy steps to play Top Predictor. Learn how to make predictions for Football, NFL, NHL Cricket and more. "
        />
      </Helmet>
      {!userDataUserId ? <LoginHeader howToPlayValue={true} /> : <Header />}
      <div className="how_to_play_container m-0 col-md-12 primary-background">
        <div className="page_content_center">
          <HowItWorks title="How To Play?"/>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
