import React, { useState } from "react";
import "./ChampionShipImg.css";
import Carousel from "react-bootstrap/Carousel";
function ChampionShipImg() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="championship-contianer">
      <div
        style={{ maxHeight: "800px"}}
        className="container d-flex flex-column flex-md-row align-items-center py-4 gap-5"
      >
            <div className="d-flex flex-column text-white gap-2 ">
          <h2 className=" w-100 w-md-75 text-center text-md-start font-weight-bold wildcard-title">
            Explore a Variety of Contests
          </h2>
          <p className="w-100 w-md-75 text-center text-md-start">
            Browse through multiple contests and discover exciting opportunities
            to participate.
          </p>
        </div>
        <Carousel activeIndex={index} onSelect={handleSelect} style={{height:"100%",maxWidth:"280px", margin:"auto"}}>
          <Carousel.Item>
            <img
              src="assets/image/home/test.png"
              // className="phone-image"
              // style={{maxWidth:"278px"}}
            />
            <Carousel.Caption>
              <h3>Women's Football Competitions</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src="assets/image/home/test3.png"
              // className="phone-image"
              // style={{maxWidth:"278px"}}
            />
            <Carousel.Caption>
              <h3>Men's Football Competitions</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src="assets/image/home/test4.png"
              // className="phone-image"
              // style={{maxWidth:"278px"}}
            />
            <Carousel.Caption>
              <h3>NHL Competitions</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              src="assets/image/home/test5.png"
              // className="phone-image"
              // style={{maxWidth:"278px"}}
            />
            <Carousel.Caption>
              <h3>NFL Competitions</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
    
      </div>
    </div>
  );
}

export default ChampionShipImg;
