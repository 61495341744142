import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "../safer-gambling/SaferGambling.css";
import { redirect } from "react-router-dom";
import Parser from "html-react-parser";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import Modal from "react-bootstrap/Modal";
import ApiContext from "../../providers/Api/ApiContext";

export default function SelfExclusion(props) {
  const { api } = useContext(ApiContext);
  const [selfStaticContent, setSelfStaticContent] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  var body, headers;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getStaticContent = () => {
    var static_content_url = "/api/static_content/get_all_static_content";
    if (props.type === "description") {
      body = {
        page_meta_key: "sg_self_exclusion_without_auth",
      };
      headers = {};
    } else {
      body = {
        page_meta_key: "sg_self_exclusion_with_auth",
      };
    }
    showLoader();
    api
      .post(static_content_url, body)
      .then(function (response) {
        if (response.status === 200) {
          var content = response.data.Data.content.page_content;
          setSelfStaticContent(content);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const submitSelfExclusion = () => {
    const body = {};
    var url = `/api/auth/suspend`;
    showLoader();
    api
      .post(url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          console.log(response.data);
          alert_notify("success", response.data.Message);
          redirect("/submited/safer_gambling/selfexclusion");
          setTimeout(() => {
            redirect("/login");
          }, 2000);
        } else if (response.status === 401) {
          redirect("/login");
        } else {
          console.log(response.error);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  useEffect(() => {
    getStaticContent();
  }, []);

  return (
    <React.Fragment>
      <div className="self_exclusion safer_gambling_container mt-3 primary-background">
        {props.type === "gambling" ? (
          <div>
            <div className="safer_title">{Parser(selfStaticContent)}</div>
            <div className="gambling_deactivate_account">
              <ButtonPrimary
                textValue={
                  !props.labels.safer_gambling_deactivate_account
                    ? "Deactivate Account"
                    : props.labels.safer_gambling_deactivate_account.label_name
                }
                classValue="btn_primary btn_deactivate_account"
                onClick={handleShow}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="safer_title">{Parser(selfStaticContent)}</div>
          </div>
        )}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deactivation </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            Are you sure you want to deactivate your account?
          </Modal.Body>
          <Modal.Footer>
            <ButtonPrimary textValue="NO" onClick={handleClose} />
            <ButtonPrimary textValue="YES" onClick={submitSelfExclusion} />
          </Modal.Footer>
        </Modal>
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
