import React from "react";
import "./oddsImage.css";
function Section3() {
  return (
    <div className="primary-background ">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-center py-5 gap-5">
        <div className="d-flex justify-content-center">
          <img
            src="assets/image/home/challange6.png"
            className="phone-image"
            // style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div className="d-flex flex-column text-white gap-2">
          <h2 className="w-100 w-md-75 text-center text-md-start font-weight-bold oddsimage-title">
            Live leaderboard
          </h2>
          <p className="w-100 w-md-75 text-center text-md-start">
            Check your position on the leader board in real time
          </p>
          <p className="w-100 w-md-75 text-center text-md-start">
            Not doing great? You can change your predictions for remaining games! 
          </p>
          <p className="w-100 w-md-75 text-center text-md-start">
          Have you got a wild card left to play? Sometimes it’s a good strategy to keep a wild card for the final few matches! 
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section3;
