import React, { useState, useEffect, useContext } from "react";
import AccountHeader from "../account-header/AccountHeader";
import Footer from "../footer/Footer";
import ButtonPrimary from "../buttons/ButtonPrimary";
import LoginHeader from "../login/LoginHeader";
import useLoading from "../../hooks/UseLoading";
import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import ApiContext from "../../providers/Api/ApiContext";

export default function ActivateAccount() {
  const { api } = useContext(ApiContext);
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();
  var { token } = useParams();

  const accountActiveLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    showLoader();
    api
      .get(`/api/auth/activate_account/${token}`)
      .then((response) => {
        alert_notify(
          "success",
          "Account successfully verified, Please Login to your account with Username or Email"
        );
        hideLoader();
      })
      .catch((error) => {
        alert_notify("error", error.response.data.Message);
        hideLoader();
      });
  }, [token]);

  var loginClassVal = `btn_primary btn_active_account`;

  return (
    <React.Fragment>
      <LoginHeader />
      <div className="active_account_container">
        <div className="page_content_center">
          <SuccesNotification
            title="Your account is now active please login!"
            content={""}
          />
          <ButtonPrimary
            classValue={loginClassVal}
            textValue="LOGIN"
            onClick={accountActiveLogin}
          />
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
