import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import ApiContext from "../../providers/Api/ApiContext";
import axios from "axios";

const tempPageData = {
  interac: {
    value: "",
    converted_value: "",
  },
  card: {
    value: "",
  },
};

export default function Deposit(props) {
  const { api } = useContext(ApiContext);
  const [pageData, setPageData] = useState(tempPageData);
  const [depositSettings, setDepositSettings] = useState({});
  const [validDepositInterac, setValidDepositInterac] = useState(true);
  const [exchange, setExchange] = useState(0);
  const [validDeposit, setValidDeposit] = useState(true);
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const navigate = useNavigate();
  var sls_api_url = process.env.REACT_APP_SLS_API_URL;

  // Top Up deposit
  const depositTopUp = () => {
    let tempData = { ...pageData };
    var user_id = props.userData.user_id;
    var balance = tempData.card.value;
    balance = twoDecimalFixed(balance);
    const body = {
      user_id: user_id,
      balance: balance,
      status: 0,
    };

    var url = `/api/my_account/user_deposit`;
    showLoader();
    api
      .post(url, body)
      .then((response) => {
        if (!response.error) {
          var data = JSON.parse(response.data.Data);
        } else if (response.status === 401) {
          navigate("/login");
        } else {
          console.log(response.error);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  // Interac Deposit
  const interacDeposit = () => {
    let tempData = { ...pageData };
    var user_id = props.userData.user_id;
    var balance = tempData.interac.value;
    balance = twoDecimalFixed(balance);
    const body = {
      user_id: user_id,
      balance: balance,
      status: 0,
    };
    var url = `/api/my_account/interac_user_deposit`;
    showLoader();
    api
      .post(url, body)
      .then((response) => {
        if (response.status && response.status == 200) {
          window.location.href = `https://interac.express-connect.com/webflow?transaction=${response.data?.Data?.transaction_id}&token=${response.data?.Data?.token}`;
        } else if (response.status === 401) {
          navigate("/login");
        } else {
          console.log(response.error);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };
  const checkAmountInput = (value, type) => {
    if (isNaN(value)) {
      alert_notify("error", "Value must be a number");
    } else {
      let tempData = { ...pageData };
      if (type == "interac") {
        if (
          Number(value) * exchange >=
            Number(depositSettings.deposit_interac_min_limit) &&
          Number(value) * exchange <=
            Number(depositSettings.deposit_interac_max_limit) &&
          !isNaN(Number(value))
        ) {
          setValidDepositInterac(false);
        } else {
          setValidDepositInterac(true);
        }
        tempData.interac.value = value;
        tempData.card.value = "";
        checkExchange();
      } else {
        if (
          Number(value) >= Number(depositSettings.deposit_card_min_limit) &&
          Number(value) <= Number(depositSettings.deposit_card_max_limit) &&
          !isNaN(Number(value))
        ) {
          setValidDeposit(false);
        } else {
          setValidDeposit(true);
        }
        tempData.card.value = value;
        tempData.interac.value = "";
      }
      setPageData(tempData);
    }
  };

  const checkExchange = () => {
    var url = `${sls_api_url}/v1/getExchange?convert=EURCAD`;
    showLoader();
    axios
      .get(url)
      .then((response) => {
        if (response?.status == 200) {
          let exchange = Number(response.data.exchange) / 1.01;
          setExchange(response.data.exchange);
          var tempData = { ...pageData };
          tempData.interac.converted_value = Number(
            tempData.interac.value * exchange
          ).toFixed(2);
          setPageData(tempData);
          hideLoader();
        }
      })
      .catch((err) => {
        alert_notify("error", err.response?.data?.Message);
        hideLoader();
      });
  };

  const getDepositSettings = () => {
    var url = `/api/my_account/get_deposit_settings`;

    showLoader();
    api
      .post(url)
      .then((response) => {
        setDepositSettings(response.data.Data);
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const twoDecimalFixed = (number) => {
    var num = number;
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return with2Decimals;
  };

  useEffect(() => {
    getDepositSettings();
    checkExchange();
  }, []);
  return (
    <React.Fragment>
      <div className="deposit_container">
        <div className="center_account" style={{ paddingTop: "0" }}>
          <div>
            <span className="deposit_terms text-white">
              {!props.labels.my_account_minimum_deposit
                ? "Minimum deposit amount is 10.00 EUR"
                : props.labels.my_account_minimum_deposit.label_name}
            </span>
            <span className="deposit_terms text-white">
              {!props.labels.my_account_maximum_deposit
                ? "Maximum deposit amount is 5,000.00 EUR"
                : props.labels.my_account_maximum_deposit.label_name}
            </span>
          </div>
          <div className="row mt-4">
            <label
              style={{ textTransform: "uppercase" }}
              className="text-white"
            >
              {!props.labels.my_account_topup_with_debit_card
                ? "TOP UP WITH DEBIT CARD"
                : props.labels.my_account_topup_with_debit_card.label_name}
            </label>
            <div className="col-sm-6 col-12">
              <input
                type="text"
                onChange={(e) => checkAmountInput(e.target.value, "topUp")}
                placeholder={
                  !props.labels.my_account_add_amount_eur
                    ? "Add amount EUR"
                    : props.labels.my_account_add_amount_eur.label_name
                }
                id="input_deposit_amount"
                className="account_input"
                value={pageData.card.value}
                autoComplete="off"
              />
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <ButtonPrimary
                textValue={
                  !props.labels.my_account_topup
                    ? "TOP UP"
                    : props.labels.my_account_topup.label_name
                }
                classValue={`btn_primary btn_deposit_topup float-end ${
                  validDeposit ? "disabled_button" : ""
                }`}
                onClick={depositTopUp}
              />
            </div>
          </div>
          {/*Check if user is from Canada, if yes show interac option */}
          {props.userData.master_country_id === "38" ? (
            <div className="row mt-5">
              <div className="col-12">
                <label className="text-white">
                  {!props.labels.my_account_topup_with_interac
                    ? "Top-up with INTERAC® WALLET TRANSFER"
                    : props.labels.my_account_topup_with_interac.label_name}
                </label>
              </div>
              <div className="col-sm-6 col-12">
                <input
                  type="text"
                  id="input_interac_deposit"
                  onChange={(e) => checkAmountInput(e.target.value, "interac")}
                  placeholder={
                    !props.labels.my_account_add_amount_eur
                      ? "Add amount EUR"
                      : props.labels.my_account_add_amount_eur.label_name
                  }
                  className="account_input"
                  value={pageData.interac.value}
                  autoComplete="off"
                />
              </div>

              <div className="col-sm-6 col-12 mt-sm-0 mt-3">
                <ButtonPrimary
                  textValue={
                    !props.labels.my_account_topup
                      ? "TOP UP"
                      : props.labels.my_account_topup.label_name
                  }
                  classValue={`btn_primary btn_deposit_transfer float-end ${
                    validDepositInterac ? "disabled_button" : ""
                  }`}
                  onClick={interacDeposit}
                />
              </div>
              <div className="col-12">
                {pageData.interac.value !== "" ? (
                  <span className="cooming_soon">
                    {Number(pageData.interac.value).toFixed(2)} EUR ={" "}
                    {pageData.interac.converted_value} CAD
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="deposit_terms trade_mark">
                <p>® Trade-Mark Of Interac Corp. Used Under License</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
