import React, { useState } from "react";
import AccountHeader from "../account-header/AccountHeader";
import Footer from "../footer/Footer";
import ButtonPrimary from "../buttons/ButtonPrimary";
import LoginHeader from "../login/LoginHeader";
import "./ResetPassword.css";
import useLoading from "../../hooks/UseLoading";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useAlert from "../../hooks/UseAlert";

function encryptPassword(password) {
  return btoa(password);
}

export default function ResetEmail() {
  const [showPassword, setShowPassword] = useState(false);
  const [validePassword, setValidePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();
  var { token } = useParams();
  var url = process.env.REACT_APP_API_URL;

  const showPasswordStatus = () => {
    setShowPassword(!showPassword);
  };

  const getResetPassword = () => {
    var reset_password = document.getElementById(
      "input_confirm_password"
    ).value;
    if (reset_password !== "") {
      document
        .getElementById("input_confirm_password_required")
        .classList.add("d-none");
      setValidePassword(true);
    } else {
      document
        .getElementById("input_confirm_password_required")
        .classList.remove("d-none");
      setValidePassword(false);
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    var reset_password_url = "" + url + "/api/auth/submit_reset_password";

    // var your_password = document.getElementById("input_confirm_password").value;

    var body = {
      password: encryptPassword(password),
      confirm_password: encryptPassword(password),
      token: token,
    };
    var headers = {};
    if (validePassword) {
      showLoader();
      axios
        .post(reset_password_url, body, headers)
        .then(function (response) {
          hideLoader();
          if (response.status === 200) {
            alert_notify("success", "Password reset successfully.");
            setTimeout(() => {
              navigate("/home");
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
        });
    } else {
      alert_notify("error", "Password can not be empty.");
    }
  };

  var resetPasswordClassVal = "btn_account_action btn_primary";
  return (
    <React.Fragment>
      <LoginHeader />
      <AccountHeader
        headerValue="Reset Password"
        firstTextValue="Type Your Password To Reset. "
      />
      <div className="reset_password_container">
        <div className="page_content_center">
          <form id="reset_password_form" onSubmit={handleResetPassword}>
            <div className="row justify-content-center ">
              <div className="col-12 col-lg-5 col-xl-5 mt-4 position-relative">
                <span className="show_password" onClick={showPasswordStatus}>
                  <img
                    src="/assets/icons/show.svg"
                    alt="show-icon"
                    style={{ width: "20px" }}
                  />
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  maxLength={200}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  id="input_confirm_password"
                  style={{ width: "100%" }}
                />
                <span
                  className="required_message d-none"
                  id="input_confirm_password_required"
                >
                  Password is required
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5 col-xl-5 mt-4 d-flex reset_password_submit">
                <ButtonPrimary
                  classValue={resetPasswordClassVal}
                  textValue="CONFRIM"
                  form="reset_password_form"
                  onClick={getResetPassword}
                  d={password.length < 8}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
