import React, { useState, createContext, useContext } from "react";

const ThemeContext = createContext({
  colors: {},
});

export const useThemeProvider = () => useContext(ThemeContext);
const ThemeProvider = ({ children }) => {
  const [colors, setcolors] = useState({
    primary: "#12332E",
    secondary: "#ffb500",
  });

  const value = {
    colors,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
