import React, { useState, useEffect, useContext } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ButtonSecondary from "../buttons/ButtonSecondary";
import Deposit from "../account/Deposit";
import Withdraw from "../account/Withdraw";
import Transactions from "../account/Transactions";
import "../account/Account.css";
import ProfileAccount from "./ProfileAccount";
import ApiContext from "../../providers/Api/ApiContext";
import UserContext from "../../providers/User/UserContext";
import { useNavigate } from "react-router-dom";

export default function Account() {
  const { api } = useContext(ApiContext);
  const { user, isLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [accountState, setAccountState] = useState("deposit");
  const [accountLabels, setAccountLabels] = useState([]);

  const handleAccountType = (e) => {
    var type = e.target.getAttribute("datatype");
    setAccountState(type);
  };

  const getAccountLabels = (page_name) => {
    var account_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: page_name,
    };
    api
      .post(account_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setAccountLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var loggedUserData = JSON.parse(localStorage.getItem("userData"));
  if (loggedUserData != null) {
    var userTotalbalance =
      Number(loggedUserData.balance) + Number(loggedUserData.winning_balance);
  } else {
    var userTotalbalance = 0;
  }

  useEffect(() => {
    if (!isLoading) {
      if (
        user?.master_country_id != "38" ||
        (user?.master_country_id == "38" && user?.phone_no?.length > 4)
      ) {
        console.log(user?.master_country_id);
        getAccountLabels("my account");
      } else {
        navigate("/profile/verify-phone");
      }
    }
  }, [isLoading, user]);

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !accountLabels.my_account_page_label
            ? "My Account"
            : accountLabels.my_account_page_label.label_name
        }
      />
      <div className="account_container primary-background">
        <div className="center_account">
          <div className="account_btn_container">
            <ButtonSecondary
              textValue={
                !accountLabels.my_account_deposit
                  ? "DEPOSIT"
                  : accountLabels.my_account_deposit.label_name
              }
              classValue={
                accountState === "deposit"
                  ? "btn_secondary btn_account_deposit active"
                  : "btn_secondary btn_account_deposit"
              }
              onClick={handleAccountType}
              datatype="deposit"
            />
            <ButtonSecondary
              textValue={
                !accountLabels.my_account_withdraw
                  ? "WITHDRAW"
                  : accountLabels.my_account_withdraw.label_name
              }
              classValue={
                accountState === "withdraw"
                  ? "btn_secondary btn_account_withdraw active"
                  : "btn_secondary btn_account_deposit"
              }
              onClick={handleAccountType}
              datatype="withdraw"
            />
            <ButtonSecondary
              textValue={
                !accountLabels.my_account_transaction
                  ? "TRANSACTIONS"
                  : accountLabels.my_account_transaction.label_name
              }
              classValue={
                accountState === "transactions"
                  ? "btn_secondary btn_account_transactions active"
                  : "btn_secondary btn_account_deposit"
              }
              onClick={handleAccountType}
              datatype="transactions"
            />
          </div>
        </div>
        {accountState === "deposit" ? (
          <Deposit
            userData={loggedUserData}
            userTotalbalance={userTotalbalance}
            labels={accountLabels}
          />
        ) : accountState === "withdraw" ? (
          <Withdraw
            userData={loggedUserData}
            userTotalbalance={userTotalbalance}
            labels={accountLabels}
          />
        ) : (
          <Transactions labels={accountLabels} />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
}
