import React, { useState, useEffect, useContext } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ImageContainer from "../image-container/ImageContainer";
import ButtonSecondary from "../buttons/ButtonSecondary";
import LoginHeader from "../login/LoginHeader";
import DepositLimit from "../safer-gambling/DepositLimit";
import RealityCheck from "../safer-gambling/RealityCheck";
import SelfExclusion from "../safer-gambling/SelfExclusion";
import Timeout from "../safer-gambling/Timeout";
import WagerLimit from "../safer-gambling/WagerLimit";
import ProfileAccount from "../account/ProfileAccount";
import { useLocation } from "react-router-dom";
import "../safer-gambling/SaferGambling.css";
import { Helmet } from "react-helmet";
import ApiContext from "../../providers/Api/ApiContext";

export default function SaferGambling() {
  const { api } = useContext(ApiContext);
  const [saferValue, setSaferValue] = useState("deposit-limit");
  const [loggedStatus, setLoggedStatus] = useState("");
  const { search } = useLocation();
  const [userDataUserId, setUserId] = useState();
  const [saferGamblingLabels, setSaferGamblingLabels] = useState([]);
  var saferGambling_storage = localStorage.getItem("saferGamblingType");
  const match = search.match(/type=(.*)/);
  const type = match?.[1];

  const changeSaferType = (e) => {
    var saferType = e.target.getAttribute("datatype");
    localStorage.setItem("saferGamblingType", saferType);
    setSaferValue(saferType);
  };

  const getSaferGamblingLabels = () => {
    var safer_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "safer gambling",
    };
    api
      .post(safer_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setSaferGamblingLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSaferGamblingLabels();
    var logged_status = localStorage.getItem("user_status");
    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }
    if (loggedUserData !== null) {
      setUserId(loggedUserData);
    }

    if (saferGambling_storage !== null) {
      setSaferValue(saferGambling_storage);
    } else {
      setSaferValue("deposit-limit");
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | Safer Gambling</title>
        <meta
          name="description"
          content="Need a break from Top Predictor os simply want to limit the amount of time or your deposits. "
        />
      </Helmet>
      {!userDataUserId ? <LoginHeader howToPlayValue={true} /> : <Header />}
      {!userDataUserId ? (
        <ImageContainer />
      ) : (
        <ProfileAccount
          headerValue={
            !saferGamblingLabels.safer_gambling_page_label
              ? "SAFER GAMBLING"
              : saferGamblingLabels.safer_gambling_page_label.label_name
          }
        />
      )}
      <div className="safer_gambling_container primary-background">
        <div className="page_content_center">
          <h2
            className="safer_gambling_header text-white"
            style={{ textTransform: "uppercase" }}
          >
            {!saferGamblingLabels.safer_gambling_page_label
              ? "SAFER GAMBLING"
              : saferGamblingLabels.safer_gambling_page_label.label_name}
          </h2>
          <div className="safer_gambling_btns">
            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  saferValue === "deposit-limit"
                    ? "btn_safer_gambling btn_secondary active"
                    : "btn_safer_gambling btn_secondary"
                }
                textValue={
                  !saferGamblingLabels.safer_gambling_deposit_limit
                    ? "Deposit Limit"
                    : saferGamblingLabels.safer_gambling_deposit_limit
                        .label_name
                }
                datatype="deposit-limit"
                onClick={changeSaferType}
              />
            </div>
            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  saferValue === "wager-limit"
                    ? "btn_safer_gambling btn_secondary active"
                    : "btn_safer_gambling btn_secondary"
                }
                textValue={
                  !saferGamblingLabels.safer_gambling_wager_limit
                    ? "Wager Limit"
                    : saferGamblingLabels.safer_gambling_wager_limit.label_name
                }
                datatype="wager-limit"
                onClick={changeSaferType}
              />
            </div>
            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  saferValue === "reality-check"
                    ? "btn_safer_gambling btn_secondary active"
                    : "btn_safer_gambling btn_secondary"
                }
                textValue={
                  !saferGamblingLabels.safer_gambling_reality_check
                    ? "Reality Check"
                    : saferGamblingLabels.safer_gambling_reality_check
                        .label_name
                }
                datatype="reality-check"
                onClick={changeSaferType}
              />
            </div>
            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  saferValue === "timeout"
                    ? "btn_safer_gambling btn_secondary active"
                    : "btn_safer_gambling btn_secondary"
                }
                textValue={
                  !saferGamblingLabels.safer_gambling_timeout
                    ? "Timeout"
                    : saferGamblingLabels.safer_gambling_timeout.label_name
                }
                datatype="timeout"
                onClick={changeSaferType}
              />
            </div>
            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  saferValue === "self-exclusion"
                    ? "btn_safer_gambling btn_secondary active"
                    : "btn_safer_gambling btn_secondary"
                }
                textValue={
                  !saferGamblingLabels.safer_gambling_self_exclusion
                    ? "Self Exclusion"
                    : saferGamblingLabels.safer_gambling_self_exclusion
                        .label_name
                }
                datatype="self-exclusion"
                onClick={changeSaferType}
              />
            </div>
          </div>
          {saferValue === "deposit-limit" ? (
            <DepositLimit type={type} labels={saferGamblingLabels} />
          ) : saferValue === "wager-limit" ? (
            <WagerLimit type={type} labels={saferGamblingLabels} />
          ) : saferValue === "reality-check" ? (
            <RealityCheck type={type} labels={saferGamblingLabels} />
          ) : saferValue === "timeout" ? (
            <Timeout type={type} labels={saferGamblingLabels} />
          ) : (
            <SelfExclusion type={type} labels={saferGamblingLabels} />
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
