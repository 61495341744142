import React, { useContext, useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "./CreateClubFundraiser.css";
import { useNavigate, useParams } from "react-router-dom";
import ProfileAccount from "../account/ProfileAccount";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import Parser from "html-react-parser";
import ApiContext from "../../providers/Api/ApiContext";
import { useForm } from "react-hook-form"; // Import useForm
export default function CreateClubFundraiser() {
  const { api, soccerApi } = useContext(ApiContext);
  const navigate = useNavigate();
  const { sportId, leagueId } = useParams();
  const [loader, showLoader, hideLoader] = useLoading();
  const [leagues, setLeagues] = useState([]);
  const [sports, setSports] = useState([
    {
      sport_id: 1,
      sport_name: "Soccer",
    },
  ]);
  const [contests, setContests] = useState([]);
  const [alert, alert_notify] = useAlert();
  const [staticContent, setStaticContent] = useState("");
  const [successStatus, setSuccesStatus] = useState(false);
  const [cfLabels, setCfLabels] = useState([]);
  const [payoutTotal, setPayoutTotal] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      organisation_name: "",
      account_name: "",
      sort_code: "",
      account_number: "",
      competition_title: "",
      entry_fee: '',
      entrants: '',
      sportsFirst: 50,
      sportsSecond: 25,
      sportsThird: 15,
      sportsClub: 10,
      rake: 10,
      currency: "EUR",
      sport_id: 0,
      league_id: 0,
      contest_id: 0,
    },
  });
  const formValues = watch();

  const calculateMaxPayout = (percentage) => {
    return (
      ((formValues.entry_fee * formValues.entrants) / 100) *
      percentage
    ).toFixed(2);
  };

  const calculateRake = (percentage) => {
    return (
      (calculateMaxPayout(formValues.sportsClub) / 100) *
      percentage
    ).toFixed(2);
  };

  const submitCreateClubFundraiser = (data) => {
    var create_club_fundraiser_url = "/contest/request_contest";
    if (payoutTotal !== 100) {
      alert_notify("error", "Payouts must equal 100%");
      return false;
    }
    var body = {
      organisation_name: formValues.organisation_name,
      bankaccount_name: formValues.account_name,
      bankaccount_number: formValues.account_number,
      bankaccount_sortcode: formValues.sort_code,
      competition_title: formValues.competition_title,
      contest_type: "club",
      league_id: formValues.league_id,
      contest_id: formValues.contest_id,
      entry_fee: formValues.entry_fee,
      num_of_entrants: formValues.entrants,
      payout_1st: formValues.sportsFirst,
      payout_2nd: formValues.sportsSecond,
      payout_3rd: formValues.sportsThird,
      payout_club: formValues.sportsClub,
      rake: formValues.rake,
      sport_id: Number(formValues.sport_id),
      currency: formValues.currency,
    };
    showLoader();
    soccerApi
      .post(create_club_fundraiser_url, body)
      .then((response) => {
        if (response.status === 200) {
          setSuccesStatus(true);
          alert_notify(
            "success",
            "Club Fundraiser request has been made successfully."
          );
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const clubFundraiserLabels = () => {
    var cf_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "create club fundraiser",
    };
    showLoader();
    api
      .post(cf_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setCfLabels(data);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };
  const getStaticContent = () => {
    var cf_static_content_url = "/api/static_content/get_all_static_content";
    var body = {
      page_meta_key: "club_fundraiser",
    };
    showLoader();
    api
      .post(cf_static_content_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data?.content?.page_content;
          setStaticContent(data);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  const getContestByLeagueId = (leagueId) => {
    var content_url = `/contest/fetch_contest/${leagueId}`;
    showLoader();
    soccerApi
      .get(content_url)
      .then((response) => {
        if (response.status === 200) {
          setContests(response.data.data.contest);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  useEffect(() => {
    clubFundraiserLabels();
    getStaticContent();
    setSuccesStatus(false);
  }, [leagueId]);

  useEffect(() => {
    setPayoutTotal(
      Number(formValues.sportsFirst) +
        Number(formValues.sportsSecond) +
        Number(formValues.sportsThird) +
        Number(formValues.sportsClub)
    );
  }, [
    formValues.sportsFirst,
    formValues.sportsSecond,
    formValues.sportsThird,
    formValues.sportsClub,
  ]);

  const fetchLeagues = async (sportId) => {
    try {
      if (sportId == 0) {
        setLeagues([]);
        return;
      }

      showLoader();
      let result = await soccerApi.get(`/get_leagues`);
      setLeagues(result.data.data.content ?? []);
    } catch (error) {
      alert_notify(error.message);
    } finally {
      hideLoader();
    }
  };
  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !cfLabels.create_club_fundraiser_page_label
            ? "CREATE CLUB FUNDRAISER"
            : cfLabels.create_club_fundraiser_page_label.label_name
        }
      />
      <div className="create_club_fundraiser_container primary-background">
        {successStatus ? (
          <SuccesNotification content="Club Fundraising sign up has been sent successfully." />
        ) : (
          <div className="page_content_center">
            {Parser(staticContent)}
            <form
              id="form_club_fundraiser"
              onSubmit={handleSubmit(submitCreateClubFundraiser)}
            >
              <div className="create_club_fundraiser_info">
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="organisation_name"
                      className="organization_name_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_organisation_name
                          ? "Organisation Name"
                          : cfLabels.create_club_fundraiser_organisation_name
                              .label_name
                      }
                      {...register("organisation_name", {
                        required: "Organisation Name is required",
                      })}
                    />
                    {errors.organisation_name && (
                      <span className="text-error-club">
                        {errors.organisation_name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="account_name"
                      className="account_name_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_account_name
                          ? "Account Name"
                          : cfLabels.create_club_fundraiser_account_name
                              .label_name
                      }
                      {...register("account_name", {
                        required: "Account Name is required",
                      })}
                    />
                    {errors.account_name && (
                      <span className="text-error-club">
                        {errors.account_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="sort_code"
                      className="sort_code_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_sort_code
                          ? "Sort Code"
                          : cfLabels.create_club_fundraiser_sort_code.label_name
                      }
                      {...register("sort_code", {
                        required: "Sort Code is required",
                      })}
                    />
                    {errors.sort_code && (
                      <span className="text-error-club">
                        {errors.sort_code.message}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="account_number"
                      className="account_number_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_account_number
                          ? "Account Number"
                          : cfLabels.create_club_fundraiser_account_number
                              .label_name
                      }
                      {...register("account_number", {
                        required: "Account Number is required",
                      })}
                    />
                    {errors.account_number && (
                      <span className="text-error-club">
                        {errors.account_number.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <select
                      className="currency_select"
                      id="currency"
                      placeholder="Currency"
                      {...register("currency")}
                    >
                      <option value="EUR">EUR</option>
                      <option value="CAD">CAD</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="club_fundraiser_selections">
                <div className="club_fundraiser_select_options_tittle col-12">
                  <span className="fw-normal club_fundraiser_details text-white">
                    Please select one option only from each of the following:
                  </span>
                </div>
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-5">
                    <div className="col-12 mt-4">
                      <input
                        type="text"
                        id="competition_title"
                        className="competition_title_input"
                        maxLength="50"
                        placeholder={
                          !cfLabels.create_club_fundraiser_competition_title
                            ? "Competition Title"
                            : cfLabels.create_club_fundraiser_competition_title
                                .label_name
                        }
                        {...register("competition_title", {
                          required: "Competition Title is required",
                        })}
                      />
                      {errors.competition_title && (
                        <span className="text-error-club">
                          {errors.competition_title.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        id="create_club_fundraiser_sports"
                        {...register("sport_id", {
                          validate: (value) =>
                            value != "0" || "Please select a sport",
                          onChange: (e) => {
                            fetchLeagues(e.target.value);
                          },
                        })}
                      >
                        <option value="0">
                          {!cfLabels.create_club_fundraiser_sport
                            ? "Sport"
                            : cfLabels.create_club_fundraiser_sport.label_name}
                        </option>
                        {sports.map((sport, index) => {
                          return (
                            <option
                              data-id={sport.sport_id}
                              key={index}
                              value={sport.sport_id}
                            >
                              {sport.sport_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.sport_id && (
                        <span className="text-error-club">
                          {errors.sport_id.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        {...register("league_id", {
                          validate: (value) =>
                            value != "0" || "League is required",
                          onChange: (e) => {
                            getContestByLeagueId(e.target.value);
                          },
                        })}
                      >
                        <option value="0">
                          {!cfLabels.create_club_fundraiser_league
                            ? "League"
                            : cfLabels.create_club_fundraiser_league.label_name}
                        </option>
                        {leagues.map((league, index) => {
                          return (
                            <option
                              data-id={league.league_id}
                              data-value={league.league_id}
                              value={league.league_id}
                              key={index}
                            >
                              {league.league_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.league_id && (
                        <span className="text-error-club">
                          {errors.league_id.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        {...register("contest_id", {
                          validate: (value) =>
                            value != "0" || "Contest is required",
                        })}
                      >
                        <option value="0">Select Contest</option>
                        {contests.map((contest, index) => {
                          return (
                            <option
                              data-id={contest.contest_id}
                              value={contest.contest_id}
                              data-value={contest.contest_id}
                              key={index}
                            >
                              ({contest.contest_uid}) {contest.contest_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.contest_id && (
                        <span className="text-error-club">
                          {errors.contest_id.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <input
                        type="number"
                        id="create_club_fundraiser_entry_fee"
                        className="create_club_fundraiser_select"
                        placeholder={
                          !cfLabels.create_club_fundraiser_entryfee
                            ? "Entry Fee (" + formValues.currency + ")"
                            : cfLabels.create_club_fundraiser_entryfee
                                .label_name +
                              " (" +
                              formValues.currency +
                              ")"
                        }
                        data-type="entryfee"
                        step="1"
                        min="1"
                        {...register("entry_fee", {
                          required: "Entry fee is required",
                        })}
                      />
                      {errors.entry_fee && (
                        <span className="text-error-club">
                          {errors.entry_fee.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <input
                        type="number"
                        id="create_club_fundraiser_entrants"
                        className="create_club_fundraiser_select"
                        placeholder={
                          !cfLabels.create_club_fundraiser_entrants
                            ? "Entrants"
                            : cfLabels.create_club_fundraiser_entrants
                                .label_name
                        }
                        data-type="entrants"
                        step="1"
                        min="2"
                        {...register("entrants", {
                          required: "Number of entries are required",
                        })}
                      />
                      {errors.entrants && (
                        <span className="text-error-club">
                          {errors.entrants.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-7">
                    <div className="col-12 mt-4">
                      <span
                        className={
                          payoutTotal === 100 ? "text-white" : "red_color"
                        }
                      >
                        {!cfLabels.create_club_fundraiser_payouts
                          ? "Payouts"
                          : cfLabels.create_club_fundraiser_payouts
                              .label_name}{" "}
                        {payoutTotal}
                        (%)
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_1stplace
                          ? "1st Place"
                          : cfLabels.create_club_fundraiser_1stplace.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_sports_first"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="50"
                        data-type="sports_first"
                        min="0"
                        max="100"
                        {...register("sportsFirst", {
                          required: "1st payout is required",
                        })}
                      />
                      {errors.sportsFirst && (
                        <span className="text-error-club">
                          {errors.sportsFirst.message}
                        </span>
                      )}
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {formValues.currency}{" "}
                          {calculateMaxPayout(formValues.sportsFirst)}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_2ndplace
                          ? "2nd Place"
                          : cfLabels.create_club_fundraiser_2ndplace.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_sports_second"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="25"
                        data-type="sports_second"
                        min="0"
                        max="100"
                        {...register("sportsSecond", {
                          required: "2nd payout is required",
                        })}
                      />
                      {errors.sportsSecond && (
                        <span className="text-error-club">
                          {errors.sportsSecond.message}
                        </span>
                      )}
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {formValues.currency}{" "}
                          {calculateMaxPayout(formValues.sportsSecond)}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_3rdplace
                          ? "3rd Place"
                          : cfLabels.create_club_fundraiser_3rdplace.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_sports_third"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="15"
                        data-type="sports_third"
                        min="0"
                        max="100"
                        {...register("sportsThird", {
                          required: "3rd payout is required",
                        })}
                      />
                      {errors.sportsThird && (
                        <span className="text-error-club">
                          {errors.sportsThird.message}
                        </span>
                      )}
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {formValues.currency}{" "}
                          {calculateMaxPayout(formValues.sportsThird)}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_club
                          ? "Club"
                          : cfLabels.create_club_fundraiser_club.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_club"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="10"
                        data-type="sports_club"
                        min="0"
                        max="100"
                        {...register("sportsClub", {
                          required: "Sports club is required",
                        })}
                      />
                      {errors.sportsClub && (
                        <span className="text-error-club">
                          {errors.sportsClub.message}
                        </span>
                      )}
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {formValues.currency} {calculateMaxPayout(formValues.sportsClub)}{" "}
                          Potential Earnings
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col">
                      <span className="light_color text-white">Rake</span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_club"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="10"
                        data-type="rake"
                        min="0"
                        max="100"
                        {...register("rake", { required: "Rake is required" })}
                      />
                      {errors.rake && (
                        <span className="text-error-club">
                          {errors.rake.message}
                        </span>
                      )}
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {formValues.currency} {calculateRake(formValues.rake)} Site Rake
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 mt-4 create_club_fundraiser_btn">
                <div className="col-12">
                  <ButtonPrimary
                    classValue="btn_primary btn_create_club_fundraiser"
                    datapath="my_competition"
                    type="submit"
                    form="form_club_fundraiser"
                    textValue={
                      !cfLabels.create_club_fundraiser_submit
                        ? "Submit"
                        : cfLabels.create_club_fundraiser_submit.label_name
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
