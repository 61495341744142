import React from 'react'
import '../image-container/image_container.css'

export default function ImageContainer() {
    return (
        <React.Fragment>
            <div className="image_conainer">
                <div className="page_content_center d-flex justify-content-end p-0">
                    <div className="background_image">
                        <img src="assets/image/sports.png" alt="imgcontainer"/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
