import React, { useState, useEffect, useContext } from "react";
import AccountHeader from "../account-header/AccountHeader";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "./ForgotPassword.css";
import Footer from "../footer/Footer";
import LoginHeader from "../login/LoginHeader";
import useLoading from "../../hooks/UseLoading";
import { useLocation, useNavigate } from "react-router-dom";
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";
import { emailRegex } from "../../utils/validations";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import ApiContext from "../../providers/Api/ApiContext";

export default function ForgotPassword() {
  const { api } = useContext(ApiContext);
  const [type, setType] = useState("Forgot");
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [forgotPasswordLabels, setForgotPasswordLabels] = useState([]);
  const [loader, showLoader, hideLoader] = useLoading();
  const [isOTPSend, setIsOTPSend] = useState(false);
  const [otp, setOTP] = useState("");
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
    passwordVisible: false,
    confirmPasswordVisible: false,
  });
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();
  const [successStatus, setSuccesStatus] = useState(false);
  var loggedUserData = JSON.parse(localStorage.getItem("userData"));

  const getForgotPasswordData = () => {
    // var forgot_email = document.getElementById("forgot_password_email").value;
    if (email !== "") {
      document
        .getElementById("input_forgot_email_required")
        .classList.add("d-none");
      setValidEmail(true);
    } else {
      document
        .getElementById("input_forgot_email_required")
        .classList.remove("d-none");
      setValidEmail(false);
    }
  };

  const handleForgotPasswordSendOTP = async (e) => {
    e.preventDefault();
    if (validEmail) {
      showLoader();
      try {
        const res = await resetPassword({
          username: email.trim(),
        });
        handleResetPasswordNextSteps(res);
      } catch (err) {
        console.log(err);
        hideLoader();
      }
    } else {
      console.log("FORGOT PASSWORD ERROR");
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    if (otp === "") {
      alert_notify(
        "error",
        "Please check your email for the OTP code and enter below and also enter your new password."
      );
      return;
    }
    if (password.password.length < 8 && password.password > 20) {
      alert_notify(
        "error",
        "Password must be atlest 8 characters long and less than 20 characters"
      );
      return;
    }
    if (password.password !== password.confirmPassword) {
      alert_notify("error", "Password is not matching with confirm password");
      return;
    }
    try {
      const res = await confirmResetPassword({
        username: email.trim(),
        confirmationCode: otp.trim(),
        newPassword: password.password.trim(),
      });
      alert_notify("success", "Password reset successfully");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.log(error);
      if (error?.message) {
        alert_notify("error", error.message);
      }
    }
  };

  function handleResetPasswordNextSteps(output) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        alert_notify(
          "success",
          "Please check your email for the OTP code and enter below and also enter your new password."
        );
        hideLoader();
        setIsOTPSend(true);
        break;
      case "DONE":
        console.log("Successfully reset password.");
        hideLoader();
        break;
    }
  }

  const getForgotPasswordLabels = () => {
    var forgot_password_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "forgot password form",
    };
    api
      .post(forgot_password_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setForgotPasswordLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getForgotPasswordLabels();
    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    if (loggedUserData) {
      navigate("/home");
    }
    setSuccesStatus(false);
  }, [loggedUserData]);
  useEffect(() => {
    if (state?.type == "Reset") {
      setType("Reset");
      alert_notify(
        "error",
        "Your password has expired. Please enter your email address for an OTP code which can be used to update your password. "
      );
    }
  }, [state]);
  var forgotClassVal =
    "btn_forgot_password_user btn_account_action btn_primary";

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | {type} Password</title>
        <meta
          name="description"
          content="Forgot password to Top Predictor. Enetr your email and reset your password here."
        />
      </Helmet>
      <LoginHeader loginValue={false} />
      <AccountHeader
        headerValue={`${type} PASSWORD`}
        firstTextValue="To reset your password please enter the email address associated with your account. "
        secondTextValue="An email will be sent to you with instructions on how to complete changing your password."
      />
      <div className="forgot_password_container primary-background">
        <div className="page_content_center">
          <form
            id="forgot_password_form"
            onSubmit={
              isOTPSend
                ? handleForgotPasswordSubmit
                : handleForgotPasswordSendOTP
            }
          >
            <div className="row reset_password_container d-flex">
              <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                <input
                  type="email"
                  className="forgot_password_email"
                  maxLength={200}
                  disabled={isOTPSend}
                  placeholder={
                    !forgotPasswordLabels.fp_form_email_label
                      ? "Email"
                      : forgotPasswordLabels.fp_form_email_label.label_name
                  }
                  id="forgot_password_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  className="required_message d-none"
                  id="input_forgot_email_required"
                >
                  {!forgotPasswordLabels.err_fp_form_email_req
                    ? "Email is required"
                    : forgotPasswordLabels.err_fp_form_email_req.label_name}
                </span>
              </div>
              {isOTPSend ? (
                <>
                  <div className="mt-4 d-flex justify-content-center align-items-center">
                    <input
                      type="number"
                      className="forgot_password_email"
                      maxLength={200}
                      disabled={!isOTPSend}
                      placeholder={"OTP code sent to your email address"}
                      id="forgot_password_otp"
                      onChange={(e) => setOTP(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 d-flex justify-content-center align-items-center position-relative">
                    <span
                      className="show_password"
                      onClick={(e) =>
                        setPassword({
                          ...password,
                          passwordVisible: !password.passwordVisible,
                        })
                      }
                    >
                      <img
                        src="assets/icons/show.svg"
                        alt="show-icon"
                        style={{ width: "20px" }}
                      />
                    </span>
                    <input
                      type={password.passwordVisible ? "text" : "password"}
                      className="forgot_password_email"
                      maxLength={30}
                      disabled={!isOTPSend}
                      placeholder={"New Password"}
                      value={password.password}
                      id="forgot_password_otp"
                      onChange={(e) =>
                        setPassword({ ...password, password: e.target.value })
                      }
                    />
                  </div>
                  <div className="mt-4 d-flex justify-content-center align-items-center position-relative">
                    <span
                      className="show_password"
                      onClick={(e) =>
                        setPassword({
                          ...password,
                          confirmPasswordVisible:
                            !password.confirmPasswordVisible,
                        })
                      }
                    >
                      <img
                        src="assets/icons/show.svg"
                        alt="show-icon"
                        style={{ width: "20px" }}
                      />
                    </span>
                    <input
                      type={
                        password.confirmPasswordVisible ? "text" : "password"
                      }
                      className="forgot_password_email"
                      maxLength={30}
                      disabled={!isOTPSend}
                      placeholder={"Confirm New Password"}
                      value={password.confirmPassword}
                      id="forgot_password_otp"
                      onChange={(e) =>
                        setPassword({
                          ...password,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              ) : null}
              <div className="col-12 mt-4 d-flex justify-content-center">
                <ButtonPrimary
                  classValue={forgotClassVal}
                  textValue={
                    !isOTPSend
                      ? "sent OTP code"
                      : !forgotPasswordLabels.fp_form_submit_btn
                      ? "Reset Password"
                      : forgotPasswordLabels.fp_form_submit_btn.label_name
                  }
                  onClick={getForgotPasswordData}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
