import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import ApiContext from "../../providers/Api/ApiContext";

const tempPageWidData = {
  interac: {
    value: "",
    converted_value: "",
  },
  card: {
    value: "",
  },
};

export default function Withdraw(props) {
  const { api } = useContext(ApiContext);
  const [validWithdraw, setValidWithdraw] = useState(true);
  const [validWithdrawInterac, setValidWithdrawInterac] = useState(true);
  const [withdrawSettings, setWithdrawSettings] = useState({});
  const [exchange, setExchange] = useState(0);
  const [pageWidData, setPageWidData] = useState(tempPageWidData);
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const navigate = useNavigate();
  var sls_api_url = process.env.REACT_APP_SLS_API_URL;
  // Withdraw request
  const withdrawAmountRequest = (e) => {
    let tempPageWidData = { ...pageWidData };
    var type = e.currentTarget.getAttribute("datatype");
    if (type == "3") {
      var balance = tempPageWidData.interac.value;
    } else {
      var balance = tempPageWidData.card.value;
    }
    var withdraw_url = `/api/finance/withdraw_fund`;
    const body = {
      withdraw_amt: balance,
      withdraw_type: type,
      full_name: props.userData.first_name + " " + props.userData.last_name,
    };
    showLoader();
    api
      .post(withdraw_url, body)
      .then((response) => {
        if (response.status === 200) {
          alert_notify("success", response.data.Message);
          tempPageWidData.card.value = "";
          tempPageWidData.interac.value = "";
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
        console.log(err.response);
      });
  };

  // Check amount and disabled button if conditions arent set
  const checkWidAmountInput = (value, type) => {
    if (isNaN(value)) {
      alert_notify("error", "Value must be a number");
    } else {
      let tempPageWidData = { ...pageWidData };
      if (type == "interac") {
        if (
          Number(value) * exchange >=
            Number(withdrawSettings.withdrawal_interac_min_limit) &&
          Number(value) <= Number(props.userTotalbalance) &&
          Number(value) * exchange <=
            Number(withdrawSettings.withdrawal_interac_max_limit) &&
          !isNaN(value)
        ) {
          setValidWithdrawInterac(false);
        } else {
          setValidWithdrawInterac(true);
        }
        tempPageWidData.interac.value = value;
        tempPageWidData.card.value = "";
        checkExchange();
      } else {
        if (
          Number(value) >= Number(withdrawSettings.withdrawal_card_min_limit) &&
          Number(value) <= Number(props.userTotalbalance) &&
          Number(value) <= Number(withdrawSettings.withdrawal_card_max_limit) &&
          !isNaN(Number(value))
        ) {
          setValidWithdraw(false);
        } else {
          setValidWithdraw(true);
        }
        tempPageWidData.card.value = value;
        tempPageWidData.interac.value = "";
      }
      setPageWidData(tempPageWidData);
    }
  };

  // Exchange checking
  const checkExchange = () => {
    var url = `${sls_api_url}/v1/getExchange?convert=EURCAD`;
    showLoader();
    axios
      .get(url)
      .then((response) => {
        if (response.status == 200) {
          let exchange = Number(response.data.exchange);
          var tempPageWidData = { ...pageWidData };
          tempPageWidData.interac.converted_value = Number(
            tempPageWidData.interac.value * exchange
          ).toFixed(3);
          setPageWidData(tempPageWidData);
          setExchange(response.data.exchange);
          hideLoader();
        }
      })
      .catch((err) => {
        alert_notify("error", err.response.data.Message);
        hideLoader();
      });
  };

  const getWithdrawSettings = () => {
    var url = `/api/my_account/get_withdraw_settings`;
    showLoader();
    api
      .post(url)
      .then((response) => {
        setWithdrawSettings(response.data.Data);
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };
  useEffect(() => {
    getWithdrawSettings();
    checkExchange();
  }, []);
  return (
    <React.Fragment>
      <div className="withdraw_container">
        <div className="center_account" style={{ paddingTop: "0" }}>
          <div className="row mt-4">
            <label
              className="text-white"
              style={{ textTransform: "uppercase" }}
            >
              Withdraw with bank
            </label>
            <div className="col-sm-6 col-12">
              <input
                type="text"
                onChange={(e) => checkWidAmountInput(e.target.value, "topUp")}
                placeholder={
                  !props.labels.my_account_add_amount_eur
                    ? "Add amount EUR"
                    : props.labels.my_account_add_amount_eur.label_name
                }
                className="account_input"
                value={pageWidData.card.value}
                autoComplete="off"
              />
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <ButtonPrimary
                textValue={
                  !props.labels.my_account_withdraw_with_bank
                    ? "WITHDRAW WITH BANK"
                    : props.labels.my_account_withdraw_with_bank.label_name
                }
                classValue={`btn_primary btn_withdraw float-end ${
                  validWithdraw ? "disabled_button" : ""
                }`}
                onClick={withdrawAmountRequest}
                datatype="0"
              />
            </div>
          </div>

          {/*Check if user is from Canada, if yes show interac option */}
          {props.userData.master_country_id === "38" ? (
            <div className="row mt-5">
              <div className="col-12">
                <label className="text-white">
                  Withdraw with INTERAC® e-Transfer
                </label>
              </div>
              <div className="col-sm-6 col-12">
                <input
                  type="text"
                  onChange={(e) =>
                    checkWidAmountInput(e.target.value, "interac")
                  }
                  placeholder={
                    !props.labels.my_account_add_amount_eur
                      ? "Add amount EUR"
                      : props.labels.my_account_add_amount_eur.label_name
                  }
                  className="account_input"
                  value={pageWidData.interac.value}
                  autoComplete="off"
                />
              </div>

              <div className="col-sm-6 col-12 mt-sm-0 mt-3">
                <ButtonPrimary
                  textValue={
                    !props.labels.my_account_withdraw_with_interac
                      ? "Withdraw with INTERAC® e-Transfer"
                      : props.labels.my_account_withdraw_with_interac.label_name
                  }
                  classValue={`btn_primary btn_withdraw float-end btn_withdraw_height ${
                    validWithdrawInterac ? "disabled_button" : ""
                  }`}
                  onClick={withdrawAmountRequest}
                  datatype="3"
                />
              </div>
              <div className="col-12">
                {pageWidData.interac.value !== "" ? (
                  <span className="cooming_soon">
                    {Number(pageWidData.interac.value).toFixed(2)} EUR ={" "}
                    {pageWidData.interac.converted_value} CAD
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="col-12 withdraw_terms">
            <p className="text-white">
              {!props.labels.my_account_withdrawal_request
                ? "Your withdrawal request will be sent to admin, After approval of admin, balance will be deducted from your wallet."
                : props.labels.my_account_withdrawal_request.label_name}
            </p>
            <p className="text-white">
              *
              {!props.labels.my_account_cashout_process
                ? "We will have to verify your account information and location before Cash out process."
                : props.labels.my_account_cashout_process.label_name}
            </p>
            {props.userData.master_country_id === "38" ? (
              <p className="text-white">
                ® Trade-Mark Of Interac Corp. Used Under License
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
