import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { useNavigate, useParams } from "react-router-dom";
import "../private-competitions/PrivateCompetition.css";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import ProfileAccount from "../account/ProfileAccount";
import Parser from "html-react-parser";
import ApiContext from "../../providers/Api/ApiContext";
import { useForm } from "react-hook-form";

export default function CreatePrivateCompetition() {
  const { api, soccerApi } = useContext(ApiContext);
  const [loader, showLoader, hideLoader] = useLoading();
  const [sports, setSports] = useState([
    {
      sport_id: 1,
      sport_name: "Soccer",
    }])
  const [leagues, setLeagues] = useState([]);
  const [alert, alert_notify] = useAlert();
  const [successStatus, setSuccesStatus] = useState(false);
  const { sportId, leagueId } = useParams();
  const navigate = useNavigate();
  const [cpLabels, setCpLabels] = useState([]);
  const [cpStaticContent, setCpStaticContent] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      selectSportId: 0, 
      selectLeagueId: 0, 
      entryFee: 50,        
      entrants: 10,        
    },
  });
  const formValues = watch();
  const getCreatePrivateCompetitionStaticContent = () => {
    var content_url = "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "create_private_competiton" };
    showLoader();
    api
      .post(content_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var pageContent = data.content.page_content;
          if (pageContent != null && pageContent != undefined) {
            setCpStaticContent(pageContent);
          } else {
            setCpStaticContent(
              "Please select one option only from each of the following:"
            );
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };


  const submitCreatePrivateCompetition = (data) => {
    var create_private_competition_url = "/api/create_contest/request_contest";
      var body = {
        competition_title: "Private Contest",
        contest_type: "private",
        entry_fee: data.entryFee,
        league_id: data.selectLeagueId,
        num_of_entrants: data.entrants,
        payout_1st: "0",
        payout_2nd: "0",
        payout_3rd: "0",
        payout_club: "0",
        sport_id: data.selectSportId,
      };
      showLoader();
      api
        .post(create_private_competition_url, body)
        .then((response) => {
          if (response.status === 200) {
            setSuccesStatus(true);
            alert_notify(
              "success",
              "Private competition request has been made successfully."
            );
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
        });
  };

  const getCreatePrivateCompetitionsLabels = () => {
    var cp_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "create private competition",
    };
    showLoader();
    api
      .post(cp_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setCpLabels(data);
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
      });
  };

  useEffect(()=>{
    getCreatePrivateCompetitionStaticContent();
    getCreatePrivateCompetitionsLabels()
  },[])

  useEffect(() => {
    if(!(formValues.selectSportId !=0) ){
      setLeagues([])
      return
    }
      

   showLoader();
    soccerApi
      .get("/contest/get_active_contests")
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.data;
          data.sort((a, b) => (a.sport_name > b.sport_name ? 1 : -1));
          const leagues_array = Array.from(
            new Set(data.map((contest) => contest.league_id))
          ).map((league_id) => {
            return data.find((contest) => contest.league_id === league_id);
          });
          var sport_leagues = leagues_array.filter((row) => {
            return row.sport_id == formValues.selectSportId;
          });
          setLeagues(sport_leagues);
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        // navigate("/login");
      });
    setSuccesStatus(false);
  }, [formValues.selectSportId]);

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !cpLabels.create_private_competition_page_label
            ? "CREATE PRIVATE COMPETITION"
            : cpLabels.create_private_competition_page_label.label_name
        }
      />
      <div className="create_private_competition_container primary-background">
        <div className="page_content_center">
          {successStatus ? (
            <SuccesNotification
              title=""
              content="Top Predictor private competition was created successfully. Check your email for the shareable link."
            />
          ) : (
            <div>
              <div className="row m-0">
                <div className="col-12">
                  <span className="private_competition_select_options text-white">
                    {cpStaticContent != null && cpStaticContent != undefined
                      ? Parser(cpStaticContent)
                      : "Please select one option only from each of the following:"}
                  </span>
                </div>
              </div>
              <form
                onSubmit={handleSubmit(submitCreatePrivateCompetition)}
              >
                <div className="row private_competition_sport_league">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <select
                      className="private_competition_select"
                      {...register("selectSportId", {
                        validate: (value) =>
                          value != "0" || "Please select a sport",
                      })}
                    >
                      <option value="0">
                        {!cpLabels.create_private_competition_sport_label
                          ? "Sport"
                          : cpLabels.create_private_competition_sport_label
                              .label_name}
                      </option>
                      {sports.map((sport, index) => {
                          return (
                            <option
                              data-id={sport.sport_id}
                              key={index}
                              value={sport.sport_id}
                            >
                              {sport.sport_name}
                            </option>
                          );
                      })}
                    </select>
                    {errors.selectSportId && (
                        <span className="text-error-club">
                          {errors.selectSportId.message}
                        </span>
                      )}
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <select
                      className="private_competition_select"
                      {...register("selectLeagueId", {
                        validate: (value) =>
                          value != "0" || "Please select a league",
                      })}
                    >
                      <option value="0">
                        {!cpLabels.create_private_competition_league_label
                          ? "League"
                          : cpLabels.create_private_competition_league_label
                              .label_name}
                      </option>
                      {leagues.map((league, index) => {
                          return (
                            <option
                              key={index}
                              value={league.league_id}
                            >
                              {league.league_name}
                            </option>
                          );
                      })}
                    </select>
                    {errors.selectLeagueId && (
                        <span className="text-error-club">
                          {errors.selectLeagueId.message}
                        </span>
                      )}
                  </div>
                </div>
                <div className="row private_competition_entry_fee_entrants">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="number"
                      className="private_competition_select"
                      placeholder={
                        !cpLabels.create_private_competition_entryfee_label
                          ? "Entry Fee (EUR)"
                          : cpLabels.create_private_competition_entryfee_label
                              .label_name + " (EUR)"
                      }
                      data-type="entryfee"
                      min="0"
                      {...register("entryFee", {
                        required: "Entry fee is required",
                      })}
                    />
                         {errors.entryFee && (
                        <span className="text-error-club">
                          {errors.entryFee.message}
                        </span>
                      )}
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="number"
                      className="private_competition_select"
                      placeholder={
                        !cpLabels.create_private_competition_entrants_label
                          ? "Entrants"
                          : cpLabels.create_private_competition_entrants_label
                              .label_name
                      }
                      data-type="entrants"
                      min="2"
                      {...register("entrants", {
                        required: "Number of entries are required",
                      })}
                    />
                     {errors.entrants && (
                        <span className="text-error-club">
                          {errors.entrants.message}
                        </span>
                      )}
                  </div>
                </div>
                <div className="row m-0 mt-5">
                  <div className="col-12 create_private_competition_btn_container">
                    <ButtonPrimary
                      textValue={
                        !cpLabels.create_private_competition_create_label
                          ? "Create"
                          : cpLabels.create_private_competition_create_label
                              .label_name
                      }
                      classValue="btn_primary btn_create_private_competition"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
