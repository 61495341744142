import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonLight from "../buttons/buttonLight";
import "../safer-gambling/SaferGambling.css";
import { redirect } from "react-router-dom";
import Parser from "html-react-parser";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import moment from "moment";
import ApiContext from "../../providers/Api/ApiContext";

export default function DepositLimit(props) {
  const { api } = useContext(ApiContext);
  const [depositLimitStaticContent, setDepositLimitStaticContent] =
    useState("");
  const [monthlyDepositLimit, setMonthlyDepositLimit] = useState([]);
  const [depositDaily, setDepositDaily] = useState([]);
  const [depositMonthly, setDepositMonthly] = useState([]);
  const [depositWeekly, setDepositWeekly] = useState([]);
  const [loader, showLoader, hideLoader] = useLoading();
  var body, headers;
  const [alert, alert_notify] = useAlert();
  var dailyModifiedDate = "";
  var weeklyModifiedDate = "";
  var monthlyModifiedDate = "";
  var daily_hours_duration = "25";
  var monthly_hours_duration = "25";
  var weekly_hours_duration = "25";
  var daily_amount = 0;
  var weekly_amount = 0;
  var monthly_amount = 0;
  var daily_values = [5, 10, 30, 40, 50];
  var weekly_values = [5, 20, 50, 100, 150];
  var weekly_filtered_values = [5, 20, 50, 100, 150];
  var monthly_values = [10, 20, 50, 100, 150, 200, 250];
  var monthly_filtered_values = [10, 20, 50, 100, 150, 200, 250];

  const getStaticContent = () => {
    var static_content_url = "/api/static_content/get_all_static_content";
    if (props.type === "description") {
      body = {
        page_meta_key: "sg_deposit_limit",
      };
    } else {
      body = {
        page_meta_key: "sg_deposit_limit",
      };
    }
    showLoader();
    api
      .post(static_content_url, body)
      .then(function (response) {
        if (response.status === 200) {
          var content = response.data.Data.content.page_content;
          setDepositLimitStaticContent(content);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const getDepositLimitInfo = (type) => {
    var static_content_url = `/api/safer_gambling/get_deposit_limit`;

    var body = {
      amount: "",
      duration: 1,
      modified_date: "",
      unit: type,
    };
    showLoader();
    api
      .post(static_content_url, body)
      .then(function (response) {
        if (response.status === 200) {
          var data = response.data;
          if (data.Data.Deposit_limit_info.length > 0) {
            if (data.Data.Deposit_limit_info[0].unit === "DAYS") {
              setDepositDaily(data.Data.Deposit_limit_info[0]);
            }
            if (data.Data.Deposit_limit_info[0].unit === "MONTHS") {
              setDepositMonthly(data.Data.Deposit_limit_info[0]);
            }
            if (data.Data.Deposit_limit_info[0].unit === "WEEKS") {
              setDepositWeekly(data.Data.Deposit_limit_info[0]);
            }
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const setDepositLimit = (type, amount) => {
    var set_deposit_url = `/api/safer_gambling/set_deposit_limit`;
    body = {
      amount: amount,
      duration: 1,
      modified_date: "",
      unit: type,
    };
    showLoader();
    api
      .post(set_deposit_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          redirect("/submited/safer_gambling/deposit");
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  const submitDespositLimit = () => {
    var weekly_limit = document.getElementById(
      "deposit_limit_weekly_val"
    ).value;
    var daily_limit = document.getElementById("deposit_limit_daily_val").value;
    var monthly_limit = document.getElementById(
      "deposit_limit_monthly_val"
    ).value;

    if (daily_hours_duration > "24" && daily_limit !== "0") {
      setDepositLimit("DAYS", daily_limit);
    }
    if (weekly_hours_duration > "24" && weekly_limit !== "0") {
      setDepositLimit("WEEKS", weekly_limit);
    }
    if (monthly_hours_duration > "24" && monthly_limit !== "0") {
      setDepositLimit("MONTHS", monthly_limit);
    }
  };

  const resetDepositLimit = () => {
    var reset_desposit_url = "/api/safer_gambling/delete_deposit_limit";

    var body = {};
    showLoader();
    api
      .post(reset_desposit_url, body)
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirect("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  if (depositDaily.modified_date !== undefined) {
    dailyModifiedDate = depositDaily.modified_date;
    daily_hours_duration = moment().diff(moment(dailyModifiedDate), "hours");
    daily_amount = Number(depositDaily.amount);
  }

  if (depositWeekly.modified_date !== undefined) {
    weeklyModifiedDate = depositWeekly.modified_date;
    weekly_hours_duration = moment().diff(moment(weeklyModifiedDate), "hours");
    weekly_amount = Number(depositWeekly.amount);
  }

  if (depositMonthly.modified_date !== undefined) {
    monthlyModifiedDate = depositMonthly.modified_date;
    monthly_hours_duration = moment().diff(
      moment(monthlyModifiedDate),
      "hours"
    );
    monthly_amount = depositMonthly.amount;
  }

  weekly_filtered_values = weekly_values.filter((row) => row > daily_amount);
  monthly_filtered_values = monthly_values.filter(
    (row) => row > daily_amount + weekly_amount
  );

  const handleDepositLimitSelect = (e) => {
    var weekly_limit = document.getElementById(
      "deposit_limit_weekly_val"
    ).value;
    var daily_limit = document.getElementById("deposit_limit_daily_val").value;
    var type = e.target.getAttribute("data-type");
    var weekly_html = `<option value="0">Select Weekly Deposit Limit</option>`;
    var monthly_html = `<option value="0">Select Monthly Deposit Limit</option>`;

    if (type === "daily") {
      var weekly_result = weekly_filtered_values.filter(
        (row) => row > Number(daily_limit)
      );
      var monthly_result = monthly_filtered_values.filter(
        (row) => row > Number(daily_limit) + Number(weekly_limit)
      );

      weekly_result.map((row) => {
        return (weekly_html +=
          `<option value="` + row + `">&euro;` + row + `</option>`);
      });
      document.getElementById("deposit_limit_weekly_val").innerHTML =
        weekly_html;

      monthly_result.map((row) => {
        return (monthly_html +=
          `<option value="` + row + `">&euro;` + row + `</option>`);
      });
      document.getElementById("deposit_limit_monthly_val").innerHTML =
        monthly_html;
    }

    if (type === "weekly") {
      var weekly_result = weekly_filtered_values.filter(
        (row) => row > Number(daily_limit)
      );
      var monthly_result = monthly_filtered_values.filter(
        (row) => row > Number(daily_limit) + Number(weekly_limit)
      );

      monthly_result.map((row) => {
        return (monthly_html +=
          `<option value="` + row + `">&euro;` + row + `</option>`);
      });
      document.getElementById("deposit_limit_monthly_val").innerHTML =
        monthly_html;
    }
  };

  useEffect(() => {
    getStaticContent();
    if (props.type !== "description") {
      getDepositLimitInfo("MONTHS");
      getDepositLimitInfo("DAYS");
      getDepositLimitInfo("WEEKS");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="deposit_limit safer_gambling_container mt-3 primary-background">
        <div className="safer_title">{Parser(depositLimitStaticContent)}</div>
        {props.type === "gambling" ? (
          <div className="gambling_container">
            <div className="gambling_center">
              <div className="gambling_select mb-4 mt-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_daily_limit
                    ? "Daily Limit"
                    : props.labels.safer_gambling_daily_limit.label_name}
                </label>
                <select
                  id="deposit_limit_daily_val"
                  disabled={daily_hours_duration < 24 ? true : false}
                  onChange={handleDepositLimitSelect}
                  data-type="daily"
                >
                  <option value="0">Select Daily Deposit Limit</option>
                  {daily_values.map((row, index) => {
                    var selectedOption = "";
                    if (row == Number(depositDaily.amount)) {
                      selectedOption = true;
                    } else {
                      selectedOption = false;
                    }
                    return (
                      <option value={row} key={index} selected={selectedOption}>
                        &euro; {row}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="gambling_select mb-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_weekly_limit
                    ? "Weekly Limit"
                    : props.labels.safer_gambling_weekly_limit.label_name}
                </label>
                <select
                  id="deposit_limit_weekly_val"
                  disabled={weekly_hours_duration < 24 ? true : false}
                  onChange={handleDepositLimitSelect}
                  data-type="weekly"
                >
                  <option value="0">Select Weekly Deposit Limit</option>
                  {weekly_filtered_values.map((row, index) => {
                    var selectedOption = "";
                    if (row == Number(depositWeekly.amount)) {
                      selectedOption = true;
                    } else {
                      selectedOption = false;
                    }
                    return (
                      <option value={row} key={index} selected={selectedOption}>
                        &euro; {row}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="gambling_select mb-4">
                <label className="text-white">
                  {!props.labels.safer_gambling_monthly_limit
                    ? "Monthly Limit"
                    : props.labels.safer_gambling_monthly_limit.label_name}
                </label>
                <select
                  id="deposit_limit_monthly_val"
                  disabled={monthly_hours_duration < 24 ? true : false}
                  onChange={handleDepositLimitSelect}
                  data-type="monthly"
                >
                  <option value="0">Select Monthly Deposit Limit</option>
                  {monthly_filtered_values.map((row, index) => {
                    var selectedOption = "";
                    if (row == Number(depositMonthly.amount)) {
                      selectedOption = true;
                    } else {
                      selectedOption = false;
                    }
                    return (
                      <option value={row} key={index} selected={selectedOption}>
                        &euro; {row}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="gambling_btns">
                <ButtonLight
                  classValue="btn_light btn_reset_deposit_limit"
                  textValue={
                    !props.labels.safer_gambling_reset
                      ? "Reset"
                      : props.labels.safer_gambling_reset.label_name
                  }
                  onClick={resetDepositLimit}
                />
                <ButtonPrimary
                  classValue="btn_primary btn_submit_deposit_limit"
                  textValue={
                    !props.labels.safer_submit
                      ? "Submit"
                      : props.labels.safer_submit.label_name
                  }
                  onClick={submitDespositLimit}
                />
              </div>
              <div></div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {loader}
      {alert}
    </React.Fragment>
  );
}
