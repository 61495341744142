import React, { useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import "../profile/Profile.css";
import { updatePassword } from "@aws-amplify/auth";

export default function ChangePassword(props) {
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [validPassword, setValidPassowrd] = useState(false);
  const [validNewPassword, setValidNewPassowrd] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [alert, alert_notify] = useAlert();
  const [loader, showLoader, hideLoader] = useLoading();

  const getPasswordChangeData = () => {
    if (password.old_password !== "") {
      setValidPassowrd(true);
    } else {
      setValidPassowrd(false);
    }
    if (password.new_password !== "") {
      setValidNewPassowrd(true);
    } else {
      setValidNewPassowrd(false);
    }
    if (password.confirm_password !== "") {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
  };

  const submitPasswordChange = async (e) => {
    e.preventDefault();
    if (validPassword && validNewPassword && validConfirmPassword) {
      if (password.new_password === password.confirm_password) {
        showLoader();
        try {
          await updatePassword({
            oldPassword: password.old_password,
            newPassword: password.new_password,
          });
          alert_notify("success", "Password updated successfully.");
          setPassword({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
          hideLoader();
        } catch (err) {
          console.log(err);
          if (Object.values(err)[0] == "NotAuthorizedException") {
            alert_notify("error", "Current password is incorrect.");
          }
          if (Object.values(err)[0] == "LimitExceededException") {
            alert_notify(
              "error",
              "Password reset attempt limit exceeded, please try after some time."
            );
          }
          hideLoader();
        }
      } else {
        alert_notify(
          "error",
          "New password must be same with confirm password."
        );
      }
    } else {
      alert_notify("error", "Please type required data.");
    }
  };
  const handlePasswordChange = (key, e) => {
    setPassword({
      ...password,
      [key]: e.target.value,
    });
  };
  return (
    <React.Fragment>
      <div className="change_password_container primary-background">
        <div className="center_container">
          <div className="chnage_password_center">
            <form id="form_change_password" onSubmit={submitPasswordChange}>
              <label
                className="lbl_change_password text-white"
                style={{ textTransform: "uppercase" }}
              >
                {!props.labels.update_form_cp_page_title
                  ? "CHANGE PASSWORD"
                  : props.labels.update_form_cp_page_title.label_name}
              </label>
              <div className="row mt-row">
                <div className="col-12">
                  <input
                    type="password"
                    className="password_input"
                    id="input_change_current_password"
                    value={password.old_password}
                    onChange={(e) => handlePasswordChange("old_password", e)}
                    placeholder={
                      !props.labels.update_form_cp_curr_pwd_label
                        ? "Current Password"
                        : props.labels.update_form_cp_curr_pwd_label.label_name
                    }
                  />
                </div>
              </div>
              <div className="row mt-row">
                <div className="col-12">
                  <input
                    type="password"
                    className="password_input"
                    id="input_change_new_password"
                    value={password.new_password}
                    onChange={(e) => handlePasswordChange("new_password", e)}
                    placeholder={
                      !props.labels.update_form_cp_new_pwd_label
                        ? "New Password"
                        : props.labels.update_form_cp_new_pwd_label.label_name
                    }
                  />
                </div>
              </div>
              <div className="row mt-row">
                <div className="col-12">
                  <input
                    type="password"
                    className="password_input"
                    id="input_change_confirm_password"
                    value={password.confirm_password}
                    onChange={(e) =>
                      handlePasswordChange("confirm_password", e)
                    }
                    placeholder={
                      !props.labels.update_form_cp_conf_pwd_label
                        ? "Confirm Password"
                        : props.labels.update_form_cp_conf_pwd_label.label_name
                    }
                  />
                </div>
              </div>
              <div className="row mt-row">
                <div className="col-12 update_password_btn_container">
                  <ButtonPrimary
                    textValue={
                      !props.labels.update_form_cp_submit_btn
                        ? "Update Password"
                        : props.labels.update_form_cp_submit_btn.label_name
                    }
                    classValue="btn_primary btn_update_password"
                    form="form_change_password"
                    type="submit"
                    onClick={getPasswordChangeData}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {alert}
      {loader}
    </React.Fragment>
  );
}
