import React from "react";

const Summary = ({ className = "" }) => {
  return (
    <div className={`page_content_center ${className}`}>
      <div
        className="p-2 w-full d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#ffb500" }}
      >
        <span className="fs-4 fw-bold">
          Predict Results - Score Points - Win $
        </span>
      </div>
    </div>
  );
};

export default Summary;
