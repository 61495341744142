import React, {useState, useEffect} from 'react'
import '../loading/Loading.css'


export default function Loading(){
    return (
        <div className="loading-container">
            <span className="loader"><img src="/assets/icons/loading.svg" /></span>
        </div>
    )
}