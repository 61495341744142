import React, { useEffect, useState } from 'react'

function useHandlePadding({paddingTop,adjustPaddingForSmallDevices}) {

    const [dynamicPaddingTop , setDynamicPaddingTop] = useState(paddingTop)
    useEffect(() =>{
        const handleResize = () => {
          if (window.innerWidth < 576) {
            if (adjustPaddingForSmallDevices) {
              setDynamicPaddingTop(paddingTop * 2);
            }
          } else{
            setDynamicPaddingTop(paddingTop)
          }
        };
        handleResize()
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      },[])

    return {dynamicPaddingTop}
}

export default useHandlePadding