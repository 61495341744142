import React from "react";
import HTMLReactParser from "html-react-parser";

const WizardInfo = React.forwardRef((props, ref) => {
  var { titleText, bodyText } = props;
  return (
    <>
      <div className="gold_wizard_info">
        <div className="golf_select_info text-white">
          {" "}
          {HTMLReactParser(titleText)}{" "}
        </div>
        <div className="golf_info text-white">{HTMLReactParser(bodyText)}</div>
      </div>
    </>
  );
});
export default WizardInfo;
