import React, {useEffect} from 'react'
import useLoading from "./UseLoading";

export default function Loading() {
    const [loader, showLoader, hideLoader] = useLoading();
    useEffect(() => {
       showLoader()
    }, [])
    return (
        <div>
            {loader}
        </div>
    )
}
