import React from "react";
import "./wildCard.css";
function WildCard() {
  return (
    <div className="wildcard-container">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-center py-4 gap-5">
        <div className="d-flex justify-content-center">
          <img src="assets/image/home/wildcard1.png" className="phone-image"/>
        </div>
        <div className="d-flex flex-column text-white gap-2">
          <h2 className="w-100 w-md-75 text-center text-md-start font-weight-bold wildcard-title">
            Boost Your Points with Wild Cards
          </h2>
          <p className="w-100 w-md-75 text-center text-md-start">
            Use Wild Cards strategically to multiply your points and gain an
            edge over the other players.
          </p>
        </div>
      </div>
    </div>
  );
}

export default WildCard;
