import React, { useState } from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({ expiryTimestamp }) => {
  const [isLive, setIsLive] = useState(false);
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsLive(true),
  });

  // Format the remaining time
  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  // Calculate the days, hours, minutes, and seconds left
  const daysLeft = Math.floor(totalSeconds / (3600 * 24));
  // const hoursLeft = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  // const minutesLeft = Math.floor((totalSeconds % 3600) / 60);
  // const secondsLeft = Math.floor(totalSeconds % 60);

  return (
    <div>
      {days <= 0 ? (
        <>
          <span>{formatTime(hours)}</span>:
          <span>{formatTime(minutes)}</span>:
          <span>{formatTime(seconds)}</span>
        </>
      ) : (
        <>
          <span>{daysLeft} d left</span>
        </>
      )}
    </div>
  );
};

export default Timer;

