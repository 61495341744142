import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useComingSoonModal from "../../hooks/useComingSoonModal";
import ApiContext from "../../providers/Api/ApiContext";
import useHandlePadding from "../../hooks/useHandlePadding";
import PlayHere from "./PlayHere";

const SportsBanner = ({ activeSport = null ,paddingTop, adjustPaddingForSmallDevices = false, showPlayHere = false}) => {
  const { api } = useContext(ApiContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { openModal, Modal } = useComingSoonModal();
  const [sportsBanner, setSportsBanner] = useState([]);
  const {dynamicPaddingTop} = useHandlePadding({paddingTop,adjustPaddingForSmallDevices})

  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    const { data } = await api.get(
      `/api/second_landing_page/get_sports_banner`
    );
    let sportsRes = data?.data?.sportsBanner.filter(item => item.link.split("/").length < 3 || item.link.split("/").length === 3 );
    setSportsBanner(sportsRes);
  };
  useEffect(() => {}, [sportsBanner]);
  return (
    <div className="primary-background" style={{paddingTop: `${dynamicPaddingTop}px`}}>
    { showPlayHere && <PlayHere />}
      <div class=" d-flex justify-content-center m-auto text-center ">
        <div class="row">
          {sportsBanner?.map((sport, index) => {
            return (
              <div
                class="col d-flex justify-content-center align-items-center"
                style={{
                  borderRight:
                    index !== sportsBanner.length - 1
                      ? "1px solid #9a9a9a"
                      : "",
                }}
              >
                <span
                  className={`display_name_text ${
                    activeSport === sport.sport_id ? "display_name_active" : ""
                  }`}
                  onClick={(e) => {
                    if (sport.sport_id === 0) {
                      openModal();
                    } else {
                      if (sport?.link?.length > 0) {
                        navigate(sport.link);
                      } else {
                        navigate(`/sports_leagues/${sport.sport_id}`, {
                          state: {
                            sport_name:
                              sport?.link?.length > 0
                                ? sport.sport_name
                                : sport?.name,
                            id: sport.sport_id,
                          },
                        });
                      }
                    }
                  }}
                >
                  {sport?.name}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <Modal />
    </div>
  );
};

export default SportsBanner;
