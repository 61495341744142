import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ButtonSecondary from "../buttons/ButtonSecondary";
import LoginHeader from "../login/LoginHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../sports-leagues/SportsLeagues.css";
import Challenge from "../sports-leagues/sports/Challenge";

export default function SportsLeagues(props) {
  const [sportsValue, setSportValue] = useState("challenge");
  const [loggedStatus, setLoggedStatus] = useState("");
  const { search } = useLocation();
  const navigate = useNavigate();
  const { sportId, leagueId } = useParams();
  var sport = "";

  const match = search.match(/type=(.*)/);
  const type = match?.[1];

  const goToThisPage = (e) => {
    var path = e.currentTarget.getAttribute("datapath");
    navigate("/" + path + "/" + sportId + "/" + leagueId + "");
  };

  const changeSportsType = (e) => {
    var sportType = e.target.getAttribute("datatype");
    setSportValue(sportType);
  };

  useEffect(() => {
    var logged_status = localStorage.getItem("user_status");
    if (logged_status === "unlogged") {
      setLoggedStatus("unlogged");
    } else {
      setLoggedStatus("logged");
    }
  }, []);

  if (sportId == 1) {
    sport = "FOOTBALL COMPETITIONS";
  } else if (sportId == 3) {
    sport = "AMERICAN FOOTBALL COMPETITIONS";
  } else if (sportId == 16) {
    sport = "ICE HOCKEY COMPETITIONS";
  } else if (sportId == 3) {
    sport = "AMERICAN FOOTBALL COMPETITIONS";
  } else if (sportId == 2) {
    sport = "CRICKET COMPETITIONS";
  } else if (sportId == 18) {
    sport = "RUGBY LEAGUE";
  } else if (sportId == 11) {
    sport = "ESPORT COMPETITIONS";
  }

  return (
    <React.Fragment>
      {loggedStatus === "logged" ? <Header /> : <LoginHeader />}
      <div className="sports_league">
        <div className="my_sports_league_center">
          <label className="lbl_sports_league">{sport}</label>
        </div>
      </div>
      <div className="sports_league_container primary-background">
        <div className="page_content_center">
          <h5 className="sports_league_select_competition text-white">
            Select your competition, entry fee and the number of players you
            wish to compete against, then press play
          </h5>
          {/* <h5 className="sports_league_header text-white">
            * You are limited to free-to-play competitions only until we have
            received your KYC documents.
          </h5> */}
          <div className="sports_league_btns">
            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  sportsValue === "challenge"
                    ? "btn_safer_gambling d-none btn_secondary active"
                    : "btn_safer_gambling d-none btn_secondary"
                }
                textValue="Challenge"
                datatype="challenge"
                onClick={changeSportsType}
              />
            </div>

            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  sportsValue === "create-club-fundraiser"
                    ? "btn_safer_gambling btn_secondary active"
                    : "btn_safer_gambling btn_secondary"
                }
                textValue="CREATE CLUB FUNDRAISER"
                onClick={goToThisPage}
                datapath="club_fundraiser"
              />
            </div>

            <div className="d-inline-flex justify-content-center btn_container">
              <ButtonSecondary
                classValue={
                  sportsValue === "create-private-competition"
                    ? "btn_safer_gambling btn_secondary active"
                    : "btn_safer_gambling btn_secondary"
                }
                textValue="CREATE PRIVATE COMPETITION"
                onClick={goToThisPage}
                data-sportid={sportId}
                data-leagueid={leagueId}
                datapath="private_competition"
              />
            </div>
          </div>

          {sportsValue === "challenge" ? <Challenge /> : ""}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
