import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default function MyCompetition() {
  return (
    <React.Fragment>
      <Header />
      <div className="my_profile">
        <div className="my_profile_center">
          <label className="lbl_my_profile">CREATE PRIVATE COMPETITION</label>
        </div>
      </div>
      <div className="my_private_competition_container">
        <div className="page_content_center">
          <div className="row my_competition_info">
            <div className="col"></div>
            <div className="col-12 col-lg-2 col-xl-2">Sport</div>
            <div className="col-12 col-lg-2 col-xl-2">League</div>
            <div className="col-12 col-lg-1 col-xl-1">Entry Fee</div>
            <div className="col-12 col-lg-1 col-xl-1">Entries</div>
            <div className="col">Position</div>
            <div className="col-12 col-lg-2 col-xl-2">End Date</div>
            <div className="col"></div>
          </div>
          <div className="row my_competition_data">
            <div className="col-12 col-lg-2 col-xl-2">
              <span className="private_competition_i">
                <img src="assets/icons/private.svg" alt="private-icon" />
              </span>
              <span className="private_competition_value">8MRJVWIBN</span>
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <span className="private_competition_title">Sport</span>
              <span className="private_competition_value">Football</span>
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <span className="private_competition_title">League</span>
              <span className="private_competition_value">
                England Premiership League
              </span>
            </div>
            <div className="col-12 col-lg-1 col-xl-1">
              <span className="private_competition_title">Entry fee</span>
              <span className="private_competition_value">Free to Play</span>
            </div>
            <div className="col-12 col-lg-1 col-xl-1">
              <span className="private_competition_title">Entries</span>
              <span className="private_competition_value">1/4</span>
            </div>
            <div className="col">
              <span className="private_competition_title">Position</span>
              <span className="private_competition_value">1st</span>
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <span className="private_competition_title">End Date</span>
              <span className="private_competition_value">
                15 Sept 2021 | 15:30
              </span>
            </div>
            <div className="col private_competition_icons">
              <span className="private_competition_gamecard_i">
                <img src="assets/icons/gamecard.svg" alt="gamecard-icon" />
              </span>
              <span className="private_competition_leaderboard_i">
                <img
                  src="assets/icons/leaderboard.svg"
                  alt="leaderboard-icon"
                />
              </span>
            </div>

            <div className="col-12 private_competition_icons share_copy mt-0">
              <span className="copy_competition">
                <img src="assets/icons/copy.svg" alt="copy-icon" />
                <span>Copy link</span>
              </span>
              <span className="share_competition">
                <img src="assets/icons/share.svg" alt="share-icon" />
                <span>Share</span>
              </span>
            </div>
          </div>
          <div className="row mt-4 private_copy_share_row">
            <div className="col-12 d-flex justify-content-end">
              <span className="copy_competition">
                <img src="assets/icons/copy.svg" alt="copy-icon" />
                <span>Copy link</span>
              </span>
              <span className="share_competition">
                <img src="assets/icons/share.svg" alt="share-icon" />
                <span>Share</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
