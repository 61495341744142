import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import axios from "axios";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useNavigate } from "react-router-dom";

export default function Sumsub() {
  const navigate = useNavigate();
  const [sumsubToken, setSumsubToken] = useState("");
  const [showSumsub, setShowSumsub] = useState(false);
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  var sls_api_url = process.env.REACT_APP_SLS_API_URL;
  const changeKycStatus = () => {
    var session_key = localStorage.getItem("session_key");
    var url =
      `${sls_api_url}/v1/user_kyc_get_acess_token?sessionKey=` + session_key;
    const body = {};
    showLoader();
    axios
      .get(url, body)
      .then((response) => {
        if (response.status == 200) {
          var data = response.data;
          setSumsubToken(data.token);
          setShowSumsub(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };
  useEffect(() => {
    changeKycStatus();
  }, []);
  return (
    <React.Fragment>
      <Header />
      <div
        className="sumsub_container"
        style={{
          minHeight: "600px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showSumsub ? (
          <SumsubWebSdk
            accessToken={sumsubToken}
            expirationHandler={() => {}}
            config={{ lang: "en" }}
            options={{ addViewportTag: false, adaptIframeHeight: true }}
            onMessage={() => {}}
            onError={() => {
              setSumsubToken("");
              setShowSumsub(false);
            }}
            onReady={(data) => console.log(data)}
          />
        ) : (
          ""
        )}
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
