import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ProfileAccount from "../account/ProfileAccount";
import ApiContext from "../../providers/Api/ApiContext";
import AccountHeader from "../account-header/AccountHeader";
import { useForm } from "react-hook-form";
import ButtonPrimary from "../buttons/ButtonPrimary";
import UserContext from "../../providers/User/UserContext";
import { useNavigate } from "react-router-dom";
import UseLoading from "../../hooks/UseLoading";
import UseAlert from "../../hooks/UseAlert";

export default function VerifyPhoneNumber() {
  const { api } = useContext(ApiContext);
  const { user, isLoading, getUser } = useContext(UserContext);
  const [alert, alert_notify] = UseAlert();
  const [loader, showLoader, hideLoader] = UseLoading();
  const navigate = useNavigate();
  const [isOTPSend, setIsOTPSend] = useState(false);
  const phone_regex = /^\+1\d{10}$/;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (user?.phone_no === data?.phone_no) {
      alert_notify("error", "You cannot use same phone number");
      return;
    }
    if (!isOTPSend) {
      try {
        showLoader();
        const res = await api.post(
          "/api/my_profile/send_verification_otp",
          data
        );
        console.log(res);
        if (res.status == 200) {
          setIsOTPSend(true);
          alert_notify("success", "OTP send on your mobile number");
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.Error) {
          alert_notify(
            "error",
            Object.values(error?.response?.data?.Error).join("\n")
          );
        } else {
          alert_notify(
            "error",
            "Something went wrong please try again after sometime"
          );
        }
      } finally {
        hideLoader();
      }
    } else if (isOTPSend) {
      try {
        showLoader();
        const res = await api.post("/api/my_profile/verify_otp", data);
        console.log(res);
        if (res.status == 200) {
          await getUser();
          alert_notify("success", "Phone number verified successfully.");
          setTimeout(() => {
            navigate("/account");
          }, 3000);
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.Error) {
          alert_notify(
            "error",
            Object.values(error?.response?.data?.Error).join("\n")
          );
        } else {
          alert_notify(
            "error",
            "Something went wrong please try again after sometime"
          );
        }
      } finally {
        hideLoader();
      }
    }
  };
  useEffect(() => {
    if (!isLoading) {
      if (user?.master_country_id != "38") {
        navigate("/account");
      }
    }
  }, [isLoading, user]);
  return (
    <React.Fragment>
      <Header />
      <ProfileAccount headerValue={"Verify Phone Number"} />
      <AccountHeader
        headerValue={`Verify your Phone Number`}
        firstTextValue="To deposit and withdraw money using interac, You must verify your canadian phone number."
        secondTextValue="An OTP will be sent to your phone number for verification"
      />
      <div className="profile_container primary-background">
        <div className="page_content_center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row set_mobile_container d-flex">
              <div className="col-12 mt-2 d-flex justify-content-center align-items-center">
                <div className="pt-3 p-2 d-flex justify-content-center align-items-center ">
                  <h1>&#127464;&#127462; </h1>
                </div>
                <input
                  type="text"
                  className="forgot_password_email"
                  disabled={isOTPSend}
                  style={isOTPSend ? { color: "white" } : {}}
                  placeholder="Phone Number"
                  {...register("phone_no", {
                    required: true,
                    pattern: {
                      value: phone_regex,
                      message:
                        "Phone number must be of 10 digits without any special character and always start with +1",
                    },
                    value: `+1${
                      getValues("phone_no") ? getValues("phone_no") : ""
                    }`,
                  })}
                />
              </div>
              {errors?.phone_no?.type === "required" && (
                <span className="text-end validation-error-msg">
                  *Phone Number is required
                </span>
              )}
              {errors.phone_no?.type === "pattern" && (
                <span className="text-end validation-error-msg">
                  {errors.phone_no?.message}
                </span>
              )}
              {isOTPSend ? (
                <>
                  <div className="col-12 mt-2 d-flex justify-content-center align-items-center">
                    <div className="p-2 d-flex justify-content-center align-items-center ">
                      <h1 class="invisible">&#127464;&#127462; </h1>
                    </div>
                    <input
                      type="number"
                      className="forgot_password_email"
                      disabled={!isOTPSend}
                      maxLength={6}
                      placeholder="OTP"
                      {...register("otp", {
                        required: true,
                        maxLength: 6,
                        minLength: 6,
                      })}
                    />
                  </div>
                  {errors.otp?.type === "required" && (
                    <span className="text-end validation-error-msg">
                      OTP is required
                    </span>
                  )}
                  {errors.otp?.type === "maxLength" ||
                    (errors.otp?.type === "minLength" && (
                      <span className="text-end validation-error-msg">
                        Invalid OTP
                      </span>
                    ))}
                </>
              ) : null}
              <div className="col-12 mt-4 d-flex justify-content-end">
                <ButtonPrimary
                  type="submit"
                  classValue="btn_set_phone btn_account_action btn_primary"
                  textValue={!isOTPSend ? "sent OTP code" : "Verify OTP"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {loader}
      <Footer />
    </React.Fragment>
  );
}
