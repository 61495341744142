import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { howItWorks } from "../../data";
import "./howItWorks.css";

function HowItWorks({title = "How It Works?"}) {
  const [activeKey, setActiveKey] = useState("0");

  const handleSelect = (eventKey) => {
   if(eventKey !== null)
    setActiveKey(eventKey);
   else setActiveKey(0)
  };

  return (
    <>
      <h3 className="text-white text-center my-4">{title}</h3>
      <div className="container d-flex flex-column flex-md-row  justify-content-between align-items-center">
        <Accordion
          defaultActiveKey="0"
          activeKey={activeKey}
          onSelect={handleSelect}
          style={{width:"60%"}}
        >
          {howItWorks.map((item, index) => {
            return (
                <>
              <Accordion.Item
                eventKey={index}
                style={{ backgroundColor: "transparent", color: "white" }}
              >
                <Accordion.Header>{index + 1}. {item.title} <i class={`bi bi-caret-${activeKey == index ? "up" : "down"}-fill`}></i></Accordion.Header>
                <Accordion.Body>{item.desc}</Accordion.Body>
              </Accordion.Item>
              <hr style={{ color: "white" }}/>
              </>
            );
          })}
        </Accordion>
        <div>
          <img
            src={howItWorks[activeKey].imgPath}
            alt={howItWorks[activeKey].title}
            // style={{
            //   height: "100%",
            //   width: "100%",
            //   objectFit: "contain",
            //   display: "block",
            // }}
            className="phone-image"
          />
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
