const awsconfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      signUpVerificationMethod:
        process.env.REACT_APP_SIGN_UP_VERIFICATION_METHOD,
    },
  },
};

export default awsconfig;
