import React from "react";
import moment from "moment";

const SelectAnchor = React.forwardRef((props, ref) => {
  const {
    playerType,
    setPlayer,
    classValue,
    playersData,
    player,
    contestUid,
    setTournamentPlayer,
    textValue,
    allCustomPlayers,
    contestDetails,
    currentRound,
    disabled = false,
  } = props;
  var result = Object.keys(player).map((key) => {
    return player[key]["player_feed_id"];
  });
  return (
    <div className={classValue}>
      <select
        disabled={disabled}
        className="form-select select_anchor"
        value={
          player[playerType]?.player_feed_id
            ? player[playerType]?.player_feed_id
            : 0
        }
        onChange={(event) => {
          var data = {
            ...player,
            [playerType]: {
              player_feed_id: event.target.value,
              contest_uid: contestUid,
              player_type: playerType,
            },
          };
          setPlayer(data);
        }}
      >
        <option value="0" className="text-center">
          {textValue}
        </option>
        {allCustomPlayers.map((row, i) => {
          if (currentRound - 1 == 0) {
            var teeTimePerRound = "tee_time_round_one";
          } else if (currentRound - 1 == 1) {
            var teeTimePerRound = "tee_time_round_two";
          } else if (currentRound - 1 == 2) {
            var teeTimePerRound = "tee_time_round_three";
          } else if (currentRound - 1 == 3) {
            var teeTimePerRound = "tee_time_round_four";
          } else {
            var teeTimePerRound = "tee_time_round_four";
          }
          var disabled = false;
          if (row[teeTimePerRound] == null || row[teeTimePerRound] == "") {
            disabled = false;
          } else if (row[teeTimePerRound].indexOf(":") > -1) {
            var round_date = moment(
              contestDetails?.start_at,
              "YYYY-MM-DD HH:mm"
            )
              .add(currentRound, "days")
              .format("YYYY-MM-DD");
            var localTime = moment
              .utc(
                round_date + " " + row[teeTimePerRound],
                "YYYY-MM-DD hh:mm A"
              )
              .local();
            var currentTimeUnix = moment().utc().local().unix();
            if (currentTimeUnix > localTime.unix()) {
              disabled = true;
            }
          } else if (row[teeTimePerRound] == "WD") {
            disabled = false;
          } else {
            disabled = true;
          }

          var name =
            row.cuted == 1 ? row.player_name + " (Cut) " : row.player_name;
          if (row.cuted == 1) {
            var name = row.player_name + " (Cut) ";
          } else if (disabled) {
            var name = row.player_name + " (Tee'd Off) ";
          } else {
            var name = row.player_name;
          }
          return (
            <option
              value={row.player_feed_id}
              disabled={result.includes(row.player_feed_id)}
              key={i}
            >
              {name}
            </option>
          );
        })}
      </select>
    </div>
  );
});
export default SelectAnchor;
