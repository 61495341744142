import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import ProfileAccount from "./ProfileAccount";
import Footer from "../footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [seconds, setSeconds] = useState(10);
  const [status, setStatus] = useState(queryParams.get("status") === "success");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          navigate("/account");
          clearInterval(intervalId);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <React.Fragment>
      <Header />
      <div className="account_container primary-background">
        <div className="center_account text-center h-50">
          <br />
          <br />
          {status ? (
            <h1 className={"display_name_active"}>Payment Successful</h1>
          ) : (
            <h1 className={"text-danger"}>Payment Failed</h1>
          )}
          <h4 className="text-white fs-5">
            Redirecting on accounts screen in {seconds} seconds
          </h4>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PaymentStatus;
