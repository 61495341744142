import React from 'react'

export default function SuccesNotification(props) {
    return (
        <React.Fragment>
            <div className="success_notification">
                <div className="success_message">
                    <div className="d-flex align-items-center">
                        <span className="check_icon_container">
                            <img src="/assets/icons/check.svg" alt="check-icon"/>
                        </span>
                        <div className="message">
                            <span className="message_title">{props.title}</span>
                            <span className="message_content">
                                {props.content}
                            </span>                            
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}