import React, { useState, useEffect, useContext } from "react";
import "../login/Login.css";
import AccountHeader from "../account-header/AccountHeader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../footer/Footer";
import LoginHeader from "../login/LoginHeader";
import ButtonPrimary from "../buttons/ButtonPrimary";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import { Helmet } from "react-helmet";
import SuccesNotification from "../../hooks/SuccesNotification";
import { signIn, resendSignUpCode } from "aws-amplify/auth";
import ApiContext from "../../providers/Api/ApiContext";
import UserContext from "../../providers/User/UserContext";

export default function Login() {
  var cachedUsername = localStorage.getItem("cached_username");
  const { api } = useContext(ApiContext);
  const { getUser } = useContext(UserContext);
  // const [loginStatus, setLoginStatus] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [validUserName, setValidUserName] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const navigate = useNavigate();
  const [loginLabels, setLoginLabels] = useState([]);
  const [loader, showLoader, hideLoader] = useLoading();
  var loggedUserData = JSON.parse(localStorage.getItem("userData"));
  const { search } = useLocation();
  const match = search.match(/token=(.*)/);
  const token = match?.[1];
  var loginClassVal = "";
  const [alert, alert_notify] = useAlert();

  const getLoginInputData = () => {
    var email = document.getElementById("input_username").value;
    var password = document.getElementById("input_password").value;

    if (email === "") {
      document
        .getElementById("input_username_required")
        .classList.remove("d-none");
      setValidUserName(false);
    } else {
      document
        .getElementById("input_username_required")
        .classList.add("d-none");
      setValidUserName(true);
    }

    if (password === "") {
      document
        .getElementById("input_password_required")
        .classList.remove("d-none");
      setValidPassword(false);
    } else {
      document
        .getElementById("input_password_required")
        .classList.add("d-none");
      setValidPassword(true);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    // if (isAuthenticated) {
    //   setIsAuthenticated(false);
    // }
    
    let tempPredictions = JSON.parse(localStorage.getItem("tempPredictions"))
    localStorage.clear();
    var email = document.getElementById("input_username").value;
    var password = document.getElementById("input_password").value;

    if (!email.includes("@")) {
      localStorage.setItem("cached_username", email.trim());
    }
    if (validUserName && validPassword) {
      showLoader();
      try {
        const res = await signIn({
          username: email,
          password: password,
          options: {
            clientMetadata: {
              username: email,
            },
          },
        });
        if (res.isSignedIn == false) {
          if (res.nextStep.signInStep === "CONFIRM_SIGN_UP") {
            await resendSignUpCode({
              username: email,
            });
            alert_notify(
              "error",
              "Please verify your email using a link send on your email"
            );
          }
        } else if (
          res.isSignedIn == true &&
          res.nextStep.signInStep == "DONE"
        ) {
          //setIsAuthenticated(true);
          //await configAxios();
          if(tempPredictions){
            //NOTE: As of now we are saving predictions for soccer game card only that's why there will be no saved data for other sport game cards so that no need to check for the sport. 
            localStorage.setItem("tempPredictions",JSON.stringify(tempPredictions))
            await getUser()

            navigate(`/soccer/gamecard/1/${tempPredictions.contest_uid}?savePredictions=true`)
    
          }else{
            navigate("/home");
          }
          localStorage.setItem("user_status", "logged");
        }
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        if (err?.message.includes("Incorrect username or password")) {
          alert_notify("error", "Invalid username or password");
        } else if (err?.message.includes("PasswordExpired")) {
          navigate("/forgot_password", { state: { type: "Reset" } });
        } else if (err?.message.includes("EmailVerificationLinkSendOnEmail")) {
          alert_notify(
            "error",
            "Please verify your email using a link send on your email."
          );
        }
      }
    }
  };

  const getLoginLabels = () => {
    var login_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "login form",
    };
    api
      .post(login_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setLoginLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loginClassVal = `btn_login_user btn_primary btn_account_action`;
  const showPasswordStatus = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    getLoginLabels();
    localStorage.setItem("user_status", "unlogged");

    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    // if (loggedUserData) {
    //   setTimeout(() => navigate("/home"), 3000);
    // }
  }, [loggedUserData]);
  useEffect(() => {
    if (token != undefined) {
      api
        .get(`/api/auth/activate_account/${token}`)
        .then((response) => {
          setShowSuccessAlert(true);
          hideLoader();
        })
        .catch((error) => {
          alert_notify("error", error.response.data.Message);
          hideLoader();
        });
    }
  }, [token]);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Predictor | Login</title>
        <meta
          name="description"
          content="Login to the Top Predictor, sports newest and most interactive prediction game."
        />
      </Helmet>
      <LoginHeader loginValue={true} />
      <AccountHeader
        headerValue={
          !loginLabels.login_form_login
            ? "LOGIN"
            : loginLabels.login_form_login.label_name
        }
        firstTextValue="Login with your TOP PREDICTOR account to participate in the NEWEST & MOST INTERACTIVE PREDICTION GAME"
        secondTextValue=""
      />
      <div className="login_container primary-background">
        <div className="page_content_center">
          {showSuccessAlert ? (
            <SuccesNotification
              title="Your account is now active please login!"
              content={""}
            />
          ) : (
            ""
          )}
          <form id="login_form" onSubmit={handleLoginSubmit}>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5 col-xl-5 mt-4">
                <input
                  type="text"
                  placeholder={
                    !loginLabels.login_form_username_label
                      ? "Email or username"
                      : loginLabels.login_form_username_label.label_name
                  }
                  maxLength={200}
                  id="input_username"
                  defaultValue={cachedUsername}
                  style={{ width: "100%" }}
                />
                <span
                  className="required_message d-none"
                  id="input_username_required"
                >
                  {!loginLabels.login_form_join_err_username_req
                    ? "Username is required"
                    : loginLabels.login_form_join_err_username_req.label_name}
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5 col-xl-5 mt-4 position-relative">
                <span className="show_password" onClick={showPasswordStatus}>
                  <img
                    src="assets/icons/show.svg"
                    alt="show-icon"
                    style={{ width: "20px" }}
                  />
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  maxLength={200}
                  placeholder={
                    !loginLabels.login_form_password_label
                      ? "Password"
                      : loginLabels.login_form_password_label.label_name
                  }
                  id="input_password"
                  style={{ width: "100%" }}
                />
                <span
                  className="required_message d-none"
                  id="input_password_required"
                >
                  {!loginLabels.login_form_join_err_pwd_req
                    ? "Password is required"
                    : loginLabels.login_form_join_err_pwd_req.label_name}
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5 col-xl-5 mt-4 login_forgot_password_container">
                <div className="remember_user_container">
                  <input
                    type="checkbox"
                    id="input_remember_user"
                    maxLength={200}
                  />
                  <span className="remember_user_text text-white">
                    {!loginLabels.login_form_remember_me_label
                      ? "Remember me on this computer"
                      : loginLabels.login_form_remember_me_label.label_name}
                  </span>
                </div>
                <Link
                  className="btn_forgot_password text-white"
                  to="/forgot_password"
                  style={{ textTransform: "uppercase" }}
                >
                  {!loginLabels.login_form_forgot_pwd_link_label
                    ? "Forgot Your Password?"
                    : loginLabels.login_form_forgot_pwd_link_label.label_name}
                </Link>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5 col-xl-5 mt-4 d-flex login_btn_container align-items-center">
                <div className="create_acc_container">
                  <Link to="/register" className="create_acc_link text-white">
                    {!loginLabels.login_form_create_an_account
                      ? "CREATE AN ACCOUNT"
                      : loginLabels.login_form_create_an_account.label_name}
                  </Link>
                </div>
                <ButtonPrimary
                  classValue={loginClassVal}
                  textValue={
                    !loginLabels.login_form_main_title
                      ? "LOG IN"
                      : loginLabels.login_form_main_title.label_name
                  }
                  form="login_form"
                  onClick={getLoginInputData}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
