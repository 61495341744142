import React, { useEffect, useState, useRef, useContext } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "../my-competitions/Competitions.css";
import ProfileAccount from "../account/ProfileAccount";
import { useNavigate, useParams } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Parser from "html-react-parser";
import { ProgressBar, Spinner } from "react-bootstrap";
import { playerPositionSort } from "../../utils";
import { Tooltip } from "react-tooltip";
import ApiContext from "../../providers/Api/ApiContext";

export default function GameCard() {
  const { api } = useContext(ApiContext);
  const [activeGameOdds, setActiveGameOdds] = useState("-1");
  const [matchTeams, setMatchTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const tooltipRef = useRef();
  const [contestMarkets, setContestMarkets] = useState([]);
  const [actualPredictions, setActualPredictions] = useState([]);
  const [cardsPredictions, setCardsPredictions] = useState([]);
  const [userKycStatus, setUserKycStatus] = useState("");
  const [contestDetails, setContestDetails] = useState([]);
  const [anyMatchToStart, setAnyMatchToStart] = useState(false);
  const [successStatus, setSuccesStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [teamXi, setTeamXi] = useState([]);
  const [previousMatches, setPreviousMatches] = useState([]);
  const [H2HData, setH2HData] = useState([]);
  const [matchNews, setMatchNews] = useState([]);
  const [funfacts, setFunFacts] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [gamecardLabels, setGamecardLabels] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => {
    setProbalityTabContent({
      home_team_name: "",
      awat_team_name: "",
      home_team_primary_color: "",
      away_team_primary_color: "",
      home_winning_percentage: "",
      away_winning_percentage: "",
    });
    setTeamXi([]);
    setLineUp();
    setSubstitutes({});
    setShowModal(false);
  };
  const [submitType, setSubmitType] = useState("");
  var teamRef = useRef();
  const navigate = useNavigate();
  const { id, uid } = useParams();
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const [predictions, setPredictions] = useState([]);
  const [openedPage, setOpenedPage] = useState(false);
  const [probabilityTabContent, setProbalityTabContent] = useState({
    home_team_name: "",
    awat_team_name: "",
    home_team_primary_color: "",
    away_team_primary_color: "",
    home_winning_percentage: "",
    away_winning_percentage: "",
  });
  const [lineup, setLineUp] = useState();
  const [isPitchLoading, setPitchLoading] = useState(false);
  const [substitutes, setSubstitutes] = useState({});
  var predictions_odds = [];
  var cards_predictions = [];
  var sports = {
    1: "soccer",
    2: "cricket",
    11: "esport",
    16: "icehockey",
    18: "rugbyleague",
    3: "nfl",
    8: "basketball",
    5: "australianrules",
    12: "gaelicgames",
    7: "baseball",
  };
  var actual_predictions = [];
  var submitedPredictions = [];
  var clickedOdds = [];
  var cricketOdds = [];

  const getMatchTeamsData = () => {
    var match_teams_url = "/api/" + sports[id] + "/gamecard/match_teams";
    var body = {
      contest_uid: uid,
    };
    showLoader();
    api
      .post(match_teams_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          setMatchTeams(data);
          for (var i = 0; i < data.length; i++) {
            data[i].predictions.is_ace_used = data[i].is_ace_used;
            data[i].predictions.is_joker_used = data[i].is_joker_used;
            data[i].predictions.season_id = data[i].season_id;
            if (!Array.isArray(data[i].predictions)) {
              actual_predictions.push(data[i].predictions);
            }
          }
          setActualPredictions(actual_predictions);
          setLoading(false);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const getContestMarkets = () => {
    var match_teams_url = "/api/" + sports[id] + "/gamecard/contest_markets";
    var body = {
      contest_uid: uid,
    };
    showLoader();
    api
      .post(match_teams_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          setContestMarkets(data);
          setLoading(false);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const showGameOdds = (e) => {
    var game_id = e.target.getAttribute("data-id");
    var w = window.innerWidth;
    var h = window.innerHeight;
    if (w <= 991) {
      setActiveGameOdds(game_id);
      if (activeGameOdds === game_id) {
        setActiveGameOdds("-1");
      }
    }
  };

  const getGamecardLabels = (page_name) => {
    var gamecard_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: page_name,
    };
    api
      .post(gamecard_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setGamecardLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setOpenedPage(true);
    getGamecardLabels("gamecard");
    getContestMarkets();
    getMatchTeamsData();
    fetchContestDetails();

    var w = window.innerWidth;
    var h = window.innerHeight;

    if (w < 991) {
      setSubmitType("single_submit");
    } else {
      setSubmitType("multi_submit");
    }

    window.addEventListener("resize", () => {
      var w = window.innerWidth;
      var h = window.innerHeight;

      if (w > 991) {
        setActiveGameOdds("-1");
        setSubmitType("multi_submit");
      } else {
        setSubmitType("single_submit");
      }
    });

    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (loggedUserData !== null) {
      setUserKycStatus(loggedUserData.kyc_status);
    }
  }, [reload]);

  // submit matches odds
  const submitPredictions = (e) => {
    e.preventDefault();
    var submitType = e.currentTarget.getAttribute("datatype");
    var seasonId = e.currentTarget.getAttribute("data-seasonid");

    if (submitType == "single_submit") {
      var active_joker = document.querySelector(
        '.change_points.active[data-value="triple"][data-type="single-card"]'
      );
      var active_ace = document.querySelector(
        '.change_points.active[data-value="double"][data-type="single-card"]'
      );
    } else {
      var active_joker = document.querySelector(
        '.change_points.active[data-value="triple"][data-type="multi-card"]'
      );
      var active_ace = document.querySelector(
        '.change_points.active[data-value="double"][data-type="multi-card"]'
      );
    }

    if (predictions.length > 0) {
      var predictions_odds = predictions;
    } else {
      var predictions_odds = actualPredictions;
    }

    if (active_joker !== null) {
      var active_joker_season_id = active_joker.getAttribute("data-seasonid");
      if (predictions_odds.length > 0) {
        predictions_odds = predictions_odds.map(
          ({ is_joker_used, ...rest }) => ({ ...rest, is_joker_used: 0 })
        );
        var objIndex = predictions_odds.findIndex(
          (obj) => obj.season_id == active_joker_season_id
        );
        if (objIndex != -1) {
          predictions_odds[objIndex].is_joker_used = 1;
        }
        setPredictions(predictions_odds);
      }
    } else {
      var active_joker_season_id = "";
      predictions_odds = predictions_odds.map(({ is_joker_used, ...rest }) => ({
        ...rest,
        is_joker_used: 0,
      }));
      setPredictions(predictions_odds);
    }
    if (active_ace !== null) {
      var active_ace_season_id = active_ace.getAttribute("data-seasonid");
      if (predictions_odds.length > 0) {
        predictions_odds = predictions_odds.map(({ is_ace_used, ...rest }) => ({
          ...rest,
          is_ace_used: 0,
        }));
        var objIndex = predictions_odds.findIndex(
          (obj) => obj.season_id == active_ace_season_id
        );
        if (objIndex != -1) {
          predictions_odds[objIndex].is_ace_used = 1;
        }
        setPredictions(predictions_odds);
      }
    } else {
      var active_ace_season_id = "";
      predictions_odds = predictions_odds.map(({ is_ace_used, ...rest }) => ({
        ...rest,
        is_ace_used: 0,
      }));
      setPredictions(predictions_odds);
    }

    if (id == 2) {
      if (submitType !== "single_submit") {
        var selectedOddsEl = document.querySelectorAll(
          '.set_game_odd[data-type="multi-card"].active'
        );
      } else {
        var selectedOddsEl = document.querySelectorAll(
          '.set_game_odd[data-type="single-card"].active'
        );
      }
      for (var i = 0; i < selectedOddsEl.length; i++) {
        var selectedOddsRow = selectedOddsEl[i];
        var is_joker_used = 0;
        var is_ace_used = 0;
        var market_group = selectedOddsRow.dataset.marketgroup;
        var master_sport_market_id = selectedOddsRow.dataset.marketid;
        var point = selectedOddsRow.dataset.points;
        var season_id = selectedOddsRow.dataset.seasonid;
        var team_id = selectedOddsRow.dataset.teamid;

        cricketOdds.push({
          is_ace_used: is_ace_used,
          is_joker_used: is_joker_used,
          market_group: market_group,
          master_sport_market_id: master_sport_market_id,
          point: point,
          season_id: season_id,
          team_id: team_id,
        });
      }
      if (active_joker !== null) {
        var active_joker_season_id = active_joker.dataset.seasonid;
      } else {
        var active_joker_season_id = "";
      }
      if (active_ace !== null) {
        var active_ace_season_id = active_ace.dataset.seasonid;
      } else {
        var active_ace_season_id = "";
      }
      cricketOdds.map((el) => {
        if (el.season_id === active_joker_season_id) {
          el.is_joker_used = 1;
        }
        if (el.season_id === active_ace_season_id) {
          el.is_ace_used = 1;
        }
      });
    }

    var create_predictions_url =
      "/api/" + sports[id] + "/gamecard/create_predictions";

    if (id != 2) {
      submitedPredictions = predictions_odds;
    } else {
      submitedPredictions = cricketOdds;
    }

    // if (submitType == "single_submit") {
    //   submitedPredictions = submitedPredictions.filter(function (row) {
    //     return row.season_id == seasonId;
    //   });
    // }

    if (submitedPredictions.length == 0) {
      alert_notify("error", "No predictions changes have been made");
    }
    var potentialPoints = 0;
    for (var l = 0; l < submitedPredictions.length; l++) {
      var team_odd_row = submitedPredictions[l];
      var is_ace_used_value = team_odd_row.is_ace_used;
      var is_joker_used_value = team_odd_row.is_joker_used;

      if (is_ace_used_value != 0 || is_joker_used_value != 0) {
        if (is_ace_used_value != 0) {
          potentialPoints += Number(team_odd_row.point) * 2;
        } else if (is_joker_used_value != 0) {
          potentialPoints += Number(team_odd_row.point) * 3;
        }
      } else {
        potentialPoints += Number(team_odd_row.point);
      }
      if (
        document.querySelector(
          '.set_game_odd[data-teamid="' +
            team_odd_row.team_id +
            '"][data-seasonid="' +
            team_odd_row.season_id +
            '"][data-points="' +
            team_odd_row.point +
            '"][data-marketid="' +
            team_odd_row.master_sport_market_id +
            '"]'
        ) != null
      ) {
        document
          .querySelector(
            '.set_game_odd[data-teamid="' +
              team_odd_row.team_id +
              '"][data-seasonid="' +
              team_odd_row.season_id +
              '"][data-points="' +
              team_odd_row.point +
              '"][data-marketid="' +
              team_odd_row.master_sport_market_id +
              '"]'
          )
          .classList.add("active");
      }
    }
    document.querySelector(".potential_points").innerHTML = potentialPoints;

    var body = {
      contest_uid: uid,
      predictions: submitedPredictions,
    };

    if (
      userKycStatus !== "2" &&
      Number(contestDetails.contest.entry_fee) != "0"
    ) {
      alert_notify("error", "Your KYC status key must be approved to submit");
      return false;
    } else {
      showLoader();
      api
        .post(create_predictions_url, body)
        .then((response) => {
          if (response.status === 200) {
            var data = response.data.Data;
            var isFailed = false;
            if (data) {
              data.map((id) => {
                matchTeams.map((row) => {
                  if (row.season_id === id) {
                    isFailed = true;
                  }
                });
              });
            }
            if (isFailed) {
              if (
                body.predictions.length === 1 ||
                body.predictions.length === data.length
              ) {
                alert_notify(
                  "error",
                  "Not able to update predictions because competition is already started."
                );
              } else {
                alert_notify(
                  "success",
                  "Your predictions are saved."
                );
              }
              setReload(!reload);
            } else {
              alert_notify("success", response.data.Message);
            }
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
          setSuccesStatus(false);
        });
    }
  };

  const fetchContestDetails = () => {
    var contest_details_url = "/api/" + sports[id] + "/gamecard/prize_data";
    var body = {
      contest_uid: uid,
    };
    showLoader();
    api
      .post(contest_details_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          if (data.seasons.matches != undefined) {
            var matches = data.seasons.matches;
            var anyMatchRemaingToStart = matches.some((odd) => {
              return odd.match_started === false;
            });
          } else {
            var anyMatchRemaingToStart = true;
          }
          setAnyMatchToStart(anyMatchRemaingToStart);
          setContestDetails(data);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  var totalActivePoints = 0;
  const changePoint = (e) => {
    if (id == 2) {
      var isOpen = tooltipRef.current?.isOpen;
      if (isOpen) {
        tooltipRef.current?.close();
      }
    }
    var points = e.target.getAttribute("data-points");
    var season_id = e.target.getAttribute("data-seasonid");
    var master_sport_market_id = e.target.getAttribute("data-marketid");
    var team_id = e.target.getAttribute("data-teamid");
    var index = e.target.getAttribute("data-index");
    if (id == 1 && e.target.getAttribute("data-marketid") == 4) {
      var selectedOddsEl = document.querySelectorAll(
        '.set_game_odd[data-type="multi-card"].active'
      );
      let totalDrawSelected = 0;
      for (var i = 0; i < selectedOddsEl.length; i++) {
        if (selectedOddsEl[i].dataset.marketid == "4") {
          totalDrawSelected++;
        }
      }
      if (
        totalDrawSelected + 1 > (75 / 100) * matchTeams.length &&
        !e.target.classList.contains("active")
      ) {
        alert_notify(
          "error",
          "We cap selecting all score draws to 75% of matches. See T&C's"
        );
        return;
      }
    }
    var potential_points = Number(
      document.querySelector(".potential_points").textContent
    );
    var type = e.target.getAttribute("data-type");
    setOpenedPage(false);
    e.target.textContent = points;

    var active_ace = document.querySelector(
      '.change_points.active[data-value="double"][data-type="' +
        type +
        '"][data-index="' +
        index +
        '"]'
    );
    var active_joker = document.querySelector(
      '.change_points.active[data-value="triple"][data-type="' +
        type +
        '"][data-index="' +
        index +
        '"]'
    );

    if (id != "2") {
      if (
        document.querySelector(
          ".odds_col_" +
            index +
            " .set_game_odd.active[data-type='" +
            type +
            "']"
        ) !== null
      ) {
        var previous_points = Number(
          document.querySelector(
            ".odds_col_" +
              index +
              " .set_game_odd.active[data-type='" +
              type +
              "']"
          ).textContent
        );
      } else {
        var previous_points = 0;
      }
      document
        .querySelectorAll(".odds_col_" + index + " .set_game_odd")
        .forEach((e) => (e.textContent = e.dataset.points));
      // Potential points
      if (e.target.classList.contains("active")) {
        e.target.classList.remove("active");
      } else {
        document
          .querySelectorAll(".odds_col_" + index + " .set_game_odd")
          .forEach((e) => e.classList.remove("active"));
        e.target.classList.add("active");
      }

      if (
        document.querySelector(
          ".odds_col_" +
            index +
            " .set_game_odd.active[data-type='" +
            type +
            "']"
        ) !== null
      ) {
        var actual_points = Number(
          document.querySelector(
            ".odds_col_" +
              index +
              " .set_game_odd.active[data-type='" +
              type +
              "']"
          ).textContent
        );
      } else {
        var actual_points = 0;
      }

      if (active_ace != null) {
        if (e.target.classList.contains("active")) {
          e.target.textContent = points * 2;
        }
      }
      if (active_joker != null) {
        if (e.target.classList.contains("active")) {
          e.target.textContent = points * 3;
        }
      }
      var activeChangePoints = document.querySelectorAll(
        ".set_game_odd.active[data-type='" + type + "']"
      );
      for (var i = 0; i < activeChangePoints.length; i++) {
        totalActivePoints += Number(activeChangePoints[i].textContent);
      }
      document.querySelector(".potential_points").innerHTML = totalActivePoints;

      var odds = {
        is_ace_used: 0,
        is_joker_used: 0,
        master_sport_market_id: master_sport_market_id,
        point: points,
        season_id: season_id,
        team_id: team_id,
      };

      if (!openedPage) {
        clickedOdds = [...predictions];
      } else {
        clickedOdds = [...actualPredictions];
      }
      // if (predictions_odds.some((odd) => {
      //   return (odd.season_id === season_id && odd.master_sport_market_id === master_sport_market_id);})) {
      //   predictions_odds = predictions_odds.filter((odd) => {return odd.season_id !== season_id;});
      // } else {
      //   predictions_odds = predictions_odds.filter((odd) => {return odd.season_id !== season_id;});
      //   predictions_odds.push(odds);
      // }
      // console.log(predictions_odds)

      var containsOdd = clickedOdds.some((odd) => {
        return (
          odd.season_id === season_id &&
          odd.master_sport_market_id === master_sport_market_id &&
          odd.point === points
        );
      });
      if (!containsOdd) {
        clickedOdds = clickedOdds.filter((odd) => {
          return odd.season_id !== season_id;
        });
        clickedOdds.push(odds);
        predictions_odds = clickedOdds;
      } else {
        clickedOdds = clickedOdds.filter((odd) => {
          return odd.season_id !== season_id;
        });
        predictions_odds = clickedOdds;
      }
    } else {
      var market_group = e.target.getAttribute("data-marketgroup");
      var odds = {
        is_ace_used: 0,
        is_joker_used: 0,
        master_sport_market_id: master_sport_market_id,
        point: points,
        season_id: season_id,
        team_id: team_id,
        market_group: market_group,
      };

      if (e.target.classList.contains("active")) {
        e.target.classList.remove("active");
      } else {
        if (market_group != "win") {
          var selected_win_marketgroup = false;
          document
            .querySelectorAll(
              ".odds_col_" +
                index +
                ' .set_game_odd[data-type="' +
                type +
                '"][data-marketgroup="win"]'
            )
            .forEach((e) => {
              if (e.classList.contains("active")) {
                selected_win_marketgroup = true;
              }
            });

          if (!selected_win_marketgroup) {
            var test = false;
            document
              .querySelectorAll(
                ".odds_col_" +
                  index +
                  ' .set_game_odd[data-type="' +
                  type +
                  '"][data-marketgroup="' +
                  market_group +
                  '"][data-teamid="' +
                  team_id +
                  '"]'
              )
              .forEach((e) => e.classList.remove("active"));
            document
              .querySelectorAll(
                ".odds_col_" +
                  index +
                  ' .set_game_odd[data-type="' +
                  type +
                  '"][data-marketgroup]:not([data-marketgroup="win"])[data-teamid]:not([data-teamid="' +
                  team_id +
                  '"])'
              )
              .forEach((e) => {
                if (e.classList.contains("active")) {
                  test = true;
                }
              });
            if (!test) {
              e.target.classList.add("active");
            }
          }
          if (id == 2) {
            if (isOpen) {
              setTimeout(() => {
                checkOddsSelection(odds, e);
              }, 1000);
            } else {
              checkOddsSelection(odds, e);
            }
          }
        } else {
          if (e.target.classList.contains("active")) {
            e.target.classList.remove("active");
          } else {
            var selected_win_marketgroup = false;
            document
              .querySelectorAll(
                ".odds_col_" +
                  index +
                  ' .set_game_odd[data-type="' +
                  type +
                  '"][data-marketgroup="win"]'
              )
              .forEach((e) => {
                if (e.classList.contains("active")) {
                  selected_win_marketgroup = true;
                }
              });

            if (!selected_win_marketgroup) {
              document
                .querySelectorAll(
                  ".odds_col_" +
                    index +
                    " .set_game_odd[data-type='" +
                    type +
                    "']"
                )
                .forEach((e) => e.classList.remove("active"));
              e.target.classList.add("active");
            }
          }
        }
      }
      if (active_ace != null) {
        if (e.target.classList.contains("active")) {
          e.target.textContent = points * 2;
        }
      }
      if (active_joker != null) {
        if (e.target.classList.contains("active")) {
          e.target.textContent = points * 3;
        }
      }
      var activeChangePoints = document.querySelectorAll(
        ".set_game_odd.active[data-type='" + type + "']"
      );
      for (var i = 0; i < activeChangePoints.length; i++) {
        totalActivePoints += Number(activeChangePoints[i].textContent);
      }
      document.querySelector(".potential_points").innerHTML = totalActivePoints;
      var defaultOddsPoints = document.querySelectorAll(
        ".set_game_odd[data-type='" + type + "'][data-index='" + index + "']"
      );
      for (var k = 0; k < defaultOddsPoints.length; k++) {
        if (!defaultOddsPoints[k].classList.contains("active")) {
          defaultOddsPoints[k].innerHTML = defaultOddsPoints[k].dataset.points;
        }
      }
    }
    setPredictions(predictions_odds);
  };

  const changeWildCards = (e) => {
    if (e.target.classList.contains("change_points")) {
      var type = e.target.getAttribute("data-value");
      var season_id = e.target.getAttribute("data-seasonid");
      var index = e.target.getAttribute("data-index");
      var potential_points = Number(
        document.querySelector(".potential_points").textContent
      );
      var selected_odd = document.querySelector(
        ".odds_col_" + index + " .set_game_odd.active"
      );
      var cardType = e.target.getAttribute("data-type");
      if (selected_odd !== null) {
        var selected_odd_points = selected_odd.getAttribute("data-points");
      }
      var points = e.target.getAttribute("data-points");
      var master_sport_market_id = e.target.getAttribute("data-marketid");
      var team_id = e.target.getAttribute("data-teamid");
      predictions_odds = [...predictions];
      cards_predictions = [...cardsPredictions];
      if (type === "double") {
        if (e.target.classList.contains("active")) {
          e.target.classList.remove("active");
          document
            .querySelectorAll(
              '.wildcard[data-value="double"][data-type="' + cardType + '"]'
            )
            .forEach((e) => e.classList.add("change_points"));
          var active_joker_index = document.querySelector(
            '.change_points.active[data-value="triple"][data-type="' +
              cardType +
              '"]'
          );
          if (active_joker_index === null) {
            document
              .querySelector(
                ".game_row_" +
                  index +
                  ' .gamecard_points[data-value="triple"][data-type="' +
                  cardType +
                  '"]'
              )
              .classList.add("change_points");
          } else {
            var el_index = active_joker_index.getAttribute("data-index");
            document
              .querySelector(
                ".game_row_" +
                  el_index +
                  ' .gamecard_points[data-value="double"][data-type="' +
                  cardType +
                  '"]'
              )
              .classList.remove("change_points");
          }
          if (selected_odd !== null) {
            selected_odd.innerHTML = selected_odd_points;
          }
        } else {
          document
            .querySelectorAll(
              '.gamecard_points[data-value="double"][data-type="' +
                cardType +
                '"]'
            )
            .forEach((e) => e.classList.remove("change_points"));
          e.target.classList.add("active");
          e.target.classList.add("change_points");
          var active_joker_index = document.querySelector(
            '.change_points.active[data-value="triple"][data-type="' +
              cardType +
              '"]'
          );
          if (active_joker_index === null) {
            document
              .querySelector(
                ".game_row_" +
                  index +
                  ' .change_points[data-value="triple"][data-type="' +
                  cardType +
                  '"]'
              )
              .classList.remove("change_points");
          } else {
            var el_index = active_joker_index.getAttribute("data-index");
          }
          if (selected_odd !== null) {
            selected_odd.innerHTML = Number(selected_odd_points) * 2;
          }
        }
      } else {
        if (e.target.classList.contains("active")) {
          e.target.classList.remove("active");
          document
            .querySelectorAll('.wildcard[data-value="triple"]')
            .forEach((e) => e.classList.add("change_points"));
          var active_ace_index = document.querySelector(
            '.change_points.active[data-value="double"][data-type="' +
              cardType +
              '"]'
          );
          if (active_ace_index === null) {
            document
              .querySelector(
                ".game_row_" +
                  index +
                  ' .gamecard_points[data-value="double"][data-type="' +
                  cardType +
                  '"]'
              )
              .classList.add("change_points");
          } else {
            var el_index = active_ace_index.getAttribute("data-index");
            document
              .querySelector(
                ".game_row_" +
                  el_index +
                  ' .gamecard_points[data-value="triple"]'
              )
              .classList.remove("change_points");
          }
          if (selected_odd !== null) {
            selected_odd.innerHTML = selected_odd_points;
          }
        } else {
          document
            .querySelectorAll('.wildcard[data-value="triple"]')
            .forEach((e) => e.classList.remove("change_points"));
          e.target.classList.add("active");
          e.target.classList.add("change_points");
          var active_ace_index = document.querySelector(
            '.change_points.active[data-value="double"][data-type="' +
              cardType +
              '"]'
          );
          if (active_ace_index === null) {
            document
              .querySelector(
                ".game_row_" +
                  index +
                  ' .change_points[data-value="double"][data-type="' +
                  cardType +
                  '"]'
              )
              .classList.remove("change_points");
          } else {
            var el_index = active_ace_index.getAttribute("data-index");
          }
          if (selected_odd !== null) {
            selected_odd.innerHTML = Number(selected_odd_points) * 3;
          }
        }
      }

      if (
        document.querySelector(
          ".odds_col_" +
            index +
            " .set_game_odd.active[data-type='" +
            cardType +
            "']"
        ) !== null
      ) {
        var actual_points = Number(
          document.querySelector(
            ".odds_col_" +
              index +
              " .set_game_odd.active[data-type='" +
              cardType +
              "']"
          ).textContent
        );
        var total = potential_points - selected_odd_points + actual_points;
        if (!isNaN(total)) {
          document.querySelector(".potential_points").innerHTML = total;
        }
      } else {
        var actual_points = 0;
        var total = potential_points - selected_odd_points + actual_points;
        if (!isNaN(total)) {
          document.querySelector(".potential_points").innerHTML = total;
        }
      }

      // Potential points
      if (!e.target.classList.contains("active")) {
        if (type === "double") {
          if (
            document.querySelector(
              ".odds_col_" +
                index +
                " .set_game_odd.active[data-type='" +
                cardType +
                "']"
            ) !== null
          ) {
            var actual_points =
              Number(
                document.querySelector(
                  ".odds_col_" +
                    index +
                    " .set_game_odd.active[data-type='" +
                    cardType +
                    "']"
                ).textContent
              ) * 2;
          } else {
            var actual_points = 0;
          }
        } else {
          if (
            document.querySelector(
              ".odds_col_" +
                index +
                " .set_game_odd.active[data-type='" +
                cardType +
                "']"
            ) !== null
          ) {
            var actual_points =
              Number(
                document.querySelector(
                  ".odds_col_" +
                    index +
                    " .set_game_odd.active[data-type='" +
                    cardType +
                    "']"
                ).textContent
              ) * 3;
          } else {
            var actual_points = 0;
          }
        }
        var total =
          potential_points + Number(selected_odd_points) - actual_points;
        if (!isNaN(total)) {
          document.querySelector(".potential_points").innerHTML = total;
        }
      }

      var active_joker = document.querySelector(
        '.change_points.active[data-value="triple"][data-type="' +
          cardType +
          '"]'
      );
      var active_ace = document.querySelector(
        '.change_points.active[data-value="double"][data-type="' +
          cardType +
          '"]'
      );

      var activeChangePoints = document.querySelectorAll(
        ".set_game_odd.active[data-type='" + cardType + "']"
      );
      for (var i = 0; i < activeChangePoints.length; i++) {
        totalActivePoints += Number(activeChangePoints[i].textContent);
      }
      document.querySelector(".potential_points").innerHTML = totalActivePoints;

      if (active_joker !== null) {
        var active_joker_season_id = active_joker.getAttribute("data-seasonid");
        var objIndex = predictions_odds.findIndex(
          (obj) => obj.season_id == active_joker_season_id
        );
        if (objIndex != -1) {
          predictions_odds[objIndex].is_joker_used = 1;
        }
        var object_index = predictions_odds.findIndex(
          (x) => x.season_id === season_id
        );
        if (object_index != -1) {
          predictions_odds[object_index].existing_prediction = true;
          setPredictions(predictions_odds);
        }
        if (type == "triple") {
          var cards_odds = {
            existing_prediction: true,
            is_ace_used: 0,
            is_joker_used: 1,
            season_id: season_id,
            prediction_failed: true,
          };
          if (
            cards_predictions.some((odd) => {
              return odd.season_id === season_id;
            })
          ) {
            cards_predictions = cards_predictions.filter((odd) => {
              return odd.season_id !== season_id;
            });
          }
          cards_predictions.push(cards_odds);
          setCardsPredictions(cards_predictions);
        }
      } else {
        var active_joker_season_id = "";
        if (type == "triple") {
          var cards_odds = {
            existing_prediction: true,
            is_ace_used: 0,
            is_joker_used: 0,
            season_id: season_id,
            prediction_failed: true,
          };
          if (
            cards_predictions.some((odd) => {
              return odd.season_id === season_id;
            })
          ) {
            cards_predictions = cards_predictions.filter((odd) => {
              return odd.season_id !== season_id;
            });
          }
          cards_predictions.push(cards_odds);
          setCardsPredictions(cards_predictions);
        }
      }
      if (active_ace !== null) {
        var active_ace_season_id = active_ace.getAttribute("data-seasonid");
        var objIndex = predictions_odds.findIndex(
          (obj) => obj.season_id == active_ace_season_id
        );
        if (objIndex != -1) {
          predictions_odds[objIndex].is_ace_used = 1;
        }
        var object_index = predictions_odds.findIndex(
          (x) => x.season_id === season_id
        );

        if (type == "double") {
          var cards_odds = {
            existing_prediction: true,
            is_ace_used: 1,
            is_joker_used: 0,
            season_id: season_id,
            prediction_failed: true,
          };
          if (
            cards_predictions.some((odd) => {
              return odd.season_id === season_id;
            })
          ) {
            cards_predictions = cards_predictions.filter((odd) => {
              return odd.season_id !== season_id;
            });
          }
          cards_predictions.push(cards_odds);
          setCardsPredictions(cards_predictions);
        }
      } else {
        var active_ace_season_id = "";
        if (type == "double") {
          var cards_odds = {
            existing_prediction: true,
            is_ace_used: 0,
            is_joker_used: 0,
            season_id: season_id,
            prediction_failed: true,
          };
          if (
            cards_predictions.some((odd) => {
              return odd.season_id === season_id;
            })
          ) {
            cards_predictions = cards_predictions.filter((odd) => {
              return odd.season_id !== season_id;
            });
          }
          cards_predictions.push(cards_odds);
          setCardsPredictions(cards_predictions);
        }
      }

      var cricketPotentialPoints = 0;
      if (id == 2) {
        var cricketAllActiveOdds = document.querySelectorAll(
          ".set_game_odd.active[data-type='" +
            cardType +
            "'][data-index='" +
            index +
            "']"
        );
        if (e.target.classList.contains("active")) {
          var value = e.target.dataset.value;
          for (var k = 0; k < cricketAllActiveOdds.length; k++) {
            var oddValue = cricketAllActiveOdds[k].dataset.points;
            if (value == "double") {
              cricketAllActiveOdds[k].innerHTML = Number(oddValue) * 2;
            } else {
              cricketAllActiveOdds[k].innerHTML = Number(oddValue) * 3;
            }
          }
        } else {
          for (var k = 0; k < cricketAllActiveOdds.length; k++) {
            var oddValue = cricketAllActiveOdds[k].dataset.points;
            cricketAllActiveOdds[k].innerHTML = Number(oddValue);
          }
        }
      }
      var cricketAllActiveOdds = document.querySelectorAll(
        ".set_game_odd.active[data-type='" + cardType + "']"
      );
      for (var l = 0; l < cricketAllActiveOdds.length; l++) {
        var rowPoint = cricketAllActiveOdds[l].textContent;
        cricketPotentialPoints += Number(rowPoint);
      }
      document.querySelector(".potential_points").innerHTML =
        cricketPotentialPoints;
    }
  };

  const getPlayerXi = (season_id, team_id) => {
    showLoader();
    var player_xi_url = "/api/team/player_xi";

    var body = {
      season_id: season_id,
      team_id: team_id,
    };
    showLoader();
    api
      .post(player_xi_url, body)
      .then((response) => {
        if (response.status === 200) {
          var positionalData = {};
          for (i = 0; i < response.data.Data.players.length; i++) {
            if (
              response.data.Data.players[i].position_name_abbr == undefined ||
              response.data.Data.players[i].position_name_abbr == null
            ) {
              response.data.Data.players[i].position_name_abbr =
                "Unknown Position";
            }
            if (
              positionalData[
                response.data.Data.players[i].position_name_abbr
              ] == undefined
            ) {
              positionalData[response.data.Data.players[i].position_name_abbr] =
                [];
            }
            if (response.data.Data.players[i].player_name != "") {
              positionalData[
                response.data.Data.players[i].position_name_abbr
              ].push(response.data.Data.players[i]);
            }
          }
          setLineUp(response.data.Data.pitch_image);
          setPitchLoading(true);
          setSubstitutes(response.data.Data.substitutes);
          setTeamXi(positionalData);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      })
      .finally(() => {
        handleModalShow();
      });
  };

  const getPreviousMatches = (season_id, team_id) => {
    var previous_matches_url = "/api/team/previous_matches";
    var body = {
      season_id: season_id,
      target_team_id: team_id,
    };
    showLoader();
    api
      .post(previous_matches_url, body)
      .then((response) => {
        if (response.status === 200) {
          var prevMatches = [];
          var prevHomeMatches = [];
          var prevAwayMatches = [];

          if (response.data.Data != null) {
            if (response.data.Data.home.length > 0) {
              prevHomeMatches = response.data.Data.home;
            }
            if (response.data.Data.away.length > 0) {
              prevAwayMatches = response.data.Data.away;
            }
            prevMatches = prevHomeMatches.concat(prevAwayMatches);
            prevMatches.sort((a, b) => (a.match_date < b.match_date ? 1 : -1));
            setPreviousMatches(prevMatches);
          } else {
            setPreviousMatches([]);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  const getH2H = (season_id) => {
    var h2h_url = "/api/team/h2h";
    var body = {
      season_id: season_id,
    };
    showLoader();
    api
      .post(h2h_url, body)
      .then((response) => {
        if (response.status === 200) {
          setH2HData(response.data.Data);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  const getWinningProbability = (season_id) => {
    var probability_url = "/api/team/get_season_stats";
    showLoader();
    api
      .get(probability_url, {
        params: { season_id: season_id },
      })
      .then((response) => {
        if (response.status === 200) {
          setProbalityTabContent(response.data.Data);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  const fetchFunFacts = (season_id) => {
    var match_news_url = "/api/team/get_match_funfacts";
    var body = {
      season_id: season_id,
    };
    showLoader();
    api
      .post(match_news_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          setFunFacts(data);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  const getMatchNews = (season_id, team_id) => {
    var match_news_url = "/api/team/get_match_news";
    var body = {
      season_id: season_id,
    };
    showLoader();
    api
      .post(match_news_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var matchNewsArray = [];
          data.map((row) => {
            var rowTeamId = row.team_id;
            if (team_id == rowTeamId) {
              matchNewsArray.push(row);
            }
          });
          setMatchNews(matchNewsArray);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.GlobalError);
        }
        hideLoader();
      });
  };

  const goToThisLeaderboardPage = (e) => {
    var path = e.currentTarget.getAttribute("data-path");
    var uid = e.currentTarget.getAttribute("data-uid");
    navigate("/" + path + "/" + id + "/" + uid + "");
  };

  const getMatchDetails = (e, status) => {
    var team_id = e.target.getAttribute("data-teamid");
    var team_name = e.target.getAttribute("data-teamname");
    var season_id = e.target.getAttribute("data-seasonid");
    if (!status) {
      getWinningProbability(season_id);
    }
    getPlayerXi(season_id, team_id);
    getPreviousMatches(season_id, team_id);
    getH2H(season_id);
    fetchFunFacts(season_id);
    getMatchNews(season_id, team_id);
    setModalTitle(team_name);
  };

  var teams_col = document.querySelectorAll(".teams_col");
  for (var l = 0; l < teams_col.length; l++) {
    var teams_col_row = teams_col[l];
    if (teams_col_row != null) {
      teams_col_row.classList.remove("active_team_col");
    }
    if (
      document.querySelector('.teams_col[data-id="' + activeGameOdds + '"]') !=
      null
    ) {
      document
        .querySelector('.teams_col[data-id="' + activeGameOdds + '"]')
        .classList.add("active_team_col");
    }
  }

  var game_odds = document.querySelectorAll(".game_odds");
  // var gamecard_odds_row = document.querySelectorAll(".gamecard_odds_row");
  for (var i = 0; i < game_odds.length; i++) {
    var row = game_odds[i];
    row.style.display = "none";
    // gamecard_odd_row.querySelector('.teams_col').classList.remove('active_teams_col');
    if (activeGameOdds !== "-1") {
      // gamecard_odd_row.querySelector('.teams_col').classList.add('active_teams_col');
      game_odds[activeGameOdds].style.display = "block";
    }
  }
  var potential_points = 0;
  var used_helper = { ace: {}, joker: {} };

  for (var i = 0; i < matchTeams.length; i++) {
    var row = matchTeams[i];
    if (Array.isArray(row.predictions)) {
      var points = 0;
      for (var j = 0; j < row.predictions.length; j++) {
        points += Number(row.predictions[j].point);
      }
    } else {
      var points = Number(row.predictions.point);
    }
    if (row.is_ace_used != null && row.is_ace_used != 0) {
      used_helper.ace.season_id = row.season_id;
      points = points * 2;
    } else if (row.is_joker_used != null && row.is_joker_used != 0) {
      used_helper.joker.season_id = row.season_id;
      points = points * 3;
    }
    potential_points += points;
  }
  var league_country =
    contestDetails.contest != undefined
      ? contestDetails.contest.league_country
      : "";
  var league_name =
    contestDetails.contest != undefined
      ? contestDetails.contest.league_name
      : "";
  var leaderboard_position =
    contestDetails.rank != undefined ? parseInt(contestDetails.rank) : 0;
  var competiton_point =
    contestDetails.points != undefined ? parseInt(contestDetails.points) : 0;

  if (id == 2) {
    var market_odd_class = "cricket_odds";
    var ace_joker_class_cricket = "";
  } else {
    var market_odd_class = "";
    var ace_joker_class_cricket = "";
  }
  var prize_data =
    contestDetails?.contest?.prize_composition != null &&
    Number(contestDetails?.contest?.entry_fee)
      ? JSON.parse(contestDetails?.contest?.prize_composition)
      : false;
  var html = "";
  if (prize_data) {
    var prize_pool = contestDetails?.contest?.prize_pool
      ? contestDetails?.contest?.prize_pool
      : 0;
    Object.keys(prize_data).map(function (keyName, keyIndex) {
      var price = Number(prize_pool) * (Number(prize_data[keyName]) / 100);
      if (keyName != "consolation") {
        if (price != 0) {
          html += `<div className="col-lg-2 col-md-2 col-sm-12 col-4 p-0"> <img src="/assets/icons/${keyName}_place.svg" alt="gamecard-icon" /> <div className="price"> € ${price.toFixed(
            2
          )} </div> </div>`;
        }
      } else {
        if (prize_data[keyName]) {
          var consolation_data = prize_data[keyName];
          Object.keys(consolation_data).map(function (key, i) {
            if (Number(key) + 3 < 6) {
              var consolation_price =
                Number(prize_pool) * (Number(consolation_data[key]) / 100);
              if (consolation_price != 0) {
                html += `<div className="col-lg-2 col-md-2 col-sm-12 col-4 p-0"> <img src="/assets/icons/${
                  Number(key) + 3
                }_place.svg" alt="gamecard-icon" /> <div className="price"> € ${consolation_price.toFixed(
                  2
                )} </div> </div>`;
              }
            }
          });
        }
      }
    });
  }
  const checkOddsSelection = (odds, e) => {
    var selectedOddsEl = document.querySelectorAll(
      '.set_game_odd[data-type="multi-card"].active'
    );
    let count = 0;
    for (var i = 0; i < selectedOddsEl.length; i++) {
      if (selectedOddsEl[i].dataset.seasonid == odds.season_id) {
        count++;
      }
    }
    if (count == 1) {
      if (odds.market_group == "win_wickets") {
        tooltipRef.current?.open({
          position: {
            x: e.clientX,
            y: e.clientY - 10,
          },
          place: "top",
          content: "Now predict a runs option for same team",
        });
        tooltipRef.current?.close({
          delay: 5000,
        });
      } else {
        tooltipRef.current?.open({
          position: {
            x: e.clientX,
            y: e.clientY - 10,
          },
          place: "top",
          content: "Now predict a wickets option for same team",
        });
        tooltipRef.current?.close({
          delay: 5000,
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !gamecardLabels.gamecard_my_competitions
            ? "My Competitions"
            : gamecardLabels.gamecard_my_competitions.label_name
        }
      />
      <div className="gamecard_container primary-background">
        <div
          className={
            market_odd_class == "cricket_odds"
              ? "cricket_content_center"
              : "page_content_center"
          }
        >
          {successStatus ? (
            <SuccesNotification content="Your predictions have been succesfully submitted." />
          ) : (
            ""
          )}
          <div className="row gamecard_info m-0 mt-5">
            <div className="col-xl-8 col-lg-8 col-12 p-0">
              <div className="d-inline-flex align-items-center justify-content-between gamecard_tab competition_tab active_tab ">
                <span
                  className="gamecard_title text-white"
                  style={{ textTransform: "uppercase" }}
                >
                  {!gamecardLabels.gamecard_gamecard_label
                    ? "Gamecard"
                    : gamecardLabels.gamecard_gamecard_label.label_name}
                </span>
                <span
                  className="gamecard_icon m-0 text-white"
                  data-path="gamecard"
                  data-uid={uid}
                >
                  <img src="/assets/icons/gamecard.svg" alt="gamecard-icon" />
                </span>
              </div>
              <div
                className="d-inline-flex align-items-center justify-content-between leaderboard_tab competition_tab"
                data-path="leaderboard"
                data-uid={uid}
                onClick={goToThisLeaderboardPage}
                style={{ cursor: "pointer" }}
              >
                <span
                  className="gamecard_title text-white"
                  style={{ textTransform: "uppercase" }}
                >
                  {!gamecardLabels.gamecard_leaderboard_label
                    ? "Leaderboard"
                    : gamecardLabels.gamecard_leaderboard_label.label_name}
                </span>
                <span className="gamecard_icon m-0 text-white">
                  <img
                    src="/assets/icons/leaderboard.svg"
                    alt="leaderboard-icon"
                  />
                </span>
              </div>
              <span className="gamecard_competition text-white text-white">
                Competition {uid} ({league_country + " " + league_name})
              </span>
              <div className="row price_container m-0 mt-3">{Parser(html)}</div>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 gamecard_free_to_play">
              <span className="free_to_play_title text-white">
                {Number(contestDetails?.contest?.entry_fee)
                  ? "ENTRY FEE: €" + contestDetails?.contest?.entry_fee
                  : "FREE TO PLAY"}
              </span>
              <div className="gamecard_positions">
                <span className="d-block">
                  <div className="row">
                    <div className="col-10 text-white">
                      Leaderboard Position:
                    </div>
                    <div className="col-2 text-white">
                      {leaderboard_position}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 text-white">Competition Points:</div>
                    <div className="col-2 text-white">{competiton_point}</div>
                  </div>
                  <div className="row">
                    <div className="col-10 text-white">Potential Points: </div>
                    <div className="col-2 potential_points text-white">
                      {potential_points}
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div
            className="row align-items-center points_container justify-content-center"
            style={{ marginTop: "40px" }}
          >
            <div
              className={
                !successStatus
                  ? "triple_points col-5"
                  : "triple_points col-5 points_cards_succes"
              }
            >
              <span>
                <img
                  className="triple_points_icon"
                  src="/assets/icons/triple.svg"
                  alt="tiple-points-icon"
                />
              </span>
              <span style={{ textTransform: "uppercase", textAlign: "start" }}>
                {!gamecardLabels.gamecard_triple_points_label
                  ? "Triple your points"
                  : gamecardLabels.gamecard_triple_points_label.label_name}
              </span>
            </div>
            <div
              className={
                !successStatus
                  ? "double_points col-5"
                  : "double_points col-5 points_cards_succes"
              }
            >
              <span>
                <img
                  className="double_points_icon"
                  src="/assets/icons/double.svg"
                  alt="double-points-icon"
                />
              </span>
              <span style={{ textTransform: "uppercase", textAlign: "start" }}>
                {!gamecardLabels.gamecard_double_points_label
                  ? "Double your points"
                  : gamecardLabels.gamecard_double_points_label.label_name}
              </span>
            </div>
            <div className="points_info" onClick={handleShow}>
              {" "}
              ?{" "}
            </div>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>HOW TO PLAY WILDCARDS</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="how_to_play_wildcards">
                  <iframe
                    className="how_to_play_wildcards_video"
                    allowFullScreen="true"
                    src="https://www.youtube.com/embed/JtNPr2ofeb4"
                    frameborder="0"
                    title="How To Play Wildcards"
                  ></iframe>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="row gamecard_odds_types m-0">
            <div
              className={market_odd_class == "cricket_odds" ? "col-2" : "col-3"}
            ></div>
            <div className="col-2"></div>
            <div
              className={market_odd_class == "cricket_odds" ? "col-8" : "col-7"}
            >
              <div className="row justify-content-end">
                <div className={"col " + market_odd_class + " p-0"}></div>
                {!loading
                  ? contestMarkets.map((row, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            "col d-flex justify-content-center align-items-center gamecard_market_name " +
                            market_odd_class +
                            ""
                          }
                          data-marketid={row.master_sport_market_id}
                        >
                          {id == 3 && row.market_name == "Draw"
                            ? "Tie"
                            : row.market_name}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          <div className="gamecard_odds_container">
            {!loading
              ? matchTeams.map((row, index) => {
                  if (id != 3 && id != 16 && id != 8) {
                    var home_team_id = row.teams[0].team_id;
                    var away_team_id = row.teams[1].team_id;
                    var home_team_name = row.teams[0].team_name;
                    var away_team_name = row.teams[1].team_name;
                    var home_team_name_text = row.teams[0].team_name;
                    var away_team_name_text = row.teams[1].team_name;
                  } else {
                    var home_team_id = row.teams[1].team_id;
                    var away_team_id = row.teams[0].team_id;
                    var home_team_name = row.teams[1].team_name;
                    var away_team_name = row.teams[0].team_name;
                    var home_team_name_text = row.teams[1].team_name + " @";
                    var away_team_name_text = row.teams[0].team_name;
                  }

                  if (used_helper.ace.season_id !== undefined) {
                    if (used_helper.ace.season_id == row.season_id) {
                      var ace_active_class = "active";
                      if (!successStatus) {
                        var ace_event_class = "change_points";
                      } else {
                        var ace_event_class = "";
                      }

                      var joker_active_class = "";
                      var joker_event_class = "";
                    } else {
                      var ace_active_class = "";
                      var ace_event_class = "";
                    }
                  } else {
                    var ace_active_class = "";
                    if (!successStatus) {
                      if (used_helper.joker.season_id == row.season_id) {
                        var ace_event_class = "";
                      } else {
                        var ace_event_class = "change_points";
                      }
                    } else {
                      var ace_event_class = "";
                    }
                  }
                  if (used_helper.joker.season_id !== undefined) {
                    if (used_helper.joker.season_id == row.season_id) {
                      var joker_active_class = "active";
                      if (!successStatus) {
                        var joker_event_class = "change_points";
                      } else {
                        var joker_event_class = "";
                      }

                      var ace_active_class = "";
                      var ace_event_class = "";
                    } else {
                      var joker_active_class = "";
                      var joker_event_class = "";
                    }
                  } else {
                    var joker_active_class = "";
                    if (!successStatus) {
                      if (used_helper.ace.season_id == row.season_id) {
                        var joker_event_class = "";
                      } else {
                        var joker_event_class = "change_points";
                      }
                    } else {
                      var joker_event_class = "";
                    }
                  }
                  if (contestDetails.seasons != undefined) {
                    var details = contestDetails.seasons.matches.filter(
                      (match) => match.season_id == row.season_id
                    );
                  } else {
                    var details = [];
                  }

                  // Check for game result
                  var set_odds_class = "";
                  var home_score = "";
                  var away_score = "";
                  var can_play_row_class = "";
                  var vs = "vs";
                  if (row.match_result != null) {
                    var match_result = JSON.parse(row.match_result);
                    if (id != 3 && id != 16 && id != 8) {
                      if (match_result.home_score != undefined) {
                        home_score = match_result.home_score;
                        vs = "";
                      } else {
                        home_score = "";
                        vs = "vs";
                      }
                      if (match_result.away_score != undefined) {
                        away_score = match_result.away_score;
                        vs = "";
                      } else {
                        away_score = "";
                        vs = "vs";
                      }
                    } else {
                      if (match_result.home_score != undefined) {
                        away_score = match_result.home_score;
                        vs = "";
                      } else {
                        away_score = "";
                        vs = "vs";
                      }
                      if (match_result.away_score != undefined) {
                        home_score = match_result.away_score;
                        vs = "";
                      } else {
                        home_score = "";
                        vs = "vs";
                      }
                    }
                  } else if (!(id != 3 && id != 16 && id != 8)) {
                    vs = "";
                  } else {
                    var match_result = "";
                  }

                  // Check if match is completed
                  var completed_html = "";
                  var show_tick = false;
                  var liveGameClass = "";
                  var wildCardClass = "wildcard";

                  if (details.length != 0) {
                    if (details[0].match_started || details[0].status == "FT") {
                      if (details[0].status === "FT") {
                        completed_html = (
                          <div
                            className="completed_badge"
                            style={{ margin: "0 10px" }}
                          >
                            {" "}
                            Completed{" "}
                          </div>
                        );
                        liveGameClass = "";
                        can_play_row_class = "";
                        set_odds_class = "";
                        var ace_event_class = "";
                        var joker_event_class = "";
                        wildCardClass = "";
                        show_tick = true;
                      } else if (details[0].status == "Postp.") {
                        completed_html = (
                          <div
                            className="completed_badge"
                            style={{ margin: "0 10px" }}
                          >
                            {" "}
                            Postponed{" "}
                          </div>
                        );
                        liveGameClass = "";
                        can_play_row_class = "";
                        set_odds_class = "";
                        var ace_event_class = "";
                        var joker_event_class = "";
                        wildCardClass = "";
                        show_tick = true;
                      } else {
                        completed_html = (
                          <div
                            className="completed_badge"
                            style={{ margin: "0 10px" }}
                          >
                            {" "}
                            Live{" "}
                          </div>
                        );
                        liveGameClass = "";
                        can_play_row_class = "";
                        set_odds_class = "";
                        var ace_event_class = "";
                        var joker_event_class = "";
                        wildCardClass = "";
                        show_tick = true;
                      }
                      set_odds_class = "";
                      var ace_event_class = "";
                      var joker_event_class = "";

                      show_tick = true;
                    } else {
                      show_tick = false;
                      if (!successStatus) {
                        set_odds_class = "set_game_odd";
                        can_play_row_class = "active_play_row";
                      } else {
                        set_odds_class = "";
                        can_play_row_class = "";
                        wildCardClass = "";
                      }
                    }
                  } else {
                    completed_html = "";
                    show_tick = false;
                    if (!successStatus) {
                      set_odds_class = "set_game_odd";
                      can_play_row_class = "active_play_row";
                    } else {
                      set_odds_class = "";
                      can_play_row_class = "";
                    }
                  }

                  var dateTimeFormat = "DD MMM YY HH:mm";
                  var dateTimeUtc = moment.utc(row.date);
                  var localDate = dateTimeUtc.local();
                  var gamePlayTime = localDate.format(dateTimeFormat);

                  return (
                    <div
                      className={"gamecardrow game_row_" + index}
                      key={index}
                    >
                      <div
                        className={`row m-0 mt-2 gamecard_odds_row ${
                          market_odd_class == "cricket_odds" ? "px-0" : ""
                        }`}
                        data-id={index}
                      >
                        <div
                          className={
                            market_odd_class == "cricket_odds"
                              ? "col-12 col-lg-1 col-xl-1 match_datetime_status game_time"
                              : "col-12 col-lg-2 col-xl-2 match_datetime_status game_time"
                          }
                          style={{ textAlign: "center" }}
                        >
                          <div style={{ margin: "0 10px" }}>
                            {gamePlayTime}{" "}
                          </div>
                          {completed_html}
                        </div>
                        <div
                          className="col-12 col-xl-3 col-lg-3 d-flex p-0 align-items-center teams_col"
                          data-id={index}
                        >
                          <div className="teams">
                            <div className="hometeam">
                              <span
                                ref={teamRef}
                                className="hometeam_name team_name"
                                data-id={index}
                                data-type="home"
                                onClick={showGameOdds}
                              >
                                {home_team_name_text}
                              </span>
                              <div className="ml-2 match_score_info">
                                {" "}
                                <span className="mr-2 match_home_score">
                                  {" "}
                                  {home_score}{" "}
                                </span>{" "}
                                <span
                                  className="game_info"
                                  data-teamid={home_team_id}
                                  data-teamname={home_team_name}
                                  data-seasonid={row.season_id}
                                  onClick={(e) =>
                                    getMatchDetails(e, details[0].match_started)
                                  }
                                >
                                  i
                                </span>{" "}
                              </div>
                            </div>
                            <span
                              className={
                                home_score != ""
                                  ? "mr-2 match_home_score_mobile"
                                  : ""
                              }
                            >
                              {" "}
                              {home_score}{" "}
                            </span>
                            <div className="vs">{vs}</div>
                            <span
                              className={
                                away_score != ""
                                  ? "mr-2 match_away_score_mobile"
                                  : ""
                              }
                            >
                              {" "}
                              {away_score}{" "}
                            </span>
                            <div className="awayteam">
                              <span
                                ref={teamRef}
                                className="awayteam_name team_name"
                                data-id={index}
                                data-type="away"
                                onClick={showGameOdds}
                              >
                                {away_team_name_text}
                              </span>
                              <div className="ml-2 match_score_info">
                                {" "}
                                <span className="mr-2 match_away_score">
                                  {" "}
                                  {away_score}{" "}
                                </span>{" "}
                                <span
                                  className="game_info"
                                  data-teamid={away_team_id}
                                  data-teamname={away_team_name}
                                  data-seasonid={row.season_id}
                                  onClick={(e) =>
                                    getMatchDetails(e, details[0].match_started)
                                  }
                                >
                                  i
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            market_odd_class == "cricket_odds"
                              ? "col-8 p-0 odds_col odds_col_" +
                                index +
                                "" +
                                " " +
                                can_play_row_class
                              : "col-7 p-0 odds_col odds_col_" +
                                index +
                                "" +
                                " " +
                                can_play_row_class
                          }
                          data-id={index}
                        >
                          <div className="row m-0 align-items-center justify-content-center">
                            <div
                              className={
                                "col d-flex align-items-center justify-content-center p-0 text-center" +
                                market_odd_class +
                                ""
                              }
                            >
                              <div className="double_triple_points">
                                <span
                                  className="triple_game_points"
                                  data-value="triple"
                                  data-seasonid={row.season_id}
                                  data-index={index}
                                >
                                  <img
                                    className={`gamecard_points ${
                                      joker_active_class +
                                      " " +
                                      joker_event_class +
                                      " " +
                                      wildCardClass
                                    }`}
                                    src="/assets/icons/triple.svg"
                                    alt="triple-icon"
                                    data-value="triple"
                                    data-seasonid={row.season_id}
                                    data-index={index}
                                    data-type="multi-card"
                                    onClick={changeWildCards}
                                  />
                                </span>
                                <span
                                  className="double_game_points"
                                  data-value="double"
                                  data-seasonid={row.season_id}
                                  data-index={index}
                                >
                                  <img
                                    className={`gamecard_points ${
                                      ace_active_class +
                                      " " +
                                      ace_event_class +
                                      " " +
                                      wildCardClass
                                    }`}
                                    src="/assets/icons/double.svg"
                                    alt="double-icon"
                                    data-value="double"
                                    data-seasonid={row.season_id}
                                    data-index={index}
                                    data-type="multi-card"
                                    onClick={changeWildCards}
                                  />
                                </span>
                              </div>
                            </div>
                            {/* Desktop odds */}
                            {contestMarkets.map((market, market_index) => {
                              var multi = "";
                              var multi_odds =
                                row.points[market.master_sport_market_id];
                              var result =
                                row.result[market.master_sport_market_id];
                              var single_points_txt = "-";
                              var single_points_val = 0;
                              var home_points = "-";
                              var away_points = "-";
                              var active_class = "";
                              var home_active_class = "";
                              var away_active_class = "";
                              var win_odd = "";

                              if (market_index == 0) {
                                win_odd = "win";
                              } else {
                                win_odd = "";
                              }
                              var single_tick = "";
                              var home_tick = "0";
                              var away_tick = "0";

                              // Check for tick
                              if (Array.isArray(result)) {
                                if (result.length > 0) {
                                  single_tick = result[0];
                                } else {
                                  single_tick = 0;
                                }
                              } else {
                                home_tick =
                                  row.result[market.master_sport_market_id][
                                    home_team_id
                                  ];
                                away_tick =
                                  row.result[market.master_sport_market_id][
                                    away_team_id
                                  ];
                              }

                              // Check for points
                              if (Array.isArray(multi_odds)) {
                                multi = false;
                                if (multi_odds.length > 0) {
                                  single_points_val = multi_odds[0];
                                  single_points_txt = multi_odds[0];
                                } else {
                                  single_points_txt = "-";
                                  single_points_val = 0;
                                }
                              } else {
                                multi = true;
                                home_points =
                                  row.points[market.master_sport_market_id][
                                    home_team_id
                                  ];
                                away_points =
                                  row.points[market.master_sport_market_id][
                                    away_team_id
                                  ];
                              }

                              var home_points_txt = home_points;
                              var away_points_txt = away_points;
                              var withdraw_points_txt = single_points_txt;

                              // No cricket sports predictions
                              if (id != 2) {
                                if (row.predictions.master_sport_market_id) {
                                  if (
                                    row.predictions.master_sport_market_id ==
                                    market.master_sport_market_id
                                  ) {
                                    if (
                                      row.predictions.team_id == home_team_id
                                    ) {
                                      home_active_class = "active";
                                      // If any of joker of ace is used
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 3;
                                      } else {
                                        home_points_txt = Number(home_points);
                                      }
                                    } else if (
                                      row.predictions.team_id == away_team_id
                                    ) {
                                      away_active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 3;
                                      } else {
                                        away_points_txt = Number(away_points);
                                      }
                                    } else {
                                      active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 3;
                                      } else {
                                        withdraw_points_txt =
                                          Number(single_points_val);
                                      }
                                    }
                                  } else {
                                    home_active_class = "";
                                    away_active_class = "";
                                    active_class = "";
                                  }
                                } else {
                                  home_active_class = "";
                                  away_active_class = "";
                                  active_class = "";
                                }
                              } else {
                                // Cricket predictions
                                if (row.predictions.length == 2) {
                                  if (
                                    row.predictions[0].master_sport_market_id ==
                                    market.master_sport_market_id
                                  ) {
                                    if (
                                      row.predictions[0].master_sport_market_id
                                    ) {
                                      if (
                                        row.predictions[0]
                                          .master_sport_market_id ==
                                        market.master_sport_market_id
                                      ) {
                                        if (
                                          row.predictions[0].team_id ==
                                          home_team_id
                                        ) {
                                          home_active_class = "active";
                                          // If any of joker of ace is used
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            home_points_txt =
                                              Number(home_points) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            home_points_txt =
                                              Number(home_points) * 3;
                                          } else {
                                            home_points_txt =
                                              Number(home_points);
                                          }
                                        } else if (
                                          row.predictions[0].team_id ==
                                          away_team_id
                                        ) {
                                          away_active_class = "active";
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            away_points_txt =
                                              Number(away_points) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            away_points_txt =
                                              Number(away_points) * 3;
                                          } else {
                                            away_points_txt =
                                              Number(away_points);
                                          }
                                        } else {
                                          active_class = "active";
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            withdraw_points_txt =
                                              Number(single_points_val) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            withdraw_points_txt =
                                              Number(single_points_val) * 3;
                                          } else {
                                            withdraw_points_txt =
                                              Number(single_points_val);
                                          }
                                        }
                                      } else {
                                        home_active_class = "";
                                        away_active_class = "";
                                        active_class = "";
                                      }
                                    } else {
                                      home_active_class = "";
                                      away_active_class = "";
                                      active_class = "";
                                    }
                                  }
                                  if (
                                    row.predictions[1].master_sport_market_id ==
                                    market.master_sport_market_id
                                  ) {
                                    if (
                                      row.predictions[1].master_sport_market_id
                                    ) {
                                      if (
                                        row.predictions[1]
                                          .master_sport_market_id ==
                                        market.master_sport_market_id
                                      ) {
                                        if (
                                          row.predictions[1].team_id ==
                                          home_team_id
                                        ) {
                                          home_active_class = "active";
                                          // If any of joker of ace is used
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            home_points_txt =
                                              Number(home_points) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            home_points_txt =
                                              Number(home_points) * 3;
                                          } else {
                                            home_points_txt =
                                              Number(home_points);
                                          }
                                        } else if (
                                          row.predictions[1].team_id ==
                                          away_team_id
                                        ) {
                                          away_active_class = "active";
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            away_points_txt =
                                              Number(away_points) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            away_points_txt =
                                              Number(away_points) * 3;
                                          } else {
                                            away_points_txt =
                                              Number(away_points);
                                          }
                                        } else {
                                          active_class = "active";
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            withdraw_points_txt =
                                              Number(single_points_val) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            withdraw_points_txt =
                                              Number(single_points_val) * 3;
                                          } else {
                                            withdraw_points_txt =
                                              Number(single_points_val);
                                          }
                                        }
                                      } else {
                                        home_active_class = "";
                                        away_active_class = "";
                                        active_class = "";
                                      }
                                    } else {
                                      home_active_class = "";
                                      away_active_class = "";
                                      active_class = "";
                                    }
                                  }
                                } else {
                                  if (row.predictions.length == 1) {
                                    if (
                                      row.predictions[0].master_sport_market_id
                                    ) {
                                      if (
                                        row.predictions[0]
                                          .master_sport_market_id ==
                                        market.master_sport_market_id
                                      ) {
                                        if (
                                          row.predictions[0].team_id ==
                                          home_team_id
                                        ) {
                                          home_active_class = "active";
                                          // If any of joker of ace is used
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            home_points_txt =
                                              Number(home_points) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            home_points_txt =
                                              Number(home_points) * 3;
                                          } else {
                                            home_points_txt =
                                              Number(home_points);
                                          }
                                        } else if (
                                          row.predictions[0].team_id ==
                                          away_team_id
                                        ) {
                                          away_active_class = "active";
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            away_points_txt =
                                              Number(away_points) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            away_points_txt =
                                              Number(away_points) * 3;
                                          } else {
                                            away_points_txt =
                                              Number(away_points);
                                          }
                                        } else {
                                          active_class = "active";
                                          if (
                                            row.is_ace_used != null &&
                                            row.is_ace_used != 0
                                          ) {
                                            withdraw_points_txt =
                                              Number(single_points_val) * 2;
                                          } else if (
                                            row.is_joker_used != null &&
                                            row.is_joker_used != 0
                                          ) {
                                            withdraw_points_txt =
                                              Number(single_points_val) * 3;
                                          } else {
                                            withdraw_points_txt =
                                              Number(single_points_val);
                                          }
                                        }
                                      } else {
                                        home_active_class = "";
                                        away_active_class = "";
                                        active_class = "";
                                      }
                                    } else {
                                      home_active_class = "";
                                      away_active_class = "";
                                      active_class = "";
                                    }
                                  }
                                }
                              }

                              // Check if NaN
                              if (isNaN(withdraw_points_txt)) {
                                withdraw_points_txt = "-";
                                single_points_val = 0;
                              }
                              if (isNaN(away_points_txt)) {
                                away_points_txt = "-";
                                away_points = 0;
                              }
                              if (isNaN(home_points_txt)) {
                                home_points_txt = "-";
                              }
                              return (
                                <div
                                  className={
                                    "col d-flex justify-content-center " +
                                    market_odd_class +
                                    ""
                                  }
                                  key={market_index}
                                >
                                  {multi ? (
                                    <div className="win_odds clickable">
                                      {id == 2 &&
                                      home_active_class != "active" ? (
                                        <Tooltip
                                          ref={tooltipRef}
                                          openOnClick={true}
                                          opacity={1.0}
                                          style={{
                                            backgroundColor: "#ffb500",
                                            color: "#12332E",
                                            fontSize: "15px",
                                          }}
                                          id="cricket-tooltip"
                                        />
                                      ) : null}
                                      <div
                                        className={
                                          "home " +
                                          home_active_class +
                                          " " +
                                          set_odds_class +
                                          " " +
                                          liveGameClass
                                        }
                                        data-points={home_points}
                                        data-seasonid={row.season_id}
                                        data-marketid={
                                          market.master_sport_market_id
                                        }
                                        data-teamid={home_team_id}
                                        data-marketgroup={market.market_group}
                                        data-index={index}
                                        data-type="multi-card"
                                        data-win={win_odd}
                                        onClick={
                                          set_odds_class != ""
                                            ? changePoint
                                            : () => {
                                                return false;
                                              }
                                        }
                                      >
                                        {home_points_txt}{" "}
                                        {home_tick != "0" && show_tick ? (
                                          <img
                                            className="match_tick"
                                            src="/assets/icons/match_tick.svg"
                                            alt="match-tick"
                                          />
                                        ) : (
                                          " "
                                        )}
                                      </div>
                                      <div
                                        className={
                                          "away " +
                                          away_active_class +
                                          " " +
                                          set_odds_class +
                                          " " +
                                          liveGameClass
                                        }
                                        data-points={away_points}
                                        data-seasonid={row.season_id}
                                        data-marketid={
                                          market.master_sport_market_id
                                        }
                                        data-teamid={away_team_id}
                                        data-marketgroup={market.market_group}
                                        data-index={index}
                                        data-type="multi-card"
                                        data-win={win_odd}
                                        onClick={
                                          set_odds_class != ""
                                            ? changePoint
                                            : () => {
                                                return false;
                                              }
                                        }
                                      >
                                        {away_points_txt}{" "}
                                        {away_tick != "0" && show_tick ? (
                                          <img
                                            className="match_tick"
                                            src="/assets/icons/match_tick.svg"
                                            alt="match-tick"
                                          />
                                        ) : (
                                          " "
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        "draw_odds clickable " +
                                        active_class +
                                        " " +
                                        set_odds_class +
                                        " " +
                                        liveGameClass
                                      }
                                      data-points={single_points_val}
                                      data-seasonid={row.season_id}
                                      data-marketid={
                                        market.master_sport_market_id
                                      }
                                      data-teamid="0"
                                      data-index={index}
                                      data-type="multi-card"
                                      data-marketgroup={market.market_group}
                                      onClick={
                                        set_odds_class != ""
                                          ? changePoint
                                          : () => {
                                              return false;
                                            }
                                      }
                                    >
                                      {withdraw_points_txt}{" "}
                                      {single_tick != 0 && show_tick ? (
                                        <img
                                          className="match_tick"
                                          src="/assets/icons/match_tick.svg"
                                          alt="match-tick"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "game_odds  game_odds_" +
                          index +
                          " odds_col_" +
                          index +
                          ""
                        }
                      >
                        <div className="row m-0 justify-content-center">
                          <div
                            className="double_triple_points"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "150px",
                            }}
                          >
                            <span
                              className="triple_game_points"
                              data-value="triple"
                            >
                              <img
                                className={`gamecard_points ${
                                  joker_active_class +
                                  " " +
                                  joker_event_class +
                                  " " +
                                  wildCardClass
                                }`}
                                src="/assets/icons/triple.svg"
                                alt="triple-icon"
                                data-value="triple"
                                data-seasonid={row.season_id}
                                data-index={index}
                                data-type="single-card"
                                onClick={changeWildCards}
                              />
                            </span>
                            <span
                              className="double_game_points"
                              data-value="double"
                            >
                              <img
                                className={`gamecard_points ${
                                  ace_active_class +
                                  " " +
                                  ace_event_class +
                                  " " +
                                  wildCardClass
                                }`}
                                src="/assets/icons/double.svg"
                                alt="double-icon"
                                data-value="double"
                                data-seasonid={row.season_id}
                                data-index={index}
                                data-type="single-card"
                                onClick={changeWildCards}
                              />
                            </span>
                          </div>
                        </div>
                        {/* Mobile odds */}
                        {contestMarkets.map((market, market_index) => {
                          var multi = "";
                          var multi_odds =
                            row.points[market.master_sport_market_id];
                          var result =
                            row.result[market.master_sport_market_id];
                          var single_points_txt = "-";
                          var single_points_val = 0;
                          var home_points = "-";
                          var away_points = "-";
                          var active_class = "";
                          var home_active_class = "";
                          var away_active_class = "";
                          var win_odd = "";

                          var single_tick = "";
                          var home_tick = "0";
                          var away_tick = "0";
                          var master_sport_market_id =
                            market.master_sport_market_id;

                          if (market_index == 0) {
                            win_odd = "win";
                          } else {
                            win_odd = "";
                          }

                          // Check for tick
                          if (Array.isArray(result)) {
                            if (result.length > 0) {
                              single_tick = result[0];
                            } else {
                              single_tick = 0;
                            }
                          } else {
                            home_tick =
                              row.result[market.master_sport_market_id][
                                home_team_id
                              ];
                            away_tick =
                              row.result[market.master_sport_market_id][
                                away_team_id
                              ];
                          }

                          // Check for points
                          if (Array.isArray(multi_odds)) {
                            multi = false;
                            if (multi_odds.length > 0) {
                              single_points_val = multi_odds[0];
                              single_points_txt = multi_odds[0];
                            } else {
                              single_points_txt = "-";
                              single_points_val = 0;
                            }
                          } else {
                            multi = true;
                            home_points =
                              row.points[market.master_sport_market_id][
                                home_team_id
                              ];
                            away_points =
                              row.points[market.master_sport_market_id][
                                away_team_id
                              ];
                          }
                          var home_points_txt = home_points;
                          var away_points_txt = away_points;
                          var withdraw_points_txt = single_points_txt;

                          // No cricket sports predictions
                          if (id != 2) {
                            if (row.predictions.master_sport_market_id) {
                              if (
                                row.predictions.master_sport_market_id ==
                                market.master_sport_market_id
                              ) {
                                if (row.predictions.team_id == home_team_id) {
                                  home_active_class = "active";
                                  // If any of joker of ace is used
                                  if (
                                    row.is_ace_used != null &&
                                    row.is_ace_used != 0
                                  ) {
                                    home_points_txt = Number(home_points) * 2;
                                  } else if (
                                    row.is_joker_used != null &&
                                    row.is_joker_used != 0
                                  ) {
                                    home_points_txt = Number(home_points) * 3;
                                  } else {
                                    home_points_txt = Number(home_points);
                                  }
                                } else if (
                                  row.predictions.team_id == away_team_id
                                ) {
                                  away_active_class = "active";
                                  if (
                                    row.is_ace_used != null &&
                                    row.is_ace_used != 0
                                  ) {
                                    away_points_txt = Number(away_points) * 2;
                                  } else if (
                                    row.is_joker_used != null &&
                                    row.is_joker_used != 0
                                  ) {
                                    away_points_txt = Number(away_points) * 3;
                                  } else {
                                    away_points_txt = Number(away_points);
                                  }
                                } else {
                                  active_class = "active";
                                  if (
                                    row.is_ace_used != null &&
                                    row.is_ace_used != 0
                                  ) {
                                    withdraw_points_txt =
                                      Number(single_points_val) * 2;
                                  } else if (
                                    row.is_joker_used != null &&
                                    row.is_joker_used != 0
                                  ) {
                                    withdraw_points_txt =
                                      Number(single_points_val) * 3;
                                  } else {
                                    withdraw_points_txt =
                                      Number(single_points_val);
                                  }
                                }
                              } else {
                                home_active_class = "";
                                away_active_class = "";
                                active_class = "";
                              }
                            } else {
                              home_active_class = "";
                              away_active_class = "";
                              active_class = "";
                            }
                          } else {
                            // Cricket predictions
                            if (row.predictions.length == 2) {
                              if (
                                row.predictions[0].master_sport_market_id ==
                                market.master_sport_market_id
                              ) {
                                if (row.predictions[0].master_sport_market_id) {
                                  if (
                                    row.predictions[0].master_sport_market_id ==
                                    market.master_sport_market_id
                                  ) {
                                    if (
                                      row.predictions[0].team_id == home_team_id
                                    ) {
                                      home_active_class = "active";
                                      // If any of joker of ace is used
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 3;
                                      } else {
                                        home_points_txt = Number(home_points);
                                      }
                                    } else if (
                                      row.predictions[0].team_id == away_team_id
                                    ) {
                                      away_active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 3;
                                      } else {
                                        away_points_txt = Number(away_points);
                                      }
                                    } else {
                                      active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 3;
                                      } else {
                                        withdraw_points_txt =
                                          Number(single_points_val);
                                      }
                                    }
                                  } else {
                                    home_active_class = "";
                                    away_active_class = "";
                                    active_class = "";
                                  }
                                } else {
                                  home_active_class = "";
                                  away_active_class = "";
                                  active_class = "";
                                }
                              }
                              if (
                                row.predictions[1].master_sport_market_id ==
                                market.master_sport_market_id
                              ) {
                                if (row.predictions[1].master_sport_market_id) {
                                  if (
                                    row.predictions[1].master_sport_market_id ==
                                    market.master_sport_market_id
                                  ) {
                                    if (
                                      row.predictions[1].team_id == home_team_id
                                    ) {
                                      home_active_class = "active";
                                      // If any of joker of ace is used
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 3;
                                      } else {
                                        home_points_txt = Number(home_points);
                                      }
                                    } else if (
                                      row.predictions[1].team_id == away_team_id
                                    ) {
                                      away_active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 3;
                                      } else {
                                        away_points_txt = Number(away_points);
                                      }
                                    } else {
                                      active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 3;
                                      } else {
                                        withdraw_points_txt =
                                          Number(single_points_val);
                                      }
                                    }
                                  } else {
                                    home_active_class = "";
                                    away_active_class = "";
                                    active_class = "";
                                  }
                                } else {
                                  home_active_class = "";
                                  away_active_class = "";
                                  active_class = "";
                                }
                              }
                            } else {
                              if (row.predictions.length == 1) {
                                if (row.predictions[0].master_sport_market_id) {
                                  if (
                                    row.predictions[0].master_sport_market_id ==
                                    market.master_sport_market_id
                                  ) {
                                    if (
                                      row.predictions[0].team_id == home_team_id
                                    ) {
                                      home_active_class = "active";
                                      // If any of joker of ace is used
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        home_points_txt =
                                          Number(home_points) * 3;
                                      } else {
                                        home_points_txt = Number(home_points);
                                      }
                                    } else if (
                                      row.predictions[0].team_id == away_team_id
                                    ) {
                                      away_active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        away_points_txt =
                                          Number(away_points) * 3;
                                      } else {
                                        away_points_txt = Number(away_points);
                                      }
                                    } else {
                                      active_class = "active";
                                      if (
                                        row.is_ace_used != null &&
                                        row.is_ace_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 2;
                                      } else if (
                                        row.is_joker_used != null &&
                                        row.is_joker_used != 0
                                      ) {
                                        withdraw_points_txt =
                                          Number(single_points_val) * 3;
                                      } else {
                                        withdraw_points_txt =
                                          Number(single_points_val);
                                      }
                                    }
                                  } else {
                                    home_active_class = "";
                                    away_active_class = "";
                                    active_class = "";
                                  }
                                } else {
                                  home_active_class = "";
                                  away_active_class = "";
                                  active_class = "";
                                }
                              }
                            }
                          }

                          // Check if NaN
                          if (isNaN(withdraw_points_txt)) {
                            withdraw_points_txt = "-";
                            single_points_val = 0;
                          }
                          if (isNaN(away_points_txt)) {
                            away_points_txt = "-";
                            away_points = 0;
                          }
                          if (isNaN(home_points_txt)) {
                            home_points_txt = "-";
                          }
                          return (
                            <div
                              className={
                                market_index == 0
                                  ? "row m-0 mt-4"
                                  : "row m-0 mt-2"
                              }
                              key={market_index}
                            >
                              {multi ? (
                                <div className="row m-0 align-items-center">
                                  <div className="col-4">
                                    <div
                                      className={
                                        "home " +
                                        home_active_class +
                                        " " +
                                        set_odds_class +
                                        " game_odd_section" +
                                        " " +
                                        liveGameClass
                                      }
                                      data-points={home_points}
                                      data-seasonid={row.season_id}
                                      data-marketid={
                                        market.master_sport_market_id
                                      }
                                      data-teamid={home_team_id}
                                      data-index={index}
                                      data-type="single-card"
                                      data-win={win_odd}
                                      data-marketgroup={market.market_group}
                                      onClick={
                                        set_odds_class != ""
                                          ? changePoint
                                          : () => {
                                              return false;
                                            }
                                      }
                                    >
                                      {home_points_txt}{" "}
                                      {home_tick != "0" && show_tick ? (
                                        <img
                                          className="match_tick"
                                          src="/assets/icons/match_tick.svg"
                                          alt="match-tick"
                                        />
                                      ) : (
                                        " "
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="col-4 d-flex justify-content-center bold_font"
                                    style={{ textAlign: "center" }}
                                  >
                                    {market.market_name}
                                  </div>
                                  <div className="col-4">
                                    <div
                                      className={
                                        "away " +
                                        away_active_class +
                                        " " +
                                        set_odds_class +
                                        " game_odd_section" +
                                        " " +
                                        liveGameClass
                                      }
                                      data-points={away_points}
                                      data-seasonid={row.season_id}
                                      data-marketid={
                                        market.master_sport_market_id
                                      }
                                      data-teamid={away_team_id}
                                      data-marketgroup={market.market_group}
                                      data-index={index}
                                      data-type="single-card"
                                      data-win={win_odd}
                                      onClick={
                                        set_odds_class != ""
                                          ? changePoint
                                          : () => {
                                              return false;
                                            }
                                      }
                                    >
                                      {away_points_txt}{" "}
                                      {away_tick != "0" && show_tick ? (
                                        <img
                                          className="match_tick"
                                          src="/assets/icons/match_tick.svg"
                                          alt="match-tick"
                                        />
                                      ) : (
                                        " "
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="row m-0 align-items-center">
                                  <div className="col-12 d-flex align-items-center flex-column">
                                    <div className="bold_font">
                                      {id == 3 && market.market_name == "Draw"
                                        ? "Tie"
                                        : market.market_name}
                                    </div>
                                    <div
                                      className={
                                        "draw_odds clickable " +
                                        active_class +
                                        " " +
                                        set_odds_class +
                                        " game_odd_section" +
                                        " " +
                                        liveGameClass
                                      }
                                      data-points={single_points_val}
                                      data-seasonid={row.season_id}
                                      data-marketid={
                                        market.master_sport_market_id
                                      }
                                      data-teamid="0"
                                      data-index={index}
                                      data-type="single-card"
                                      data-marketgroup={market.market_group}
                                      onClick={
                                        set_odds_class != ""
                                          ? changePoint
                                          : () => {
                                              return false;
                                            }
                                      }
                                      style={{
                                        width: "70px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {withdraw_points_txt}{" "}
                                      {single_tick != 0 && show_tick ? (
                                        <img
                                          className="match_tick"
                                          src="/assets/icons/match_tick.svg"
                                          alt="match-tick"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
          <Modal
            size="xl"
            show={showModal}
            onHide={handleModalClose}
            id="modal_match_details"
          >
            <Modal.Header closeButton>
              <Modal.Title id="modal_match_title">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                defaultActiveKey={
                  lineup ||
                  substitutes?.home?.length > 0 ||
                  substitutes?.away?.length > 0
                    ? "lineup"
                    : Object.keys(teamXi).length > 0
                    ? "squad"
                    : "results"
                }
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {!lineup && Object.keys(teamXi).length > 0 ? (
                  <Tab
                    eventKey="squad"
                    title={
                      !gamecardLabels.gamecard_predicted_team
                        ? "Predicted Team"
                        : gamecardLabels.gamecard_predicted_team.label_name
                    }
                  >
                    {teamXi !== null ? (
                      playerPositionSort(Object.keys(teamXi)).map(
                        (key, keyIndex) => {
                          if (teamXi[key].length > 0) {
                            return (
                              <div
                                className="m-1"
                                style={{
                                  paddingLeft: "50px",
                                  paddingRight: "50px",
                                }}
                              >
                                <div
                                  className="row justify-content-center m-2"
                                  style={{
                                    color: "#12332E",
                                    fontFamily: "Roboto",
                                  }}
                                  key={keyIndex}
                                >
                                  <div
                                    className="col-4"
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {id == 2 &&
                                    [
                                      "Bowler",
                                      "All Rounder",
                                      "Batter",
                                    ].includes(key) ? (
                                      <img
                                        className="match_tick"
                                        src={`/assets/icons/${key
                                          .toLowerCase()
                                          .replace(" ", "")}.svg`}
                                        alt="cricket_logo"
                                      />
                                    ) : (
                                      "#"
                                    )}
                                  </div>
                                  <div
                                    className="col-6 d-flex align-items-end"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {Object.keys(teamXi).length > 1
                                      ? key
                                      : "Player Name"}
                                  </div>
                                </div>
                                <hr style={{ marginTop: "0px" }} />
                                {teamXi[key].map((player, index) => {
                                  return (
                                    <div
                                      className="row justify-content-center m-2"
                                      style={{
                                        color: "#12332E",
                                        fontFamily: "Roboto",
                                      }}
                                      key={index}
                                    >
                                      <div
                                        className="col-4"
                                        style={{ textAlign: "center" }}
                                      >
                                        {player.jersey_number}
                                      </div>
                                      <div className="col-6 d-flex">
                                        {player.player_name}
                                      </div>
                                    </div>
                                  );
                                })}
                                <br />
                              </div>
                            );
                          }
                        }
                      )
                    ) : (
                      <div className="d-flex justify-content-center">
                        Updating soon
                      </div>
                    )}
                    {}
                  </Tab>
                ) : null}
                {lineup ||
                substitutes?.home?.length > 0 ||
                substitutes?.away?.length > 0 ? (
                  <Tab eventKey="lineup" title="Predicted Lineups">
                    {lineup ? (
                      <div className="d-flex justify-content-center align-items-center">
                        {isPitchLoading ? (
                          <Spinner animation="border" variant="warning" />
                        ) : null}
                        <img
                          src={lineup}
                          width={600}
                          className="img-fluid"
                          onLoad={(e) => setPitchLoading(false)}
                          hidden={isPitchLoading}
                        />
                      </div>
                    ) : (
                      <div className="text-center p-4">
                        <h4 className="fw-bold away_percent">
                          {!gamecardLabels.gamecard_playing_squad_coming_soon_label
                            ? "Lineups will be updated soon..."
                            : gamecardLabels
                                .gamecard_playing_squad_coming_soon_label
                                .label_name}
                        </h4>
                      </div>
                    )}
                    <div className="row p-4 text-uppercase">
                      <span className="col-4 text-center substitute_team_name">
                        {substitutes?.home_team_name}
                      </span>
                      <span className="col-4 text-center substitute_title">
                        Substitutes
                      </span>
                      <span className="col-4 text-center substitute_team_name">
                        {substitutes?.away_team_name}
                      </span>
                    </div>
                    {substitutes?.home?.length > 0 ||
                    substitutes?.away?.length > 0 ? (
                      <div className="row py-2">
                        <div className="col-6">
                          <div className="row justify-content-center substitute_container_header">
                            <div className="col-4 substitute_container_header_content">
                              #
                            </div>
                            <div className="col-6 d-flex text-uppercase substitute_container_header_content">
                              Player Name
                            </div>
                          </div>
                          <hr />
                          {substitutes?.home?.map((player, index) => {
                            return (
                              <div
                                className="row justify-content-center substitute_container_header"
                                key={index}
                              >
                                <div className="col-4 text-center">
                                  {player.jersey_number}
                                </div>
                                <div className="col-6 d-flex">
                                  {player.substitute_name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-6">
                          <div className="row justify-content-center substitute_container_header">
                            <div className="col-4 substitute_container_header_content">
                              #
                            </div>
                            <div className="col-6 d-flex text-uppercase substitute_container_header_content">
                              Player Name
                            </div>
                          </div>
                          <hr />
                          {substitutes?.away?.map((player, index) => {
                            return (
                              <div
                                className="row justify-content-center substitute_container_header"
                                key={index}
                              >
                                <div className="col-4 text-center">
                                  {player.jersey_number}
                                </div>
                                <div className="col-6 d-flex">
                                  {player.substitute_name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </Tab>
                ) : null}
                <Tab eventKey="results" title="Previous Results">
                  {previousMatches !== null && previousMatches.length != 0 ? (
                    <div className="match_details_home">
                      {previousMatches.map((match, i) => {
                        var dateTimeFormat = "DD MMM YYYY";
                        var dateTimeUtc = moment.utc(match.match_date);
                        var localDate = dateTimeUtc.local();
                        var homeGamePlayTime = localDate.format(dateTimeFormat);
                        return (
                          <div
                            className="row m-2 justify-content-center"
                            key={"home_" + i}
                          >
                            <div className="col-4 d-flex align-items-center">
                              {homeGamePlayTime}
                            </div>
                            <div className="col-6">
                              <div className="match_result">
                                <div className="home">
                                  <span>{match.team1_name}</span>
                                  <span className="match_details_result">
                                    {match.team1_score}
                                  </span>
                                </div>
                                <div className="away">
                                  <span>{match.team2_name}</span>
                                  <span className="match_details_result">
                                    {match.team2_score}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      Updating soon
                    </div>
                  )}
                </Tab>
                {id != "16" &&
                id != "8" &&
                contestDetails?.contest?.league_name != "NRL" &&
                contestDetails?.contest?.league_name != "AFL" &&
                contestDetails?.contest?.league_name != "AFL PREMIERSHIP" ? (
                  <Tab eventKey="h2h" title="H2H">
                    {H2HData !== null && H2HData.length != 0 ? (
                      H2HData.map((h2h, index) => {
                        var dateTimeFormat = "DD MMM YYYY";
                        var dateTimeUtc = moment.utc(h2h.match_date);
                        var localDate = dateTimeUtc.local();
                        var H2HmatchTime = localDate.format(dateTimeFormat);
                        return (
                          <div
                            className="row m-2 justify-content-center"
                            key={index}
                          >
                            <div className="col-4 d-flex align-items-center">
                              {H2HmatchTime}
                            </div>
                            <div className="col-6">
                              <div className="match_result">
                                <div className="home">
                                  <span>{h2h.team1_name}</span>
                                  <span className="match_details_result">
                                    {h2h.team1_score}
                                  </span>
                                </div>
                                <div className="away">
                                  <span>{h2h.team2_name}</span>
                                  <span className="match_details_result">
                                    {h2h.team2_score}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="d-flex justify-content-center">
                        Updating soon
                      </div>
                    )}
                  </Tab>
                ) : (
                  ""
                )}
                <Tab eventKey="news" title="Match News">
                  {matchNews !== null && matchNews.length != 0 ? (
                    matchNews.map((row, index) => {
                      var dateTimeFormat = "DD MMM YYYY";
                      var dateTimeUtc = moment.utc(row.created_date);
                      var localDate = dateTimeUtc.local();
                      var matchNewsDate = localDate.format(dateTimeFormat);
                      return (
                        <div className="row m-2" key={index}>
                          <div className="col-3">{matchNewsDate}</div>
                          <div className="col-9">{Parser(row.news)}</div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center">
                      Updating soon
                    </div>
                  )}
                </Tab>
                {funfacts.length > 0 ? (
                  <Tab eventKey="funfacts" title="Fun Facts">
                    {funfacts !== null && funfacts.length != 0 ? (
                      funfacts.map((row, index) => {
                        return (
                          <div className="m-2 px-4 py-2" key={index}>
                            <div className="row">{row}</div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="d-flex justify-content-center">
                        Updating soon
                      </div>
                    )}
                  </Tab>
                ) : null}
                {probabilityTabContent?.stats?.length > 0 ? (
                  <Tab eventKey="stats" title="Match Stats">
                    <div className="px-4 py-2">
                      {probabilityTabContent?.stats
                        ?.sort((a, b) => (a.stat_name.startsWith("W") ? -1 : 1))
                        .map((stat, index) => {
                          let home = stat.home_value;
                          let away = stat.away_value;
                          let draw = 0;
                          const total =
                            Number(stat.home_value) + Number(stat.away_value);
                          if (stat.stat_name == "Winning Probability") {
                            draw =
                              100 -
                              Number(stat.home_value) -
                              Number(stat.away_value);
                          } else {
                            home = (home / total) * 100;
                            away = (away / total) * 100;
                          }
                          return (
                            <div className="pb-4">
                              <h6 className="text-center text-uppercase fw-bold pb-2">
                                {stat.stat_name}
                              </h6>
                              {stat.stat_name == "Winning Probability" ? (
                                <div className="row">
                                  <div className="col text-start">
                                    <span className="home_team_title text-uppercase">
                                      {
                                        probabilityTabContent?.team_details
                                          ?.home_team_name
                                      }
                                    </span>
                                  </div>
                                  <div className="col text-center">
                                    <span className="draw_title text-uppercase">
                                      Draw
                                    </span>
                                  </div>
                                  <div className="col text-end">
                                    <span className="away_team_title text-uppercase">
                                      {
                                        probabilityTabContent?.team_details
                                          ?.away_team_name
                                      }
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                              <div className="d-flex justify-content-between">
                                <h6
                                  style={{
                                    color:
                                      probabilityTabContent?.team_details
                                        ?.home_team_primary_color,
                                  }}
                                >
                                  {Number(stat.home_value)}{" "}
                                  {stat.stat_name.indexOf("%") !== -1 ||
                                  stat.stat_name == "Winning Probability"
                                    ? "%"
                                    : ""}
                                </h6>
                                <h6>
                                  {Number(draw)}{" "}
                                  {stat.stat_name.indexOf("%") !== -1 ||
                                  stat.stat_name == "Winning Probability"
                                    ? "%"
                                    : ""}
                                </h6>
                                <h6
                                  style={{
                                    color:
                                      probabilityTabContent?.team_details
                                        ?.away_team_primary_color,
                                  }}
                                >
                                  {Number(stat.away_value)}{" "}
                                  {stat.stat_name.indexOf("%") !== -1 ||
                                  stat.stat_name == "Winning Probability"
                                    ? "%"
                                    : ""}
                                </h6>
                              </div>
                              <ProgressBar>
                                <ProgressBar
                                  style={{
                                    backgroundColor:
                                      probabilityTabContent?.team_details
                                        ?.home_team_primary_color,
                                  }}
                                  now={home}
                                  key={index + 1}
                                />
                                {draw > 0 ? (
                                  <ProgressBar
                                    className="draw_progress"
                                    now={draw}
                                    key={index + 2}
                                  />
                                ) : null}
                                <ProgressBar
                                  style={{
                                    backgroundColor:
                                      probabilityTabContent?.team_details
                                        ?.away_team_primary_color,
                                  }}
                                  now={away}
                                  key={index + 3}
                                />
                              </ProgressBar>
                            </div>
                          );
                        })}
                    </div>
                  </Tab>
                ) : null}
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {anyMatchToStart ? (
        <div className="row m-0 gamecard_submit_row primary-background">
          <div className="submit_container pt-0">
            {!successStatus ? (
              <ButtonPrimary
                textValue="Save Predictions"
                classValue="btn_primary btn_submit_gamecard"
                datatype={submitType}
                onClick={submitPredictions}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
