import React, { useEffect, useState, useRef, useContext } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import Wizard from "../wizard/Wizard";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "../my-competitions/Competitions.css";
import ProfileAccount from "../account/ProfileAccount";
import { useNavigate, useParams } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SelectAnchor from "../select-anchor/SelectAnchor";
import WizardInfo from "../wizard/WizardInfo";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import HTMLReactParser from "html-react-parser";
import ApiContext from "../../providers/Api/ApiContext";

export default function GolfGameCard() {
  const { api } = useContext(ApiContext);
  const [gamecardLabels, setGamecardLabels] = useState([]);
  const navigate = useNavigate();
  const { id, uid } = useParams();
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();
  const [activeStep, setActiveStep] = useState(1);
  const [titleText, setTitleText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [player, setPlayer] = useState([]);
  const [allTournamentPlayers, setAllTournamentPlayers] = useState([]);
  const [isPlayerListOut, setIsPlayerListOut] = useState(true);
  const [isSingleDayComp, setSingleDayComp] = useState(false);
  const [defaultRoundNumber, setDefaultRoundNumber] = useState(0);
  const [allCustomPlayers, setAllCustomPlayers] = useState([]);
  const [seletedPlayerFeedId, setSelectedPlayerFeedId] = useState([]);
  const [roundPlayers, setRoundPlayers] = useState([]);
  const [contestDetails, setContestDetails] = useState({});
  const [round, setRound] = useState(0);
  const [teeTimeLabel, setTeeTimeLabel] = useState("tee_time_round_one");
  const [roundPointsLabel, setRoundPointsLabel] = useState("round_one_points");
  const [roundBonusPointsLabel, setRoundBonusPointsLabel] = useState(
    "round_one_bonus_points"
  );
  const [isMobile, setIsMobile] = useState(false);
  const [usedHelper, setUsedHelper] = useState({});
  const [totalPoints, setTotalPoints] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentRound, setCurrentRound] = useState(0);
  const [playerData, setPlayerData] = useState({});
  const [wizard, setWizard] = useState(true);
  const [playerStats, setPlayerStats] = useState(false);
  const [contestRounds, setContestRounds] = useState([]);
  const [contestStatus, setContestStatus] = useState({
    round_number: undefined,
    status: undefined,
  });
  const types = {
    "Quadruple Bogey": "#B80000",
    "Triple Bogey": "#DC7222",
    "Double Bogey": "#EDB861",
    Bogey: "#FFD99C",
    Par: "#C4C4C4",
    Birdie: "#7DC2B2",
    Eagle: "#539384",
    Albatross: "#366159",
  };
  const [leaderboardData, setLeaderboardData] = useState({
    position: 0,
    points: 0,
  });
  const [collapseOption, setCollapseOption] = useState({
    [1]: { mode: false, icon: "collapse_down" },
    [2]: { mode: false, icon: "collapse_down" },
    [3]: { mode: false, icon: "collapse_down" },
    [4]: { mode: false, icon: "collapse_down" },
    [5]: { mode: false, icon: "collapse_down" },
  });
  const [collapseStats, setcollapseStats] = useState({
    [1]: false,
    [2]: false,
    [3]: false,
    [4]: false,
  });
  useEffect(() => {
    getGamecardLabels("gamecard");
    get_tournaments_players();
    get_contest_details();

    var w = window.innerWidth;
    if (w < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    window.addEventListener("resize", () => {
      var w = window.innerWidth;
      var h = window.innerHeight;

      if (w > 991) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    });
  }, []);

  useEffect(() => {
    if (activeStep == 1) {
      const tournamentName = contestDetails["tournament_name"]
        ? contestDetails["tournament_name"]?.toUpperCase()
        : "";
      const numGolfers = isSingleDayComp
        ? " <br> SELECT 5 GOLFERS"
        : " <br> SELECT 2 GOLFERS";
      setTitleText(tournamentName + numGolfers);

      setBodyText(
        isPlayerListOut
          ? isSingleDayComp
            ? "These players are with you for round four."
            : "These players are with you throughout the tournament."
          : "<h5 className='display_name_active'>Player entries are finalised at midday, 2 days before the tournament starts. Please come back then to complete your entry.<h5>"
      );
    } else if (activeStep == 2) {
      setTitleText("SELECT ADDITIONAL 3 GOLFERS");
      setBodyText("These can be changed each day of the tournament.");
    } else if (activeStep == 3) {
      setTitleText("PLACE JOKERS & ACES");
      setBodyText(
        "Jokers and Aces can be placed on any of the golfers across the first two days. You will then get another Joker and Ace for the second two days."
      );
    }
  }, [activeStep, contestDetails, isPlayerListOut]);

  useEffect(() => {
    var result = Object.keys(player).map((key) => {
      return player[key]["player_feed_id"];
    });
    setSelectedPlayerFeedId(result);
  }, [player]);

  useEffect(() => {}, [defaultRoundNumber]);

  const get_contest_details = () => {
    var details_url = `/api/golf/gamecard/contest_details`;
    var body = {
      contest_uid: uid,
    };
    api
      .post(details_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          const round_number =
            data.tournament_status === "Not Started"
              ? 1
              : data.tournament_status === "Final"
              ? 4
              : data.tournament_status.split("-")[1].trim().toLowerCase() ===
                "play complete"
              ? Number(data.tournament_status.split("-")[0].split(" ")[1]) + 1
              : Number(data.tournament_status.split("-")[0].split(" ")[1]);
          const today = new Date().setHours(0, 0, 0, 0);
          setTotalPoints(data.total_points);
          setContestRounds(data.rounds);
          let round =
            data.tournament_status === "Not Started"
              ? 1
              : data.tournament_status === "Final"
              ? 4
              : data.tournament_status.split("-")[1].trim().toLowerCase() ===
                "play complete"
              ? Number(data.tournament_status.split("-")[0].split(" ")[1]) + 1
              : Number(data.tournament_status.split("-")[0].split(" ")[1]);
          setContestStatus({
            round_number: round,
            status:
              data.tournament_status === "Not Started"
                ? "Not Started"
                : data.tournament_status === "Final"
                ? "Final"
                : data.tournament_status.split("-")[1].trim().toLowerCase(),
          });
          if (data.rounds.filter((val) => val === true).length === 1) {
            setSingleDayComp(true);
          }
          if (data.contest_rounds.length === 4 && !isNaN(round_number)) {
            setDefaultRoundNumber(round_number - 1);
            changeTournamentround(round_number - 1);
          } else {
            if (
              data.tournament_status.toLowerCase() === "final" ||
              (data.rounds.filter((val) => val === true).length === 1 &&
                data.rounds[3])
            ) {
              setDefaultRoundNumber(data.rounds.lastIndexOf(true));
              changeTournamentround(data.rounds.lastIndexOf(true));
            } else if (
              data.tournament_status.toLowerCase() === "not started" ||
              today >=
                new Date(data.tournament_rounds[round_number - 1]).setHours(
                  0,
                  0,
                  0,
                  0
                )
            ) {
              setDefaultRoundNumber(data.rounds.indexOf(true));
              changeTournamentround(data.rounds.indexOf(true));
            } else {
              setDefaultRoundNumber(round_number - 1);
              changeTournamentround(round_number - 1);
            }
          }
          if (data.leaderboard_data.length != 0) {
            setLeaderboardData({
              position: data.leaderboard_data[0].current_rank,
              points: Number(data.leaderboard_data[0].total_score).toFixed(0),
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGamecardLabels = (page_name) => {
    var gamecard_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: page_name,
    };
    api
      .post(gamecard_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setGamecardLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToThisLeaderboardPage = (e) => {
    var path = e.currentTarget.getAttribute("data-path");
    var uid = e.currentTarget.getAttribute("data-uid");
    navigate("/golf/" + path + "/" + id + "/" + uid + "");
  };

  const changeSteps = (step = "") => {
    if (isSingleDayComp && activeStep == 1) {
      setActiveStep(activeStep + 2);
    } else {
      if (step == "") {
        var active_step = activeStep;
        if (active_step != 3) {
          if (active_step == 1 && Object.keys(player).length != 2) {
            alert_notify("error", "You must select all 2 golfers");
            return false;
          } else if (active_step == 2 && Object.keys(player).length != 5) {
            alert_notify("error", "You must select all 3 additional golfers");
            return false;
          }
          active_step++;
          setActiveStep(active_step);
        } else {
          setTournamentPlayer();
        }
      } else {
        setActiveStep(step);
      }
    }
  };

  const get_tournaments_players = () => {
    var match_teams_url = `/api/golf/gamecard/get_tournaments_players`;
    var body = {
      contest_uid: uid,
    };
    showLoader();
    api
      .post(match_teams_url, body)
      .then((response) => {
        if (response.status === 200) {
          setAllTournamentPlayers(response.data.Data);
          setAllCustomPlayers(response.data.Data);
          setIsPlayerListOut(response.data.Data?.length > 0);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const setTournamentPlayer = () => {
    const propertyValues = Object.values(player);
    var match_teams_url = `/api/golf/gamecard/set_tournaments_players`;
    if (propertyValues.length != 5) {
      alert_notify("error", "You must select all 5 golfers");
      return false;
    }
    var body = {
      players: propertyValues,
      contest_uid: uid,
    };
    showLoader();
    api
      .post(match_teams_url, body)
      .then((response) => {
        if (response.status === 200) {
          getRoundInfo(defaultRoundNumber + 1);
          setActiveStep(0);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const getRoundInfo = (round_number) => {
    var selected_url = `/api/golf/gamecard/get_rounds_info`;

    var body = {
      contest_uid: uid,
      round_number: round_number,
    };
    showLoader();
    api
      .post(selected_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          setContestDetails(data.contest_data);
          setRoundPlayers(data.players);
          setUsedHelper(data.used_helper);
          if (data.players.length == 0) {
            if (data.wizard == 0) {
              setActiveStep(1);
            } else {
              setWizard(false);
            }
          } else {
            setWizard(false);
            var players = {};
            var players_feed_id = [];
            for (var i = 0; i < data.players?.length; i++) {
              var row = data.players[i];
              if (round_number == 1) {
                var teeTimePerRound = "tee_time_round_one";
              } else if (round_number == 2) {
                var teeTimePerRound = "tee_time_round_two";
              } else if (round_number == 3) {
                var teeTimePerRound = "tee_time_round_three";
              } else if (round_number == 4) {
                var teeTimePerRound = "tee_time_round_four";
              }
              players[row.player_type] = {
                player_feed_id: row.player_feed_id,
                player_type: row.player_type,
                round: row.round,
                used_ace: row.used_ace,
                used_joker: row.used_joker,
                player_name: row.player_name,
                tee_time: row[teeTimePerRound],
                cuted: row.cuted,
                wd: row.wd,
              };
              players_feed_id.push(row.player_feed_id);
            }
            setSelectedPlayerFeedId(players_feed_id);
            setPlayer(players);
          }
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const changeTournamentround = (round) => {
    if (round == 0) {
      setTeeTimeLabel("tee_time_round_one");
      setRoundPointsLabel("round_one_points");
      setRoundBonusPointsLabel("round_one_bonus_points");
    } else if (round == 1) {
      setTeeTimeLabel("tee_time_round_two");
      setRoundPointsLabel("round_two_points");
      setRoundBonusPointsLabel("round_two_bonus_points");
    } else if (round == 2) {
      setTeeTimeLabel("tee_time_round_three");
      setRoundPointsLabel("round_three_points");
      setRoundBonusPointsLabel("round_three_bonus_points");
    } else if (round == 3) {
      setTeeTimeLabel("tee_time_round_four");
      setRoundPointsLabel("round_four_points");
      setRoundBonusPointsLabel("round_four_bonus_points");
    }
    setRound(round);
    getRoundInfo(round + 1);
  };

  const submitPredictions = () => {
    const propertyValues = Object.values(player);

    var predictions_url = `/api/golf/gamecard/golf_predictions`;

    if (propertyValues.length != 5) {
      alert_notify("error", "You must select all 5 golfers");
      return false;
    }
    var number_of_round = Number(round + 1);

    var body = {
      players: propertyValues,
      contest_uid: uid,
      round: number_of_round,
    };
    showLoader();
    api
      .post(predictions_url, body)
      .then((response) => {
        if (response.status === 200) {
          alert_notify("success", response.data.Message);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Message);
        }
        hideLoader();
      });
  };

  const changeWildCards = (e) => {
    e.stopPropagation();
    if (e.target.classList.contains("change_points")) {
      var type = e.target.getAttribute("data-value");
      var index = e.target.getAttribute("data-index");
      var cardType = e.target.getAttribute("data-type");
      var playerType = e.target.getAttribute("data-playerType");

      if (type === "double") {
        if (e.target.classList.contains("active")) {
          var playersData = player[playerType];
          playersData.used_ace = 0;
          setPlayer({ ...player, [playerType]: playersData });
          e.target.classList.remove("active");
          document
            .querySelectorAll(
              '.golf_wildcard[data-value="double"][data-type="' +
                cardType +
                '"][data-disabled="false"]'
            )
            .forEach((e) => e.classList.add("change_points"));
          var active_joker_index = document.querySelector(
            '.gamecard_points.active[data-value="triple"][data-type="' +
              cardType +
              '"]'
          );

          if (active_joker_index == null) {
            if (
              usedHelper?.joker_used.used_joker == 1 &&
              round + 1 != usedHelper?.joker_used.round
            ) {
              return true;
            }
            document
              .querySelector(
                ".game_row_" +
                  index +
                  ' .gamecard_points[data-value="triple"][data-type="' +
                  cardType +
                  '"][data-disabled="false"]'
              )
              .classList.add("change_points");
          } else {
            var el_index = active_joker_index.getAttribute("data-index");
            document
              .querySelector(
                ".game_row_" +
                  el_index +
                  ' .gamecard_points[data-value="double"][data-type="' +
                  cardType +
                  '"]'
              )
              .classList.remove("change_points");
          }
        } else {
          var playersData = player[playerType];
          playersData.used_ace = 1;
          setPlayer({ ...player, [playerType]: playersData });
          document
            .querySelectorAll(
              '.gamecard_points[data-value="double"][data-type="' +
                cardType +
                '"]'
            )
            .forEach((e) => e.classList.remove("change_points"));
          e.target.classList.add("active");
          e.target.classList.add("change_points");
          var active_joker_index = document.querySelector(
            '.change_points.active[data-value="double"][data-type="' +
              cardType +
              '"]'
          );
          if (active_joker_index == null) {
            var element = document.querySelector(
              ".game_row_" +
                index +
                ' .change_points[data-value="double"][data-type="' +
                cardType +
                '"]'
            );
            if (element != null) {
              element.classList.remove("change_points");
            }
          } else {
            var el_index = active_joker_index.getAttribute("data-index");
          }
        }
      } else {
        if (e.target.classList.contains("active")) {
          var playersData = player[playerType];
          playersData.used_joker = 0;
          setPlayer({ ...player, [playerType]: playersData });
          e.target.classList.remove("active");
          document
            .querySelectorAll(
              '.golf_wildcard[data-value="triple"][data-disabled="false"]'
            )
            .forEach((e) => e.classList.add("change_points"));
          var active_ace_index = document.querySelector(
            '.gamecard_points.active[data-value="double"][data-type="' +
              cardType +
              '"]'
          );
          if (active_ace_index == null) {
            if (
              usedHelper?.ace_used?.ace_used == 1 &&
              round + 1 != usedHelper?.ace_used?.round
            ) {
              return true;
            }
            document
              .querySelector(
                ".game_row_" +
                  index +
                  ' .gamecard_points[data-value="double"][data-type="' +
                  cardType +
                  '"]'
              )
              .classList.add("change_points");
          } else {
            var el_index = active_ace_index.getAttribute("data-index");
            document
              .querySelector(
                ".game_row_" +
                  el_index +
                  ' .gamecard_points[data-value="triple"]'
              )
              .classList.remove("change_points");
          }
        } else {
          var playersData = player[playerType];
          playersData.used_joker = 1;
          setPlayer({ ...player, [playerType]: playersData });
          document
            .querySelectorAll('.golf_wildcard[data-value="triple"]')
            .forEach((e) => e.classList.remove("change_points"));
          e.target.classList.add("active");
          e.target.classList.add("change_points");
          var active_ace_index = document.querySelector(
            '.change_points.active[data-value="triple"][data-type="' +
              cardType +
              '"]'
          );
          if (active_ace_index == null) {
            var element = document.querySelector(
              ".game_row_" +
                index +
                ' .change_points[data-value="triple"][data-type="' +
                cardType +
                '"]'
            );
            if (element != null) {
              element.classList.remove("change_points");
            }
          } else {
            var el_index = active_ace_index.getAttribute("data-index");
          }
        }
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    if (isMobile) {
      document.querySelectorAll(".m_card_container").forEach((e, i) => {
        e.classList.remove("show_modal", "active");
      });
    } else {
      document.querySelectorAll(".gamecard_odds_row").forEach((e, i) => {
        e.classList.remove("show_modal", "active");
      });
    }
  };

  const getPlayerStats = (playerFeedId) => {
    var predictions_url = `/api/golf/gamecard/get_player_stats`;
    var body = {
      player_feed_id: playerFeedId,
      contest_uid: uid,
    };
    showLoader();
    api
      .post(predictions_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          if (data.player_data.length != 0) {
            setPlayerData({
              name: data.player_data[0].player_name,
              country: data.player_data[0].country,
            });
          } else {
            setPlayerData({ name: "", country: "" });
          }
          setShowModal(true);
          setPlayerStats(data.player_stats);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !gamecardLabels.gamecard_my_competitions
            ? "My Competitions"
            : gamecardLabels.gamecard_my_competitions.label_name
        }
      />
      <div className="gamecard_container primary-background">
        <div
          className={
            activeStep != 0 && wizard
              ? "page_content_center d-flex justify-content-center"
              : "page_content_center"
          }
        >
          {activeStep != 0 && wizard ? (
            <div
              className="d-flex"
              style={{
                width: "100%",
                maxWidth: "700px",
                flexDirection: "column",
              }}
            >
              <Wizard
                activeStep={activeStep}
                changeSteps={changeSteps}
                isSingleDayComp={isSingleDayComp}
              />
              <WizardInfo titleText={titleText} bodyText={bodyText} />
              <div className="row m-0 mt-5">
                {activeStep == 1 ? (
                  <>
                    <SelectAnchor
                      classValue="col-6"
                      playerType="1"
                      setPlayer={setPlayer}
                      contestUid={uid}
                      player={player}
                      playersData={allTournamentPlayers}
                      allCustomPlayers={allCustomPlayers}
                      setTournamentPlayer={setTournamentPlayer}
                      setAllCustomPlayers={setAllCustomPlayers}
                      contestDetails={contestDetails}
                      currentRound={contestStatus.round_number}
                      disabled={!isPlayerListOut}
                      textValue={"Player #1"}
                    />
                    <SelectAnchor
                      classValue="col-6"
                      playerType="2"
                      setPlayer={setPlayer}
                      contestUid={uid}
                      player={player}
                      playersData={allTournamentPlayers}
                      allCustomPlayers={allCustomPlayers}
                      setTournamentPlayer={setTournamentPlayer}
                      setAllCustomPlayers={setAllCustomPlayers}
                      contestDetails={contestDetails}
                      currentRound={contestStatus.round_number}
                      disabled={!isPlayerListOut}
                      textValue={"Player #2"}
                    />
                    {isSingleDayComp ? (
                      <>
                        <SelectAnchor
                          classValue="col-6 pt-3"
                          playerType="3"
                          setPlayer={setPlayer}
                          contestUid={uid}
                          player={player}
                          playersData={allTournamentPlayers}
                          allCustomPlayers={allCustomPlayers}
                          setTournamentPlayer={setTournamentPlayer}
                          setAllCustomPlayers={setAllCustomPlayers}
                          currentRound={contestStatus.round_number}
                          contestDetails={contestDetails}
                          textValue={"Player #3"}
                        />
                        <SelectAnchor
                          classValue="col-6 pt-3"
                          playerType="4"
                          setPlayer={setPlayer}
                          contestUid={uid}
                          player={player}
                          playersData={allTournamentPlayers}
                          allCustomPlayers={allCustomPlayers}
                          setTournamentPlayer={setTournamentPlayer}
                          setAllCustomPlayers={setAllCustomPlayers}
                          currentRound={contestStatus.round_number}
                          contestDetails={contestDetails}
                          textValue={"Player #4"}
                        />
                        <div className="col-12 pt-3 d-flex justify-content-center">
                          <SelectAnchor
                            classValue="col-6"
                            playerType="5"
                            setPlayer={setPlayer}
                            contestUid={uid}
                            player={player}
                            playersData={allTournamentPlayers}
                            allCustomPlayers={allCustomPlayers}
                            setTournamentPlayer={setTournamentPlayer}
                            setAllCustomPlayers={setAllCustomPlayers}
                            currentRound={contestStatus.round_number}
                            contestDetails={contestDetails}
                            textValue={"Player #5"}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ) : activeStep == 2 ? (
                  <>
                    <SelectAnchor
                      classValue="col-4"
                      playerType="3"
                      setPlayer={setPlayer}
                      contestUid={uid}
                      player={player}
                      playersData={allTournamentPlayers}
                      allCustomPlayers={allCustomPlayers}
                      setTournamentPlayer={setTournamentPlayer}
                      setAllCustomPlayers={setAllCustomPlayers}
                      currentRound={contestStatus.round_number}
                      contestDetails={contestDetails}
                      textValue={"Player #3"}
                    />
                    <SelectAnchor
                      classValue="col-4"
                      playerType="4"
                      setPlayer={setPlayer}
                      contestUid={uid}
                      player={player}
                      playersData={allTournamentPlayers}
                      allCustomPlayers={allCustomPlayers}
                      setTournamentPlayer={setTournamentPlayer}
                      setAllCustomPlayers={setAllCustomPlayers}
                      currentRound={contestStatus.round_number}
                      contestDetails={contestDetails}
                      textValue={"Player #4"}
                    />
                    <SelectAnchor
                      classValue="col-4"
                      playerType="5"
                      setPlayer={setPlayer}
                      contestUid={uid}
                      player={player}
                      playersData={allTournamentPlayers}
                      allCustomPlayers={allCustomPlayers}
                      setTournamentPlayer={setTournamentPlayer}
                      setAllCustomPlayers={setAllCustomPlayers}
                      currentRound={contestStatus.round_number}
                      contestDetails={contestDetails}
                      textValue={"Player #5"}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 d-flex justify-content-end mt-5 p-0">
                <ButtonPrimary
                  textValue={activeStep == 3 ? "FINISH" : "NEXT"}
                  classValue={`btn_primary`}
                  onClick={() => changeSteps("")}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="row gamecard_info m-0 mt-5">
                <div className="col-xl-8 col-lg-8 col-12 p-0">
                  <div className="d-inline-flex align-items-center justify-content-between gamecard_tab competition_tab active_tab ">
                    <span
                      className="gamecard_title text-white"
                      style={{ textTransform: "uppercase" }}
                    >
                      {!gamecardLabels.gamecard_gamecard_label
                        ? "Gamecard"
                        : gamecardLabels.gamecard_gamecard_label.label_name}
                    </span>
                    <span
                      className="gamecard_icon m-0"
                      data-path="gamecard"
                      data-uid={uid}
                    >
                      <img
                        src="/assets/icons/gamecard.svg"
                        alt="gamecard-icon"
                      />
                    </span>
                  </div>
                  <div
                    className="d-inline-flex align-items-center justify-content-between leaderboard_tab competition_tab"
                    data-path="leaderboard"
                    data-uid={uid}
                    onClick={goToThisLeaderboardPage}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="gamecard_title text-white"
                      style={{ textTransform: "uppercase" }}
                    >
                      {!gamecardLabels.gamecard_leaderboard_label
                        ? "Leaderboard"
                        : gamecardLabels.gamecard_leaderboard_label.label_name}
                    </span>
                    <span className="gamecard_icon m-0">
                      <img
                        src="/assets/icons/leaderboard.svg"
                        alt="leaderboard-icon"
                      />
                    </span>
                  </div>
                  <span className="gamecard_competition text-white">
                    Competition {uid} ({contestDetails?.tournament_name})
                  </span>
                  <div className="row price_container m-0 mt-3"> </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-12 gamecard_free_to_play">
                  <span className="free_to_play_title text-white">
                    {Number(contestDetails?.entry_fee)
                      ? "ENTRY FEE: €" + contestDetails?.entry_fee
                      : "FREE TO PLAY"}
                  </span>
                  <div className="gamecard_positions">
                    <span className="d-block">
                      <div className="row">
                        <div className="col-10 text-white">
                          Leaderboard Position:
                        </div>
                        <div className="col-2 d-flex justify-content-end text-white">
                          {leaderboardData.position
                            ? leaderboardData.position
                            : "-"}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              {contestStatus.status === "suspended" ? (
                <div
                  className="p-2 w-full d-flex justify-content-center align-items-center"
                  style={{ backgroundColor: "#ffb500" }}
                >
                  <span className="fs-6 fw-bold">
                    {`Play has been suspended for round ${contestStatus.round_number}`}
                  </span>
                </div>
              ) : null}
              <div
                className="row align-items-center points_container"
                style={{ marginTop: "40px" }}
              >
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row rounds">
                    {contestRounds.map((round, index) => {
                      if (round) {
                        return (
                          <div className="col-3">
                            <span
                              className={
                                round == index
                                  ? "active text-white"
                                  : "text-white"
                              }
                              onClick={() => changeTournamentround(index)}
                            >
                              {" "}
                              Round {index + 1 + " "}
                            </span>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end">
                  <div className={"triple_points col-6"}>
                    <span>
                      <img
                        className="triple_points_icon"
                        src="/assets/icons/triple.svg"
                        alt="tiple-points-icon"
                      />
                    </span>
                    <span
                      style={{ textTransform: "uppercase", textAlign: "start" }}
                    >
                      {!gamecardLabels.gamecard_triple_points_label
                        ? "Triple your points"
                        : gamecardLabels.gamecard_triple_points_label
                            .label_name}
                    </span>
                  </div>
                  <div className={"double_points col-6"}>
                    <span>
                      <img
                        className="double_points_icon"
                        src="/assets/icons/double.svg"
                        alt="double-points-icon"
                      />
                    </span>
                    <span
                      style={{ textTransform: "uppercase", textAlign: "start" }}
                    >
                      {!gamecardLabels.gamecard_double_points_label
                        ? "Double your points"
                        : gamecardLabels.gamecard_double_points_label
                            .label_name}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row gamecard_odds_types m-0 pt-3 pb-3">
                <div className="col-2">
                  {contestDetails?.start_at
                    ? moment(
                        contestDetails.start_at,
                        "YYYY-MM-DD HH:mm"
                      ).format("DD MMMM YYYY")
                    : ""}
                </div>
                <div className="col-6"></div>
                <div className="col-2 text-center">
                  {" "}
                  {"R" + (round + 1)} Points
                </div>
                <div className="col-2 text-center"> Total Points</div>
              </div>
              {roundPlayers.length != 0 ? (
                roundPlayers.map((row, i) => {
                  var total =
                    Number(row.round_one_points) +
                    Number(row.round_two_points) +
                    Number(row.round_three_points) +
                    Number(row.round_four_points);
                  var disabled_select =
                    row.player_type == 1 || row.player_type == 2;
                  var disabled_class =
                    row.player_type == 1 || row.player_type == 2
                      ? "disabled_select"
                      : "";
                  var disabled_wildcard = false;
                  var teed_off = false;
                  var startTime = "";
                  var current_date = moment().format("YYYY-MM-DD");
                  var round_date = moment(
                    contestDetails?.start_at,
                    "YYYY-MM-DD HH:mm"
                  )
                    .add(round, "days")
                    .format("YYYY-MM-DD");
                  var tournamentPlayerType = row.player_type;
                  var is_ace_used = usedHelper?.ace_used?.ace_used;
                  var is_joker_used = usedHelper?.joker_used?.used_joker;
                  var joker = row.used_joker;
                  var ace = row.used_ace;
                  var joker_active_class = "";
                  var joker_event_class = "";
                  var ace_event_class = "";
                  var ace_active_class = "";
                  var point = row[roundPointsLabel];
                  var bonus_point = row[roundBonusPointsLabel];
                  if (is_ace_used != 0) {
                    if (ace == 1) {
                      ace_active_class = "active";
                      ace_event_class = "change_points";

                      joker_active_class = "";
                      joker_event_class = "";
                      point =
                        (Number(point) - Number(bonus_point)) * 2 +
                        Number(bonus_point);
                    } else {
                      ace_active_class = "";
                      ace_event_class = "";
                    }
                  } else {
                    ace_active_class = "";
                    if (joker == 1) {
                      ace_event_class = "";
                    } else {
                      ace_event_class = "change_points";
                    }
                  }

                  if (is_joker_used != 0) {
                    if (joker == 1) {
                      joker_active_class = "active";
                      joker_event_class = "change_points";

                      ace_active_class = "";
                      ace_event_class = "";
                      point =
                        (Number(point) - Number(bonus_point)) * 3 +
                        Number(bonus_point);
                    } else {
                      joker_active_class = "";
                      joker_event_class = "";
                    }
                  } else {
                    joker_active_class = "";
                    if (ace == 1) {
                      joker_event_class = "";
                    } else {
                      joker_event_class = "change_points";
                    }
                  }

                  if (
                    current_date > round_date &&
                    round + 1 !== contestStatus.round_number
                  ) {
                    startTime = "Finished";
                    disabled_select = true;
                    disabled_class = "disabled_select";
                    disabled_wildcard = true;
                    teed_off = true;
                  } else {
                    if (
                      player[row.player_type]?.tee_time == null ||
                      player[row.player_type]?.tee_time == ""
                    ) {
                      startTime = !gamecardLabels.golf_gamecard_not_decided
                        ? "Not decided yet"
                        : gamecardLabels.golf_gamecard_not_decided.label_name;
                      if (
                        round == 0 &&
                        (row.player_type == 1 || row.player_type == 2)
                      ) {
                        disabled_select = false;
                        disabled_class = "";
                        disabled_wildcard = false;
                      }
                    } else if (
                      player[row.player_type]?.tee_time.indexOf(":") > -1
                    ) {
                      var localTime = moment
                        .utc(player[row.player_type]?.tee_time, "hh:mm A")
                        .local();
                      startTime = localTime.format("hh:mm A");
                      var currentTimeUnix = moment().utc().local().unix();
                      var currentTimeAdd15 = moment()
                        .add(15, "minutes")
                        .utc()
                        .local()
                        .unix();
                      if (
                        currentTimeUnix > localTime.unix() &&
                        contestStatus.status !== "Not Started"
                      ) {
                        disabled_select = true;
                        disabled_class = "disabled_select";
                        disabled_wildcard = true;
                        teed_off = true;
                      }
                      if (
                        round == 0 &&
                        (row.player_type == 1 || row.player_type == 2)
                      ) {
                        if (currentTimeAdd15 <= localTime.unix()) {
                          disabled_select = false;
                          disabled_class = "";
                          disabled_wildcard = false;
                        }
                      }
                    } else if (player[row.player_type]?.tee_time == "WD") {
                      disabled_select = false;
                      disabled_class = "";
                      disabled_wildcard = false;
                      startTime = player[row.player_type]?.tee_time;
                    } else {
                      disabled_select = true;
                      disabled_class = "disabled_select";
                      disabled_wildcard = true;
                      startTime = player[row.player_type]?.tee_time;
                      teed_off = true;
                    }

                    if (
                      player[row.player_type]?.cuted == 1 &&
                      row.player_type != 1 &&
                      row.player_type != 2
                    ) {
                      disabled_select = false;
                      disabled_class = "";
                      disabled_wildcard = false;
                      startTime = "Cut";
                    } else if (player[row.player_type]?.cuted == 1) {
                      startTime = "Cut";
                    }
                    if (
                      player[row.player_type]?.wd == 1 &&
                      row.player_type != 1 &&
                      row.player_type != 2
                    ) {
                      disabled_select = false;
                      disabled_class = "";
                      disabled_wildcard = false;
                      startTime = "WD";
                    } else if (player[row.player_type]?.wd == 1) {
                      startTime = "WD";
                    }
                  }
                  if (disabled_wildcard) {
                    joker_event_class = "";
                    ace_event_class = "";
                  }
                  var player_name =
                    player[row.player_type]?.cuted == 1
                      ? player[row.player_type]?.player_name + " (Cut) "
                      : player[row.player_type]?.wd == 1
                      ? player[row.player_type]?.player_name + " (WD) "
                      : player[row.player_type]?.player_name;
                  if (player[row.player_type]?.cuted == 1) {
                    var player_name =
                      player[row.player_type]?.player_name + " (Cut) ";
                  } else if (player[row.player_type]?.wd == 1) {
                    var player_name =
                      player[row.player_type]?.player_name + " (WD) ";
                  } else if (teed_off) {
                    var player_name =
                      player[row.player_type]?.player_name + " (Tee'd Off) ";
                  } else {
                    var player_name = player[row.player_type]?.player_name;
                  }
                  if (isMobile) {
                    return (
                      <div
                        className={
                          "row bg-white m_card_container mb-2 game_row_" + i
                        }
                        key={i}
                        onClick={(e) => {
                          e.stopPropagation();
                          document
                            .querySelectorAll(".m_card_container")
                            .forEach((e, i) => {
                              e.classList.remove("show_modal", "active");
                            });
                          e.currentTarget.classList.add("show_modal");
                          getPlayerStats(
                            player[row.player_type]?.player_feed_id
                          );
                        }}
                      >
                        <div className="col-12 m_player_time p-2">
                          {startTime}
                        </div>
                        <div
                          className="col-12 m_player"
                          data-playertype={tournamentPlayerType}
                          data-mode="close"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (disabled_select) {
                              return false;
                            }
                            var mode = e.target.getAttribute("data-mode");
                            if (mode == "close") {
                              setCollapseOption({
                                ...collapseOption,
                                [tournamentPlayerType]: {
                                  mode: true,
                                  icon: "collapse_up",
                                },
                              });
                              e.target.setAttribute("data-mode", "open");
                            } else {
                              setCollapseOption({
                                ...collapseOption,
                                [tournamentPlayerType]: {
                                  mode: false,
                                  icon: "collapse_down",
                                },
                              });
                              e.target.setAttribute("data-mode", "close");
                            }
                          }}
                        >
                          {player_name
                            ? HTMLReactParser(player_name)
                            : player_name}
                          {disabled_select ? (
                            ""
                          ) : (
                            <img
                              className="player_icon"
                              src={`/assets/icons/${collapseOption[tournamentPlayerType].icon}.svg`}
                            />
                          )}
                        </div>
                        <div
                          className={
                            collapseOption[tournamentPlayerType].mode
                              ? "col-12"
                              : "col-12 d-none"
                          }
                        >
                          {allTournamentPlayers.map((row2, i) => {
                            var name =
                              row2.cuted == 1
                                ? row2.player_name + " (Cut) "
                                : row2.wd == 1
                                ? row2.player_name + " (WD) "
                                : row2.player_name;
                            return (
                              <div
                                className={
                                  seletedPlayerFeedId.includes(
                                    row2.player_feed_id
                                  ) ||
                                  row2.cuted == 1 ||
                                  row2.wd == 1
                                    ? "d-none col-12"
                                    : "col-12"
                                }
                                key={i}
                                style={{ padding: "5px 13px 5px 13px" }}
                                data-playerfeedid={row2.player_feed_id}
                                data-playertype={tournamentPlayerType}
                                data-teetime={row2[teeTimeLabel]}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  var player_feed_id =
                                    e.target.getAttribute("data-playerfeedid");
                                  var playerType =
                                    e.target.getAttribute("data-playertype");
                                  var tee_time =
                                    e.target.getAttribute("data-teetime");
                                  if (tee_time == null || tee_time == "") {
                                    startTime =
                                      !gamecardLabels.golf_gamecard_not_decided
                                        ? "Not decided yet"
                                        : gamecardLabels
                                            .golf_gamecard_not_decided
                                            .label_name;
                                  } else if (tee_time.indexOf(":") > -1) {
                                    var localTime = moment
                                      .utc(
                                        round_date + " " + tee_time,
                                        "YYYY-MM-DD hh:mm A"
                                      )
                                      .local();
                                    startTime = localTime.format("hh:mm A");
                                    var currentTimeUnix = moment()
                                      .utc()
                                      .local()
                                      .unix();
                                    if (currentTimeUnix > localTime.unix()) {
                                      alert_notify(
                                        "error",
                                        "The player has started to play"
                                      );
                                      return false;
                                    }
                                  } else if (tee_time == "WD") {
                                    startTime = "WD";
                                  } else {
                                    alert_notify(
                                      "error",
                                      "The player has started to play"
                                    );
                                    return false;
                                  }
                                  var player_name = e.target.textContent;
                                  var playerData = player[playerType];
                                  playerData.player_feed_id = player_feed_id;
                                  playerData.player_name = player_name;
                                  playerData.tee_time = tee_time;
                                  playerData.cuted = row2.cuted;
                                  playerData.wd = row2.wd;
                                  setPlayer({
                                    ...player,
                                    [playerType]: playerData,
                                  });
                                  setCollapseOption({
                                    ...collapseOption,
                                    [tournamentPlayerType]: {
                                      mode: false,
                                      icon: "collapse_down",
                                    },
                                  });
                                }}
                              >
                                {HTMLReactParser(name)}
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className={
                            !collapseOption[tournamentPlayerType].mode
                              ? "golf_m_wildcard col-12 d-flex justify-content-center align-items-center"
                              : "d-none"
                          }
                        >
                          <img
                            className={`gamecard_points golf_wildcard ${joker_event_class} ${joker_active_class} wildcards_${round}`}
                            src="/assets/icons/triple.svg"
                            alt="triple-icon"
                            data-value="triple"
                            data-index={i}
                            data-playertype={tournamentPlayerType}
                            data-round={row.round}
                            data-type="multi-card"
                            onClick={changeWildCards}
                            data-disabled={disabled_wildcard}
                          />
                          <img
                            className={`gamecard_points golf_wildcard ${ace_event_class} ${ace_active_class} wildcards_${round}`}
                            src="/assets/icons/double.svg"
                            alt="triple-icon"
                            data-value="double"
                            data-index={i}
                            data-playertype={tournamentPlayerType}
                            data-round={row.round}
                            data-type="multi-card"
                            onClick={changeWildCards}
                            data-disabled={disabled_wildcard}
                          />
                          <button className="btn-scoreboard">
                            {" "}
                            Scorecard{" "}
                          </button>
                        </div>
                        <div
                          className={
                            !collapseOption[tournamentPlayerType].mode
                              ? "col-12 m_round_points pb-1"
                              : "d-none"
                          }
                        >
                          <div className="row">
                            <div
                              className="col-6"
                              style={{ fontWeight: "bold" }}
                            >
                              {"R" + (round + 1)} Points
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                              {point}{" "}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !collapseOption[tournamentPlayerType].mode
                              ? "col-12 m_round_points pt-0 pb-4"
                              : "d-none"
                          }
                        >
                          <div className="row">
                            <div
                              className="col-6"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              Total Points{" "}
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                              {totalPoints[tournamentPlayerType]}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={
                          "row gamecard_odds_row  m-0 mt-3 pt-3 pb-3 game_row_" +
                          (i + round)
                        }
                        key={i}
                        onClick={(e) => {
                          e.stopPropagation();
                          document
                            .querySelectorAll(".gamecard_odds_row")
                            .forEach((e, i) => {
                              e.classList.remove("show_modal", "active");
                            });
                          e.currentTarget.classList.add("show_modal");
                          getPlayerStats(
                            player[row.player_type]?.player_feed_id
                          );
                        }}
                      >
                        <div className={`col-2 text-center start_${round}`}>
                          {/* If round is suspended the show 0 holes played for players who not tee'd off and show holes played for tee'd off players */}
                          {contestStatus.status == "suspended" &&
                          startTime.includes(":")
                            ? "0"
                            : startTime}
                        </div>
                        <div className="col-4">
                          <div className="row">
                            <div className="col-9">
                              <select
                                className={`form-select ${disabled_class}`}
                                value={player[row.player_type]?.player_feed_id}
                                data-playertype={row.player_type}
                                data-round={row.round}
                                disabled={disabled_select}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  var playerType =
                                    e.target.getAttribute("data-playertype");
                                  let index = e.target.selectedIndex;
                                  let optionElement =
                                    e.target.childNodes[index];
                                  let tee_time =
                                    optionElement.getAttribute("data-teetime");
                                  let cutted =
                                    optionElement.getAttribute("data-cuted");
                                  let wd =
                                    optionElement.getAttribute("data-wd");

                                  if (tee_time == null || tee_time == "") {
                                    startTime =
                                      !gamecardLabels.golf_gamecard_not_decided
                                        ? "Not decided yet"
                                        : gamecardLabels
                                            .golf_gamecard_not_decided
                                            .label_name;
                                  } else if (tee_time.indexOf(":") > -1) {
                                    var localTime = moment
                                      .utc(
                                        round_date + " " + tee_time,
                                        "YYYY-MM-DD hh:mm A"
                                      )
                                      .local();
                                    startTime = localTime.format("hh:mm A");
                                    var currentTimeUnix = moment()
                                      .utc()
                                      .local()
                                      .unix();
                                    if (currentTimeUnix > localTime.unix()) {
                                      alert_notify(
                                        "error",
                                        "The player has started to play"
                                      );
                                      return false;
                                    }
                                  } else if (tee_time == "WD") {
                                    startTime = "WD";
                                  } else {
                                    alert_notify(
                                      "error",
                                      "The player has started to play"
                                    );
                                    return false;
                                  }
                                  var playerData = player[playerType];
                                  playerData.player_feed_id = e.target.value;
                                  playerData.tee_time = tee_time;
                                  playerData.cuted = cutted;
                                  playerData.wd = wd;
                                  setPlayer({
                                    ...player,
                                    [playerType]: playerData,
                                  });
                                }}
                              >
                                {allTournamentPlayers.map((row2, i) => {
                                  var disabled = false;
                                  if (
                                    row2[teeTimeLabel] == null ||
                                    row2[teeTimeLabel] == ""
                                  ) {
                                    disabled = false;
                                  } else if (
                                    contestStatus.status == "Not Started"
                                  ) {
                                    disabled = false;
                                  } else if (
                                    row2[teeTimeLabel].indexOf(":") > -1
                                  ) {
                                    var localTime = moment
                                      .utc(row2[teeTimeLabel], "hh:mm A")
                                      .local();
                                    startTime = localTime.format("hh:mm A");
                                    var currentTimeUnix = moment()
                                      .utc()
                                      .local()
                                      .unix();
                                    if (currentTimeUnix > localTime.unix()) {
                                      disabled = true;
                                    }
                                  } else if (row2[teeTimeLabel] == "WD") {
                                    disabled = false;
                                  } else {
                                    disabled = true;
                                  }
                                  var name =
                                    row2.cuted == 1
                                      ? row2.player_name + " (Cut) "
                                      : row2.wd == 1
                                      ? row2.player_name + " (WD) "
                                      : row2.player_name;
                                  if (row2.cuted == 1) {
                                    var name = row2.player_name + " (Cut) ";
                                  } else if (row2.wd == 1) {
                                    var name = row2.player_name + " (WD) ";
                                  } else if (disabled) {
                                    var name =
                                      row2.player_name + " (Tee'd Off) ";
                                  } else {
                                    var name = row2.player_name;
                                  }
                                  return (
                                    <option
                                      value={row2.player_feed_id}
                                      data-teetime={row2[teeTimeLabel]}
                                      data-cuted={row2.cuted}
                                      data-wd={row2.wd}
                                      disabled={
                                        seletedPlayerFeedId.includes(
                                          row2.player_feed_id
                                        ) ||
                                        row2.cuted == 1 ||
                                        disabled ||
                                        row2.wd == 1
                                      }
                                      key={i}
                                    >
                                      {HTMLReactParser(name)}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-3">
                              <button className="btn-scoreboard">
                                {" "}
                                Scorecard{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                          <img
                            className={`gamecard_points golf_wildcard ${joker_event_class} ${joker_active_class} wildcards_${round}`}
                            src="/assets/icons/triple.svg"
                            alt="triple-icon"
                            data-value="triple"
                            data-index={i}
                            data-playertype={tournamentPlayerType}
                            data-round={row.round}
                            data-type="multi-card"
                            onClick={changeWildCards}
                            data-disabled={disabled_wildcard}
                          />
                          <img
                            className={`gamecard_points golf_wildcard ${ace_event_class} ${ace_active_class} wildcards_${round}`}
                            src="/assets/icons/double.svg"
                            alt="double-icon"
                            data-value="double"
                            data-index={i}
                            data-playertype={tournamentPlayerType}
                            data-round={row.round}
                            data-type="multi-card"
                            onClick={changeWildCards}
                            data-disabled={disabled_wildcard}
                          />
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                          {" "}
                          {point}{" "}
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                          {" "}
                          {totalPoints[tournamentPlayerType]}{" "}
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div className="row gamecard_odds_row m-0 mt-3">
                  {" "}
                  You can not play in this round{" "}
                </div>
              )}
              <div className="col-12 mt-3 btn_submit_golf_predictions">
                <ButtonPrimary
                  textValue={"SUBMIT"}
                  classValue={`btn_primary `}
                  onClick={submitPredictions}
                />
              </div>
            </>
          )}
          {!isMobile ? (
            <Modal
              show={showModal}
              centered
              className="golfModal"
              onHide={handleClose}
            >
              <div
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "13px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <img
                  src="/assets/icons/close.svg"
                  style={{ height: "18px", width: "18px" }}
                  alt="check-icon"
                />
              </div>
              <Modal.Header>
                <Modal.Title style={{ borderBottom: 0 }}>
                  <div className="col-12 golf-stats-player-name">
                    {playerData?.name
                      ? HTMLReactParser(playerData?.name)
                      : playerData?.name}
                  </div>
                  <div className="col-12 golf-stats-player-country">
                    {playerData?.country}
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {Object.keys(playerStats).length != 0
                  ? Object.keys(playerStats).map((key) => {
                      return (
                        <div className="golf-stats-container mb-3">
                          <div
                            className="row stats-round"
                            style={{ paddingLeft: "30px" }}
                          >
                            Round {key}
                          </div>
                          <div className="row stats-round mt-2">
                            <div
                              className="row hole-row"
                              style={{ padding: "8px", textAlign: "center" }}
                            >
                              <div className="col-1"> HOLE </div>
                              <div className="col"> 1 </div>
                              <div className="col"> 2 </div>
                              <div className="col"> 3 </div>
                              <div className="col"> 4 </div>
                              <div className="col"> 5 </div>
                              <div className="col"> 6 </div>
                              <div className="col"> 7 </div>
                              <div className="col"> 8 </div>
                              <div className="col"> 9 </div>
                              <div className="col"> 10 </div>
                              <div className="col"> 11 </div>
                              <div className="col"> 12 </div>
                              <div className="col"> 13 </div>
                              <div className="col"> 14 </div>
                              <div className="col"> 15 </div>
                              <div className="col"> 16 </div>
                              <div className="col"> 17 </div>
                              <div className="col"> 18 </div>
                            </div>
                            <div
                              className="row m-0"
                              style={{ padding: "8px", textAlign: "center" }}
                            >
                              <div className="col-1"> PAR </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][1]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][1]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][1]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][2]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][2]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][2]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][3]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][3]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][3]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][4]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][4]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][4]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][5]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][5]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][5]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][6]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][6]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][6]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][7]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][7]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][7]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][8]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][8]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][8]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][9]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][9]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][9]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][10]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][10]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][10]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][11]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][11]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][11]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][12]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][12]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][12]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][13]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][13]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][13]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][14]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][14]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][14]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][15]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][15]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][15]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][16]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][16]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][16]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][17]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][17]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][17]?.par}{" "}
                                </span>
                              </div>
                              <div className="col">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][18]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][18]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][18]?.par}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <div
                  className="row mt-4 mb-4"
                  style={{ borderBottom: "1px solid black" }}
                ></div>
                <div className="row golf-stats">
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#366159",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Albatross &gt;{" "}
                  </div>
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#539384",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Eagle
                  </div>
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#7DC2B2",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Birdie
                  </div>
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#C4C4C4",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Par
                  </div>
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#FFD99C",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Bogey
                  </div>
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#EDB861",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Double Bogey
                  </div>
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#DC7222",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Triple Bogey
                  </div>
                  <div className="result-info">
                    {" "}
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        display: "inline-block",
                        background: "#B80000",
                        marginRight: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      {" "}
                    </div>{" "}
                    Quadruple Bogey &lt;{" "}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          ) : (
            <Modal
              show={showModal}
              centered
              className="golfModal"
              onHide={handleClose}
            >
              <div
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "13px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <img
                  src="/assets/icons/close.svg"
                  style={{ height: "18px", width: "18px" }}
                  alt="check-icon"
                />
              </div>
              <Modal.Header>
                <Modal.Title style={{ borderBottom: 0 }}>
                  <div className="col-12 golf-stats-player-name">
                    {playerData?.name
                      ? HTMLReactParser(playerData?.name)
                      : playerData?.name}
                  </div>
                  <div className="col-12 golf-stats-player-country">
                    {playerData?.country}
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {Object.keys(playerStats).length != 0
                  ? Object.keys(playerStats).map((key) => {
                      return (
                        <div className={"row m-golf-stats-container m-0 mb-2"}>
                          <div
                            className={
                              collapseStats[key]
                                ? "col-12 text-center m-round active"
                                : "col-12 text-center m-round"
                            }
                            data-mode="close"
                            onClick={(e) => {
                              var mode = e.target.getAttribute("data-mode");
                              if (mode == "close") {
                                setcollapseStats({
                                  ...collapseStats,
                                  [key]: true,
                                });
                                e.target.setAttribute("data-mode", "open");
                              } else {
                                setcollapseStats({
                                  ...collapseStats,
                                  [key]: false,
                                });
                                e.target.setAttribute("data-mode", "close");
                              }
                            }}
                          >
                            Round {key}
                          </div>
                          <div
                            className={
                              collapseStats[key] ? "row mt-3 mb-3 " : "d-none"
                            }
                          >
                            <div className="col-6 text-center fit-container">
                              <div className="col-12"> HOLE </div>
                              <div className="col-12"> 1 </div>
                              <div className="col-12"> 2 </div>
                              <div className="col-12"> 3 </div>
                              <div className="col-12"> 4 </div>
                              <div className="col-12"> 5 </div>
                              <div className="col-12"> 6 </div>
                              <div className="col-12"> 7 </div>
                              <div className="col-12"> 8 </div>
                              <div className="col-12"> 9 </div>
                              <div className="col-12"> 10 </div>
                              <div className="col-12"> 11 </div>
                              <div className="col-12"> 12 </div>
                              <div className="col-12"> 13 </div>
                              <div className="col-12"> 14 </div>
                              <div className="col-12"> 15 </div>
                              <div className="col-12"> 16 </div>
                              <div className="col-12"> 17 </div>
                              <div className="col-12"> 18 </div>
                            </div>
                            <div className="col-6 text-center fit-container">
                              <div className="col-12"> PAR </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][1]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][1]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][1]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][2]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][2]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][2]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][3]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][3]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][3]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][4]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][4]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][4]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][5]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][5]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][5]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][6]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][6]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][6]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][7]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][7]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][7]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][8]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][8]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][8]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][9]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][9]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][9]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][10]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][10]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][10]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][11]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][11]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][11]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][12]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][12]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][12]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][13]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][13]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][13]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][14]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][14]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][14]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][15]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][15]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][15]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][16]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][16]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][16]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][17]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][17]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][17]?.par}{" "}
                                </span>
                              </div>
                              <div className="col-12">
                                {" "}
                                <span
                                  style={{
                                    background: !types[
                                      playerStats[key][18]?.type
                                    ]
                                      ? ""
                                      : types[playerStats[key][18]?.type],
                                    width: "17px",
                                    height: "23px",
                                    borderRadius: "3px",
                                    display: "inline-block",
                                  }}
                                >
                                  {" "}
                                  {playerStats[key][18]?.par}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <div
                  className="row m-0 mt-4 mb-4"
                  style={{ borderBottom: "1px solid black" }}
                ></div>
                <div className="row">
                  <div className="col-6">
                    <div className="col-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#366159",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Albatross &gt;{" "}
                    </div>
                    <div className="col-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#539384",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Eagle
                    </div>
                    <div className="col-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#7DC2B2",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Birdie
                    </div>
                    <div className="col-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#C4C4C4",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Par
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="col-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#FFD99C",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Bogey
                    </div>
                    <div className="col-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#EDB861",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Double Bogey
                    </div>
                    <div className="col-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#DC7222",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Triple Bogey
                    </div>
                    <div className="rcol-12">
                      {" "}
                      <div
                        style={{
                          width: "13px",
                          height: "13px",
                          display: "inline-block",
                          background: "#B80000",
                          marginRight: "2px",
                          borderRadius: "2px",
                        }}
                      >
                        {" "}
                      </div>{" "}
                      Quadruple Bogey &lt;{" "}
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
