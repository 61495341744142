import React from "react";
import { Link } from "react-router-dom";

export default function LandingPage(props) {
  var aws_url = process.env.REACT_APP_AWS_ASSETS_URL;
  var { landingPageData } = props;
  return (
    <React.Fragment>
      {landingPageData.length > 0 ? (
        <div
          className="landing_page_container"
          style={{
            background: "linear-gradient(180deg, #020505 0%, #323131 132.68%)",
          }}
        >
          <div className="page_center" style={{ padding: "60px" }}>
            <div className="slider">
              {landingPageData.map((row, i) => {
                if (i < 3) {
                  return (
                    <Link
                      className="vertical_sport_link"
                      to={row.link}
                      style={{ borderRadius: "5px" }}
                    >
                      <div
                        className="landing_slide"
                        style={{
                          position: "relative",
                          marginBottom: "20px",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src={aws_url + row.cover}
                          style={{
                            width: "100%",
                            height: "330px",
                            borderRadius: "5px",
                          }}
                        />
                        <div className="text-block">
                          <p className="landing_title_text">
                            {" "}
                            {row.title_text}{" "}
                          </p>
                          <p className="landing_offer_text">
                            {" "}
                            {row.offer_text}{" "}
                          </p>
                          <p className="landing_offer_text">
                            {" "}
                            {row.entry_text}{" "}
                          </p>
                          <p className="landing_footer_text">
                            {" "}
                            {row.footer_text}{" "}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
