import React from "react";
import "./oddsImage.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function OddsImage() {
    const navigate = useNavigate()
  return (
    <div className="oddsimage-container">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-center py-4 gap-5">
        <div className="d-flex justify-content-center">
          <img src="assets/image/home/odds2.png" className="oddsimage-img"/>
        </div>
        <div className="d-flex flex-column text-white gap-2 align-items-center align-items-md-start ">
          <h2 className="w-100  text-center text-md-start font-weight-bold text-right oddsimage-title">
            Fantastic odds of winning
          </h2>
          <p className="w-100  text-center text-md-start">
          Limited entries enhance the odds of winning cash prizes!
          </p>
        </div>
      </div>
    </div>
  );
}

export default OddsImage;
