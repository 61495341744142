import React, { useState, useEffect, useRef, useContext } from "react";
import AllSports from "../home/AllSports";
import UserMenu from "../user-menu/UserMenu";
import SportButton from "../buttons/SportButton";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import { useThemeProvider } from "../../providers/theme/ThemeContext";
import ApiContext from "../../providers/Api/ApiContext";
import { signOut } from "aws-amplify/auth";
import UserContext from "../../providers/User/UserContext";

export default function Header(props) {
  const { api } = useContext(ApiContext);
  const { setUser } = useContext(UserContext);
  const { colors } = useThemeProvider();
  const [showMenu, setShowMenu] = useState(false);
  const [logo, setLogo] = useState("");
  const [openAllSports, setOpenAllSports] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef();
  const allSportsRef = useRef();
  const menuRef = useRef();
  var user_balance = 0;
  var [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const [headerLabels, setHeaderLabels] = useState([]);
  const [notificationTotal, setNotificationsTotal] = useState(0);
  const [userId, setUserId] = useState();
  const [loader, showLoader, hideLoader] = useLoading();

  props.balance !== undefined
    ? (user_balance = props.balance)
    : (user_balance = 0);
  const openAllSportsContainer = () => {
    setOpenAllSports(!openAllSports);
  };

  const openUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const getHeaderLabels = () => {
    var header_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "header",
    };
    showLoader();
    api
      .post(header_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setHeaderLabels(data);
          setLogo(response.data.Data.logo);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  useEffect(() => {
    getHeaderLabels();
    var btn_view_all_sports = document.querySelector(".btn_view_all_sports");
    var btn_view_all_sports_span = document.querySelector(
      ".btn_view_all_sports"
    )?.childNodes[0];
    var btn_user_menu = document.querySelector(".btn_user_menu");
    var btn_user_menu_child =
      document.querySelector(".btn_user_menu").childNodes[0];
    var btn_open_header_menu = document.querySelector(".btn_open_header_menu");
    var btn_open_header_menu_child = document.querySelector(
      ".btn_open_header_menu"
    ).childNodes[0];

    document.addEventListener("mousedown", (e) => {
      if (e.target !== btn_user_menu && e.target !== btn_user_menu_child) {
        if (userMenuRef.current && !userMenuRef.current.contains(e.target)) {
          setShowUserMenu(false);
        }
      }

      if (
        e.target !== btn_view_all_sports &&
        e.target !== btn_view_all_sports_span
      ) {
        if (allSportsRef.current && !allSportsRef.current.contains(e.target)) {
          setOpenAllSports(false);
        }
      }
      if (
        e.target !== btn_open_header_menu &&
        e.target !== btn_open_header_menu_child
      ) {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          setShowMenu(false);
        }
      }
      if (e.target.classList.contains("allsports_subtype")) {
        var league_id = e.target.getAttribute("data-leagueid");
        var sport_id = e.target.getAttribute("data-sportid");
        navigate("/sports_leagues/" + sport_id + "/" + league_id + "");
        setOpenAllSports(false);
      }
    });

    var loggedUserData = JSON.parse(localStorage.getItem("userData"));
    if (loggedUserData !== null) {
      var totalBalance =
        Number(loggedUserData.balance) + Number(loggedUserData.winning_balance);
      setBalance(totalBalance);
      var userId = loggedUserData.user_id;
      setUserId(userId);
      var total_notifications = loggedUserData.count_notification;
      if (total_notifications == "" || total_notifications == undefined) {
        total_notifications = 0;
      }
      setNotificationsTotal(total_notifications);
    }
  }, []);
  const logout = async () => {
    showLoader();
    localStorage.setItem("user_status", "unlogged");
    //Added setTimeout because of aws-amplify lib issue link: https://github.com/aws-amplify/amplify-js/issues/10198
    setTimeout(async () => {
      try {
        setUser(null);
        await signOut();
        // resetAxios();
        // setIsAuthenticated(false);
        hideLoader();
        navigate("/index");
      } catch (error) {
        hideLoader();
        console.log("error signing out: ", error);
      }
    });
  };
  return (
    <React.Fragment>
      <div
        className="header_container"
        style={{
          // backgroundImage: "url('/assets/image/concrete-wall.png')",
          backgroundColor: colors.primary,
        }}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="logo_container">
            <Link to="/home">
              <span>
                {logo !== undefined ? (
                  <img
                    src={`${process.env.REACT_APP_AWS_ASSETS_URL}tenants/${logo}`}
                    alt="brand-logo"
                    className="top_predictor_logo"
                  />
                ) : null}
              </span>
            </Link>
          </div>
          <div>
            <Link
              to="/notifications"
              className="btn_user_notifications_mobile position-relative"
            >
              <span className="total_notifications">
                <span>{notificationTotal}</span>
              </span>
              <img
                src="/assets/icons/notifications.svg"
                alt="notification-icon"
                className="navbar_icon notification_icon"
              />
            </Link>
            <button
              className="navbar-toggler btn_open_header_menu"
              type="button"
              onClick={() => setShowMenu(!showMenu)}
              aria-controls="header-navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="/assets/icons/navbar.svg" alt="navbar-icon" />
            </button>
          </div>
          <div
            ref={menuRef}
            style={showMenu ? { display: "block" } : { display: "none" }}
            className={"collapse navbar-collapse"}
            id="header-navbar"
          >
            <div className="navbar-nav">
              <div className="right_align_navbar">
                {/* <ButtonPrimary
                  classValue={
                    openAllSports
                      ? "btn_view_all_sports btn_primary header_btn active"
                      : "btn_view_all_sports btn_primary header_btn"
                  }
                  textValue={
                    !headerLabels.view_all_sports
                      ? "VIEW ALL SPORTS"
                      : headerLabels.view_all_sports.label_name
                  }
                  onClick={openAllSportsContainer}
                /> */}
                <div className="mobile_navbar">
                  <Link
                    to="/my_competitions"
                    className="btn_my_competitions header_btn"
                  >
                    <span className="header_label">
                      {!headerLabels.my_competitions
                        ? "MY COMPETITIONS"
                        : headerLabels.my_competitions.label_name}
                    </span>
                  </Link>
                  <Link
                    to="/private_competition/1/73"
                    className="btn_my_competitions header_btn"
                  >
                    <span className="header_label">
                      {!headerLabels.create_private_competition
                        ? "CREATE PRIVATE COMPETITION"
                        : headerLabels.create_private_competition.label_name}
                    </span>
                  </Link>
                  <Link
                    to="/club_fundraiser"
                    className="btn_my_competitions header_btn"
                  >
                    <span className="header_label">
                      {!headerLabels.club_fund_raiser
                        ? "CLUB FUNDRAISER"
                        : headerLabels.club_fund_raiser.label_name}
                    </span>
                  </Link>
                  <Link
                    to="/how_to_play"
                    className={"btn_my_competitions header_btn"}
                  >
                    <span className="header_label">
                      {!headerLabels.how_to_play
                        ? "How to Play"
                        : headerLabels.how_to_play.label_name}
                    </span>
                  </Link>
                  <Link to="/profile" className="header_btn btn_my_profile">
                    <span className="header_label">
                      {!headerLabels.my_profile
                        ? "My Profile"
                        : headerLabels.my_profile.label_name}
                    </span>
                  </Link>
                  <Link to="/account" className="header_btn btn_my_account">
                    <span className="header_label">
                      {!headerLabels.my_account
                        ? "My Account"
                        : headerLabels.my_account.label_name}
                    </span>
                  </Link>
                  <Link
                    to="/safer_gambling?type=gambling"
                    className="header_btn btn_gambling_safer"
                  >
                    <span className="header_label">
                      {!headerLabels.safer_gambling
                        ? "Safer Gambling"
                        : headerLabels.safer_gambling.label_name}
                    </span>
                  </Link>
                  <Link
                    className="header_btn btn_log_out"
                    onClick={(e) => logout()}
                  >
                    <span className="header_label">
                      {!headerLabels.logout
                        ? "Log Out"
                        : headerLabels.logout.label_name}
                    </span>
                  </Link>
                </div>
                <Link
                  to="/notifications"
                  className="btn_user_notifications position-relative"
                >
                  <span className="total_notifications">
                    <span>{notificationTotal}</span>
                  </span>
                  <img
                    src="/assets/icons/notifications.svg"
                    alt="notification-icon"
                    className="navbar_icon notification_icon"
                  />
                </Link>
                <button
                  ref={userMenuRef}
                  className="btn_user_menu"
                  onClick={openUserMenu}
                >
                  <img
                    src="/assets/icons/user.svg"
                    alt="user-icon"
                    className="navbar_icon"
                  />
                </button>
              </div>
            </div>
          </div>
        </nav>
        <UserMenu
          ref={userMenuRef}
          displayMenu={showUserMenu}
          labels={headerLabels}
        />
        <AllSports ref={allSportsRef} displayValue={openAllSports} />
        <div className="row balance_row mt-3">
          <div className="col-12 d-flex align-items-center header_balance">
            <div className="balance">
              {!headerLabels.header_balance
                ? "Balance"
                : headerLabels.header_balance.label_name}
              : &euro; {balance.toFixed(2)}
            </div>
          </div>
        </div>
        <div
          className={
            props.showSports ? "sports_button_container d-none" : "d-none"
          }
        >
          <div className="sports_btns">
            <SportButton text="FOOTBALL" classVal="btn_football" />
            <SportButton text="CRICKET" classVal="btn_cricket" />
          </div>
          <div className="sports_btns">
            <SportButton text="ICE HOCKEY" classVal="btn_icehockey" />
            <SportButton text="RUGBY" classVal="btn_rugby" />
          </div>
        </div>
      </div>
      {loader}
    </React.Fragment>
  );
}
