import React from "react";
import { useNavigate } from "react-router-dom";

const PlayHere = () => {
    const navigate = useNavigate()
  return (
    <div className="w-100 d-flex justify-content-center mt-5">
    <img src="assets/image/home/play1.png" style={{maxWidth:"200px"}} onClick={() => navigate("/sports_leagues/1")}/>
    </div>
  );
};

export default PlayHere;
