import React, { createContext, useContext, useState } from "react";
import ApiContext from "../Api/ApiContext";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { api } = useContext(ApiContext);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //Replcae App.js and Home.js my_profile API call with this function
  const getUser = async () => {
    try {
      setIsLoading(true);
      const response = await api.post(`/api/my_profile/my_profile`);
      if (response.status === 200) {
        var user_data = response.data.Data.user_profile;
        var userProfileData = {
          user_id: user_data.user_id,
          balance: user_data.balance,
          email: user_data.email,
          first_name: user_data.first_name,
          last_name: user_data.last_name,
          winning_balance: user_data.winning_balance,
          kyc_status: user_data.kyc_status,
          master_country_id: user_data.master_country_id,
          master_state_id: user_data.master_state_id,
          phone_no: user_data.phone_no,
          status: user_data.status,
          user_name: user_data.user_name,
          address1: user_data.address1,
          address2: user_data.address2,
          zip_code: user_data.zip_code,
          dob: user_data.dob,
          city: user_data.city,
          opt_in_email: user_data.opt_in_email,
          bank_statement_image: user_data.bank_statement_image_v2,
          passport_image: user_data.passport_image_v2,
          utility_bills_image: user_data.utility_bills_image_v2,
          default_currency: user_data.default_currency,
          count_notification: user_data.unread_notification,
          allow_kyc: user_data.allow_kyc,
        };
        setUser(userProfileData);
        localStorage.setItem("userData", JSON.stringify(userProfileData));
        return userProfileData;
      }
    } catch (err) {
      localStorage.removeItem("showReminder");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
