import React from 'react'

export default function SportButton(props) {
    const classes = `sport_button ${props.classVal}`
    return (
        <React.Fragment>
            <button className={classes}>
                <span>{props.text}</span>
            </button>
        </React.Fragment>
    )
}
