import React, { useState, useEffect } from "react";
import { redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Loading from "../components/loading/Loading";

const metaArray = [
  "/login",
  "/our_mission",
  "/faqs",
  "/how_to_play",
  "/forgot_password",
  "/rules",
  "/safer_gambling?type=description",
  "/challange",
  "/contact_us",
  "/privacy_policy",
  "/terms_conditions",
  "/our_mission",
];

const UseLoading = () => {
  const [loading, setLoading] = useState(false);
  const [meta, changeMeta] = useState(false);

  const getMeta = (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Top Predictor</title>
      <meta
        name="description"
        content="Sports Newest and Most Interactive Prediction Game"
      />
    </Helmet>
  );
  // useEffect(() => {
  //   if (metaArray.includes(history?.location?.pathname)) {
  //     changeMeta(true);
  //   }
  // }, [history?.location?.pathname]);
  useEffect(() => {
    if (metaArray.includes(window.location?.pathname)) {
      changeMeta(true);
    }
  }, [window.location?.pathname]);

  return [
    loading ? (
      <>
        <Loading />
        {meta && getMeta}
      </>
    ) : null,
    () => setLoading(true), // Show loader
    () => setLoading(false), // Show loader
    loading,
  ];
};
export default UseLoading;
