import React, { useState, useEffect, useContext } from "react";
import "../register/Register.css";
import "../buttons/Button.css";
import AccountHeader from "../account-header/AccountHeader";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../footer/Footer";
import LoginHeader from "../login/LoginHeader";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import { signUp } from "aws-amplify/auth";
import ApiContext from "../../providers/Api/ApiContext";

function encryptPassword(password) {
  return btoa(password);
}

export default function Register() {
  const { api } = useContext(ApiContext);
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [affiliate, setAffiliate] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [subCountryList, setSubCountryList] = useState([
    "England",
    "Scotland",
    "Ireland",
    "Wales",
  ]);
  const [leagueList, setLeagueList] = useState({
    England: [
      {
        league_id: "12",
        league_name: "CHAMPIONSHIP",
      },
      {
        league_id: "436",
        league_name: "EFL CUP",
      },
      {
        league_id: "3",
        league_name: "LEAGUE TWO",
      },
      {
        league_id: "76",
        league_name: "LEAGUE ONE",
      },
      {
        league_id: "73",
        league_name: "PREMIER LEAGUE",
      },
    ],
    Scotland: [
      {
        league_id: "83",
        league_name: "PREMIERSHIP",
      },
      {
        league_id: "51",
        league_name: "LEAGUE ONE",
      },
      {
        league_id: "85",
        league_name: "LEAGUE TWO",
      },
    ],
    Ireland: [
      {
        league_id: "585",
        league_name: "DIVISION 1",
      },
      {
        league_id: "190",
        league_name: "NIFL CHAMPIONSHIP",
      },
    ],
    Wales: [
      {
        league_id: "370",
        league_name: "CYMRU NORTH",
      },
      {
        league_id: "102",
        league_name: "CYMRU PREMIER",
      },
    ],
  });
  const [subCountry, setSubCountry] = useState("");
  const [league, setLeague] = useState();
  const [sport, setSport] = useState("");
  const [team, setTeam] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [terms, setTermsCheck] = useState(false);
  const [promotions, setPromotionsCheck] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [countries, setCountries] = useState([]);
  const [sports, setSports] = useState([]);
  const [teams, setTeams] = useState([]);
  const [validUsername, setValidUsername] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [validCountry, setValidCountry] = useState(false);
  const [allValid, setAllValid] = useState(false);
  const [loader, showLoader, hideLoader] = useLoading();
  const navigate = useNavigate();
  const [alert, alert_notify] = useAlert();
  const [successStatus, setSuccesStatus] = useState(false);
  const [registerLabels, setRegisterLabels] = useState([]);

  var registerClassVal = "";

  if (registerStatus) {
    registerClassVal =
      "btn_register_user btn_account_action btn_primary active";
  } else {
    registerClassVal = "btn_register_user btn_account_action btn_primary";
  }

  const showPasswordStatus = () => {
    setShowPassword(!showPassword);
  };

  const showRepeatPasswordStatus = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const getCountries = () => {
    var countries_url = `/api/auth/get_country`;
    const body = {};
    const headers = {};
    api.post(countries_url, body, { headers }).then((response) => {
      var data = response.data.Data.country;
      setCountries(data);
    });
  };

  const getSports = () => {
    var sports_url = `/api/second_landing_page/get_sports`;
    const body = {};
    const headers = {};
    api.get(sports_url, { headers }).then((response) => {
      setSports(response.data.data.sports);
    });
  };

  useEffect(() => {
    if (sport !== "") {
      if (country === "United Kingdom") {
        getTeams(sport, subCountry);
      } else {
        getTeams(sport, country);
      }
    }
  }, [country, sport, league]);

  const getTeams = (sport_id, country_name) => {
    var teams_url = `/api/auth/get_teams`;
    var body = {};
    if (sport_id == 1 && subCountryList.includes(country_name)) {
      body = {
        sport_id: sport_id,
        country: country_name,
        league_id: league,
      };
    } else {
      body = {
        sport_id: sport_id,
        country: country_name,
      };
    }
    const headers = {};
    api.post(teams_url, body, { headers }).then((response) => {
      setTeams(response.data.Data.teams);
    });
  };

  const getRegisterInputData = () => {
    var register_username = document.getElementById(
      "input_register_username"
    ).value;
    var register_email = document.getElementById("input_register_email").value;
    var register_firstname = document.getElementById(
      "input_register_firstname"
    ).value;
    var register_lastname = document.getElementById(
      "input_register_lastname"
    ).value;
    var register_password = document.getElementById(
      "input_register_password"
    ).value;
    var register_confirm_password = document.getElementById(
      "input_register_confirmed_password"
    ).value;
    var register_country = document.getElementById(
      "register_country_select"
    ).value;

    if (register_username !== "") {
      document
        .getElementById("input_register_username_required")
        .classList.add("d-none");
      setValidUsername(true);
    } else {
      document
        .getElementById("input_register_username_required")
        .classList.remove("d-none");
      setValidUsername(false);
    }

    if (register_email !== "") {
      document
        .getElementById("input_register_email_required")
        .classList.add("d-none");
      setValidEmail(true);
    } else {
      document
        .getElementById("input_register_email_required")
        .classList.remove("d-none");
      setValidEmail(false);
    }

    if (register_firstname !== "") {
      document
        .getElementById("input_register_firstname_required")
        .classList.add("d-none");
      setValidFirstName(true);
    } else {
      document
        .getElementById("input_register_firstname_required")
        .classList.remove("d-none");
      setValidFirstName(false);
    }

    if (register_lastname !== "") {
      document
        .getElementById("input_register_lastname_required")
        .classList.add("d-none");
      setValidLastName(true);
    } else {
      document
        .getElementById("input_register_lastname_required")
        .classList.remove("d-none");
      setValidLastName(false);
    }

    if (register_password !== "") {
      document
        .getElementById("input_register_password_required")
        .classList.add("d-none");
      setValidPassword(true);
    } else {
      document
        .getElementById("input_register_password_required")
        .classList.remove("d-none");
      setValidPassword(false);
    }

    if (register_confirm_password !== "") {
      document
        .getElementById("input_register_confirm_required")
        .classList.add("d-none");
      setValidConfirmPassword(true);
    } else {
      document
        .getElementById("input_register_confirm_required")
        .classList.remove("d-none");
      setValidConfirmPassword(false);
    }

    if (register_country !== "0") {
      document
        .getElementById("input_register_country_required")
        .classList.add("d-none");
      setValidCountry(true);
    } else {
      document
        .getElementById("input_register_country_required")
        .classList.remove("d-none");
      setValidCountry(false);
    }

    if (validPassword && validConfirmPassword) {
      if (
        encryptPassword(register_password) ===
        encryptPassword(register_confirm_password)
      ) {
        setAllValid(true);
      } else {
        setAllValid(false);
      }
    }
    if (
      validUsername &&
      validEmail &&
      validFirstName &&
      validLastName &&
      validPassword &&
      validConfirmPassword &&
      validCountry
    ) {
      setAllValid(true);
    } else {
      setAllValid(false);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    var register_username = document
      .getElementById("input_register_username")
      .value.trim();
    var register_email = document.getElementById("input_register_email").value;
    var register_firstname = document.getElementById(
      "input_register_firstname"
    ).value;
    var register_lastname = document.getElementById(
      "input_register_lastname"
    ).value;
    var register_password = document.getElementById(
      "input_register_password"
    ).value;
    var register_confirm_password = document.getElementById(
      "input_register_confirmed_password"
    ).value;
    var register_country_code = document
      .getElementById("register_country_select")
      .selectedOptions[0].getAttribute("data-id");
    var register_phone_prefix = document
      .getElementById("register_country_select")
      .selectedOptions[0].getAttribute("data-phoneprefix");

    // cache username on the client
    localStorage.setItem("cached_username", register_username);
    if (isValidated()) {
      showLoader();
      var body = {
        affiliate_code: affiliate === null ? "" : affiliate,
        country_code: register_country_code,
        email: register_email,
        first_name: register_firstname,
        last_name: register_lastname,
        opt_in_email: "0",
        phone_no: "+" + register_phone_prefix,
        username: register_username,
        favourite_sport_id: sport === "" ? "" : sport,
        favourite_team_id: team === "" ? "" : team,
      };
      try {
        const res = await signUp({
          username: register_username,
          password: register_password,
          options: {
            userAttributes: {
              email: register_email, // optional
              "custom:username": register_username,
            },
            validationData: {
              email: register_email,
            },
            clientMetadata: body,
          },
        });
        hideLoader();
        setSuccesStatus(true);
        alert_notify("success", "Registred successfully.");
      } catch (err) {
        console.log(err);
        hideLoader();
        if (err?.message.includes("UsernameExistsException")) {
          alert_notify("error", "Username is already used.");
        }
        if (err?.message.includes("EmailExistsException")) {
          alert_notify("error", "Email is already used.");
        }
      }
    }
  };

  const getRegisterLabels = () => {
    var register_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "register form",
    };
    api
      .post(register_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setRegisterLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setAffiliate(searchParams.get("affiliate"));
    getRegisterLabels();
    getCountries();
    getSports();
    localStorage.removeItem("userData");
    localStorage.removeItem("showReminder");
    setSuccesStatus(false);
  }, []);

  const showError = (field) => {
    let message = "";
    if (field.toLowerCase() === "password") {
      message =
        "Password is Invalid. Please Enter 8 digit Alpha Numeric password which also includes 1 special chracter";
    } else if (field.toLowerCase() === "confirm password") {
      message = "Confirm Password does not matched";
    } else if (field.toLowerCase() === "email") {
      message = "Please enter a valid email address";
    } else if (field.toLowerCase() === "country") {
      message = "Please select country from dropdown";
    } else if (field.toLowerCase() === "name") {
      message = "First Name and Last Name is required";
    } else if (field.toLowerCase() === "terms") {
      message = "Please agree to our Terms below.";
    } else message = field + " is not valid";

    alert_notify("error", message);
  };

  const isValidated = () => {
    if (
      username.length > 2 &&
      username.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/gi) === null
    ) {
      if (
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        if (firstName !== "" || lastName !== "") {
          if (
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
              password
            )
          ) {
            if (confirmPassword === password) {
              if (country !== "") {
                if (terms) {
                  return true;
                } else {
                  showError("Terms");
                  return false;
                }
              } else {
                showError("Country");
                return false;
              }
            } else {
              showError("Confirm Password");
              return false;
            }
          } else {
            showError("Password");
            return false;
          }
        } else {
          showError("Name");
          return false;
        }
      } else {
        showError("Email");
        return false;
      }
    } else {
      showError("User Name");
      return false;
    }

    //  return (
    //    confirmPassword === password &&
    //    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
    //    username.length > 2 &&
    //    password.length > 7
    //  );
  };
  return (
    <React.Fragment>
      <LoginHeader loginValue={false} />

      <AccountHeader
        headerValue={
          !registerLabels.reg_form_page_title
            ? "REGISTER AN ACCOUNT"
            : registerLabels.reg_form_page_title.label_name
        }
        firstTextValue="With an account you can participate in the NEWEST & MOST INTERACTIVE PREDICTION GAME"
        secondTextValue=""
      />

      <div className="register_container primary-background">
        {successStatus ? (
          <SuccesNotification
            title="Thank you for signing up"
            content={
              "We have sent you a verification email. Please check your spam folder if you don’t receive it. Verification link is valid for 24 hours. You can still verify your account after 24 hours by loggin in to your account."
            }
          />
        ) : (
          <div className="page_content_center">
            <div className="row justify-content-center mt-row">
              <div className="col-12 col-lg-5 col-xl-5 mt-2 d-flex align-items-center justify-content-center">
                <span className="already_have_acc_text text-white">
                  {!registerLabels.reg_form_already_member_label
                    ? " ALREADY HAVE AN ACCOUNT?"
                    : registerLabels.reg_form_already_member_label.label_name}
                </span>
                <Link
                  to="/login"
                  className="btn_already_have_acc text-white"
                  style={{ textTransform: "uppercase" }}
                >
                  {!registerLabels.reg_form_login_label
                    ? "LOGIN"
                    : registerLabels.reg_form_login_label.label_name}
                </Link>
              </div>
            </div>
            <form id="register_form" onSubmit={handleRegisterSubmit}>
              <div className="row mt-row p-4">
                <div className="col-12 col-xl-6 col-lg-6 my-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_username"
                    name="register_username"
                    placeholder={
                      !registerLabels.reg_form_username_label
                        ? "*Username"
                        : "*" +
                          registerLabels.reg_form_username_label.label_name
                    }
                    value={username}
                    onChange={(e) =>
                      setUsername(
                        e.target.value.replaceAll(
                          /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/gi,
                          ""
                        )
                      )
                    }
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_username_required"
                  >
                    {!registerLabels.err_reg_form_username_req
                      ? " Username is required"
                      : registerLabels.err_reg_form_username_req.label_name}
                  </span>
                </div>
                <div className="col-12 col-xl-6 col-lg-6  my-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_email"
                    name="register_email"
                    placeholder={
                      !registerLabels.reg_form_email_label
                        ? " *Email"
                        : "*" + registerLabels.reg_form_email_label.label_name
                    }
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_email_required"
                  >
                    {!registerLabels.err_reg_form_email_req
                      ? " Email is required"
                      : registerLabels.err_reg_form_email_req.label_name}
                  </span>
                </div>
                <div className="col-12 col-xl-6 col-lg-6  my-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_firstname"
                    name="register_firstname"
                    placeholder={
                      !registerLabels.reg_form_first_name_label
                        ? "* First name"
                        : "*" +
                          registerLabels.reg_form_first_name_label.label_name
                    }
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_firstname_required"
                  >
                    {!registerLabels.err_reg_form_fname_req
                      ? "First name is required"
                      : registerLabels.err_reg_form_fname_req.label_name}
                  </span>
                </div>
                <div className="col-12 col-xl-6 col-lg-6  my-2">
                  <input
                    type="text"
                    className="register_input"
                    id="input_register_lastname"
                    name="register_lastname"
                    placeholder={
                      !registerLabels.reg_form_last_name_label
                        ? "* Last name"
                        : "*" +
                          registerLabels.reg_form_last_name_label.label_name
                    }
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_lastname_required"
                  >
                    {!registerLabels.err_reg_form_lname_req
                      ? "Last name is required"
                      : registerLabels.err_reg_form_lname_req.label_name}
                  </span>
                </div>
                <div className="col-12 col-xl-6 col-lg-6  my-2 position-relative">
                  <span className="show_password" onClick={showPasswordStatus}>
                    <img
                      src="assets/icons/show.svg"
                      alt="show-icon"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="register_input"
                    id="input_register_password"
                    name="register_password"
                    placeholder={
                      !registerLabels.reg_form_pwd_label
                        ? "*Password"
                        : "*" + registerLabels.reg_form_pwd_label.label_name
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_password_required"
                  >
                    {!registerLabels.err_reg_form_pwd_req
                      ? "Password is required"
                      : registerLabels.err_reg_form_pwd_req.label_name}
                  </span>
                </div>
                <div className="col-12 col-xl-6 col-lg-6  my-2 position-relative">
                  <span
                    className="show_password"
                    onClick={showRepeatPasswordStatus}
                  >
                    <img
                      src="assets/icons/show.svg"
                      alt="show-icon"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <input
                    type={showRepeatPassword ? "text" : "password"}
                    className="register_input"
                    id="input_register_confirmed_password"
                    name="register_confirm_password"
                    placeholder={
                      !registerLabels.reg_form_cnf_pwd_label
                        ? "*Confirm Password"
                        : "*" + registerLabels.reg_form_cnf_pwd_label.label_name
                    }
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span
                    className="required_message d-none"
                    id="input_register_confirm_required"
                  >
                    {!registerLabels.err_reg_form_cnf_pwd_req
                      ? "Enter the password again for confirmation"
                      : registerLabels.err_reg_form_cnf_pwd_req.label_name}
                  </span>
                </div>
                <div className="col-12 col-xl-6 col-lg-6  my-2">
                  <select
                    className="register_input"
                    placeholder={
                      !registerLabels.reg_form_country_label
                        ? "*Country"
                        : "*" + registerLabels.reg_form_country_label.label_name
                    }
                    id="register_country_select"
                    name="register_country"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    style={{ textTransform: "capitalize" }}
                  >
                    <option value="0">
                      *
                      {!registerLabels.reg_form_country_label
                        ? "Country"
                        : registerLabels.reg_form_country_label.label_name}
                    </option>
                    {countries.map((row, index) => {
                      return (
                        <option
                          data-value={index}
                          data-id={row.master_country_id}
                          key={index}
                          data-phoneprefix={row.phone_prefix}
                        >
                          {row.country_name}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    className="required_message d-none"
                    id="input_register_country_required"
                  >
                    {!registerLabels.err_reg_form_country_req
                      ? "Country is required"
                      : registerLabels.err_reg_form_country_req.label_name}
                  </span>
                </div>
                {/* {country === "United Kingdom" ? (
                  <div className="col-12 col-xl-6 col-lg-6  my-2">
                    <select
                      className="register_input"
                      disabled={country === ""}
                      placeholder={
                        !registerLabels.reg_form_sport_label
                          ? "What is your exact country from United Kingdom ?"
                          : registerLabels.reg_form_sport_label.label_name
                      }
                      id="register_sport_select"
                      name="register_sport"
                      value={subCountry}
                      onChange={(e) => {
                        if (country === "United Kingdom") {
                          setSubCountry(e.target.value);
                        }
                      }}
                      style={{ textTransform: "capitalize" }}
                    >
                      <option value="0">
                        {!registerLabels.reg_form_sport_label
                          ? "Select you country from United Kingdom ?"
                          : registerLabels.reg_form_sport_label.label_name}
                      </option>
                      {subCountryList.map((country) => {
                        return (
                          <option value={country} data-id={1} key={1}>
                            {country}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : null}
                <div className="col-12 col-xl-6 col-lg-6  my-2">
                  <select
                    className="register_input"
                    disabled={country === ""}
                    placeholder={
                      !registerLabels.reg_form_sport_label
                        ? "What is your favourite sport ?"
                        : registerLabels.reg_form_sport_label.label_name
                    }
                    id="register_sport_select"
                    name="register_sport"
                    value={sport}
                    onChange={(e) => {
                      setSport(e.target.value);
                    }}
                    style={{ textTransform: "capitalize" }}
                  >
                    <option value="0">
                      {!registerLabels.reg_form_sport_label
                        ? "What is your favourite sport ?"
                        : registerLabels.reg_form_sport_label.label_name}
                    </option>
                    {sports.map((row, index) => {
                      return (
                        <option
                          value={row.sport_id}
                          data-id={row.sport_id}
                          key={index}
                        >
                          {row.sport_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {country === "United Kingdom" && sport == "1" ? (
                  <div className="col-12 col-xl-6 col-lg-6  my-2">
                    <select
                      className="register_input"
                      disabled={country === ""}
                      placeholder={
                        !registerLabels.reg_form_sport_label
                          ? "What is your favourite football league ?"
                          : registerLabels.reg_form_sport_label.label_name
                      }
                      id="register_sport_select"
                      name="register_sport"
                      value={league}
                      onChange={(e) => {
                        setLeague(e.target.value);
                      }}
                      style={{ textTransform: "capitalize" }}
                    >
                      <option value="0">
                        {!registerLabels.reg_form_sport_label
                          ? "What is your favourite football league ?"
                          : registerLabels.reg_form_sport_label.label_name}
                      </option>
                      {leagueList[subCountry].map((league, index) => {
                        return (
                          <option
                            value={league.league_id}
                            data-id={league.league_name}
                            key={index}
                          >
                            {league.league_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : null}
                <div className="col-12 col-xl-6 col-lg-6  my-2">
                  <select
                    className="register_input"
                    disabled={sport === ""}
                    placeholder={
                      !registerLabels.reg_form_team_label
                        ? "Team"
                        : registerLabels.reg_form_team_label.label_name
                    }
                    id="register_team_select"
                    name="register_team"
                    value={team}
                    onChange={(e) => setTeam(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  >
                    <option value="0">
                      {!registerLabels.reg_form_team_label
                        ? "What is your favourite team ?"
                        : registerLabels.reg_form_team_label.label_name}
                    </option>
                    {teams.map((row, index) => {
                      return (
                        <option
                          data-value={row.team_id}
                          value={row.team_id}
                          data-id={row.team_id}
                          key={index}
                          data-phoneprefix={row.phone_prefix}
                        >
                          {row.team_name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
              </div>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2 ">
                  <div className="d-flex align-items-center register_terms">
                    <input
                      type="checkbox"
                      className="input_register_accept_terms register_checkbox"
                      style={{ width: "23px" }}
                      value={terms}
                      onClick={(e) => {
                        setTermsCheck(!terms);
                      }}
                    />
                    <span className="text-white">
                      {!registerLabels.reg_form_agree_tc_label
                        ? "I agree to the TOP PREDICTOR"
                        : registerLabels.reg_form_agree_tc_label.label_name}
                      <Link to="/terms_conditions">
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#fff",
                            margin: "0 5px",
                          }}
                        >
                          {" "}
                          terms and conditions{" "}
                        </b>
                      </Link>{" "}
                      and
                      <Link to="/privacy_policy">
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#fff",
                            margin: "0 5px",
                          }}
                        >
                          {" "}
                          privacy policy{" "}
                        </b>
                      </Link>
                      .
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-5 col-xl-5 mt-2 d-flex justify-content-end"></div>
              </div>
              <div className="row justify-content-center mt-row">
                <div className="col-12 col-lg-5 col-xl-5 mt-2">
                  <div className="d-flex align-items-center register_terms">
                    <input
                      type="checkbox"
                      className="input_register_agree_marketing_terms register_checkbox"
                      value={promotions}
                      onClick={(e) => {
                        // setPromotionsCheck(!promotions);
                      }}
                    />
                    <span className="text-white">
                      {!registerLabels.reg_form_marketing_label
                        ? "By ticking this box, I agree to marketing and promotional communication from TOP PREDICTOR."
                        : registerLabels.reg_form_marketing_label.label_name}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-5 col-xl-5 d-flex register_now_container">
                  <ButtonPrimary
                    classValue={registerClassVal}
                    textValue={
                      !registerLabels.reg_form_submit_btn
                        ? "Register Now"
                        : registerLabels.reg_form_submit_btn.label_name
                    }
                    form="register_form"
                    type="submit"
                    onClick={getRegisterInputData}
                    // d={!isValidated()}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
