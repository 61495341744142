import React from "react";
import ReactDOM from "react-dom";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsconfig from "./config/aws";
import { ApiProvider } from "./providers/Api/ApiContext";
import { UserProvider } from "./providers/User/UserContext";
const root = ReactDOMClient.createRoot(document.getElementById("root"));
Amplify.configure(awsconfig);
root.render(
  <ApiProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </ApiProvider>
);
reportWebVitals();
