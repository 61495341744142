import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "../my-competitions/Competitions.css";
import ProfileAccount from "../account/ProfileAccount";
import { useNavigate, useParams } from "react-router-dom";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import ApiContext from "../../providers/Api/ApiContext";

export default function Leaderboard() {
  const { api, soccerApi } = useContext(ApiContext);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [matches, setMatches] = useState({
    matches_data: { remaining_matches: "", total_matches: "" },
  });
  const [status, setStatus] = useState("");
  const [league, setLeague] = useState("");
  const [loading, setLoading] = useState(true);
  const [sportId, setSportId] = useState(0);
  const navigate = useNavigate();
  const [totalLeaderboards, setTotalLeaderboards] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [leaderboardLabels, setLeaderboardLabels] = useState([]);
  const { sport, uid } = useParams();
  var leaderboard_status = "";
  const [loader, showLoader, hideLoader] = useLoading();
  const [alert, alert_notify] = useAlert();

  var total_pages = 0;
  var pages_length = 0;
  var pages_array = [];

  total_pages = totalLeaderboards;
  pages_length = Math.ceil(total_pages / 10);

  for (var i = 0; i < pages_length; i++) {
    pages_array.push(i + 1);
  }

  const getLeaderboardData = (data) => {
    if (sport == 1) {
      showLoader();
      soccerApi
        .get(`/gamecard/get_leaderboard/${uid}`)
        .then((response) => {
          hideLoader();
          if (response.status === 200) {
            var result = response.data.data.result;
            var remaining_matches = response.data.data.remaining_matches;
            var total_matches = response.data.data.total_matches;
            var status = response.data.data.status;
            setSportId(response.data.data.sport_id);
            setLeaderboardData(result);
            setTotalLeaderboards(total_matches);

            setMatches({
              ...matches,
              matches_data: {
                remaining_matches: remaining_matches,
                total_matches: total_matches,
              },
            });

            if (result.length > 0) {
              setLeague(result[0].league_name);
            }
            setStatus(status);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      var leaderboard_url = "/api/leaderboard/get_leaderboard";

      if (!data) {
        var body = {
          contest_uid: uid,
        };
      } else {
        var body = data;
      }

      showLoader();
      api
        .post(leaderboard_url, body)
        .then((response) => {
          hideLoader();
          if (response.status === 200) {
            var result = response.data.Data.result;
            var remaining_matches = response.data.Data.remaining_matches;
            var total_matches = response.data.Data.total_matches;
            var status = response.data.Data.status;
            setSportId(response.data.Data.sport_id);
            setLeaderboardData(result);
            setTotalLeaderboards(total_matches);

            setMatches({
              ...matches,
              matches_data: {
                remaining_matches: remaining_matches,
                total_matches: total_matches,
              },
            });

            if (result.length > 0) {
              setLeague(result[0].league_name);
            }

            setStatus(status);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Error);
          }
          hideLoader();
        });
    }
  };

  const goToThisGamecardPage = (e) => {
    var path = e.currentTarget.getAttribute("data-path");
    var uid = e.currentTarget.getAttribute("data-uid");
    if (sportId == 1) {
      navigate("/soccer/" + path + "/" + sportId + "/" + uid + "");
    } else {
      navigate("/" + path + "/" + sportId + "/" + uid + "");
    }
  };

  const fetchLeaderboardData = (e) => {
    var index = e.target.getAttribute("data-id");
    var mode = e.currentTarget.getAttribute("data-mode");
    if (!mode) {
      var data = {
        contest_uid: uid,
        current_page: index,
      };
      getLeaderboardData(data);
      setActivePage(Number(index));
    } else {
      var active_index = document
        .querySelector(".page_number.active")
        .getAttribute("data-id");
      if (mode === "prev") {
        if (active_index > 1) {
          var new_index = Number(active_index) - 1;
          var data = {
            contest_uid: uid,
            current_page: new_index,
          };
          getLeaderboardData(data);
          setActivePage(new_index);
        } else {
          var new_index = 1;
        }
      } else {
        if (active_index < pages_length) {
          var new_index = Number(active_index) + 1;
          var data = {
            contest_uid: uid,
            current_page: new_index,
          };
          getLeaderboardData(data);
          setActivePage(new_index);
        } else {
          var new_index = pages_length;
        }
      }
    }
  };

  const getLeaderboardLabels = (page_name) => {
    var leaderboard_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: page_name,
    };
    api
      .post(leaderboard_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setLeaderboardLabels(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("Inside leaderbaord");

    getLeaderboardLabels("leaderboard");
    getLeaderboardData();
  }, []);

  if (status === "0") {
    leaderboard_status = "Inactive";
  } else if (status === "1") {
    leaderboard_status = "Active";
  } else if (status === "2") {
    leaderboard_status = "Live";
  } else if (status === "3") {
    leaderboard_status = "Prize Distribution Done";
  } else if (status === "4") {
    leaderboard_status = "Canceled";
  } else if (status === "5") {
    leaderboard_status = "Completed";
  } else {
    leaderboard_status = "Active";
  }

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !leaderboardLabels.leaderboard_my_competitions
            ? "My Competitions"
            : leaderboardLabels.leaderboard_my_competitions.label_name
        }
      />
      <div className="leaderboard_container primary-background">
        <div className="page_content_center">
          <div className=" m-0 leaderboard_header m-0 mt-5 align-items-center">
            <div
              className="d-inline-flex align-items-center justify-content-between gamecard_tab competition_tab "
              data-uid={uid}
              data-path="gamecard"
              onClick={goToThisGamecardPage}
              style={{ cursor: "pointer" }}
            >
              <span
                className="text-white"
                style={{ textTransform: "uppercase" }}
              >
                {!leaderboardLabels.leaderboard_gamecard_label
                  ? "Gamecard"
                  : leaderboardLabels.leaderboard_gamecard_label.label_name}
              </span>
              <span className="leaderboard_icon" style={{ marginRight: "0" }}>
                <img src="/assets/icons/gamecard.svg" alt="leaderboard-icon" />
              </span>
            </div>
            <div className="d-inline-flex align-items-center justify-content-between active_tab leaderboard_tab competition_tab">
              <span
                className="text-white"
                style={{ textTransform: "uppercase" }}
              >
                {!leaderboardLabels.leaderboard_leaderboard_label
                  ? "Gamecard"
                  : leaderboardLabels.leaderboard_leaderboard_label.label_name}
              </span>
              <span className="leaderboard_icon" style={{ marginRight: "0" }}>
                <img
                  src="/assets/icons/leaderboard.svg"
                  alt="leaderboard-icon"
                />
              </span>
            </div>
          </div>
          <div className="row mt-4 leaderboard_row leaderboard_status align-items-center">
            <div
              className="col-12 col-lg-4 col-xl-4"
              style={{ textTransform: "capitalize" }}
            >
              {!leaderboardLabels.leaderboard_status_label
                ? "Status"
                : leaderboardLabels.leaderboard_status_label.label_name}
              : {leaderboard_status}
            </div>
            <div className="col-12 col-lg-4 col-xl-4 leaderboard_league">
              <span className="leaderboard_league_icon">
                <img
                  className="public_icon"
                  src="/assets/icons/private.svg"
                  alt="public-icon"
                />
              </span>
              <span className="leaderbaord_league_name">{league}</span>
            </div>
            <div
              className="col-12 col-lg-4 col-xl-4 leaderboard_remaining_matches"
              style={{ textTransform: "capitalize" }}
            >
              {!leaderboardLabels.leaderboard_matches_rem_label
                ? "Matches Remaining"
                : leaderboardLabels.leaderboard_matches_rem_label.label_name}
              : {matches.matches_data.remaining_matches}/
              {matches.matches_data.total_matches}
            </div>
          </div>
          <div
            className="row mt-2 leaderboard_row leaderboard_info align-items-center"
            style={{ height: "45px" }}
          >
            <div
              className="col-4 col-xl-3 col-lg-3"
              style={{ textTransform: "capitalize" }}
            >
              {!leaderboardLabels.leaderboard_username_label
                ? "Username"
                : leaderboardLabels.leaderboard_username_label.label_name}
            </div>
            <div
              className="col-3 col-xl-3 col-lg-3"
              style={{ textTransform: "capitalize" }}
            >
              {!leaderboardLabels.leaderboard_points_label
                ? "Points"
                : leaderboardLabels.leaderboard_points_label.label_name}
            </div>
            <div
              className="col-3 col-xl-3 col-lg-3"
              style={{ textTransform: "capitalize" }}
            >
              {!leaderboardLabels.leaderboard_position_label
                ? "Position"
                : leaderboardLabels.leaderboard_position_label.label_name}
            </div>
            <div
              className="col-2 col-xl-3 col-lg-3"
              style={{ textTransform: "capitalize" }}
            >
              {!leaderboardLabels.leaderboard_prize_label
                ? "Prize"
                : leaderboardLabels.leaderboard_prize_label.label_name}
            </div>
          </div>
          <div className="leaderboard_table leaderboard_row">
            {leaderboardData.map((row, index) => {
              return (
                <div className="row m-0 mt-2 align-items-center" key={index}>
                  <div className="col-4 col-xl-3 col-lg-3">{row.user_name}</div>
                  <div className="col-3 col-xl-3 col-lg-3">
                    {row.total_score}
                  </div>
                  <div className="col-3 col-xl-3 col-lg-3">{row.rank}</div>
                  <div className="col-2 col-xl-3 col-lg-3">-</div>
                </div>
              );
            })}
          </div>
          {pages_array.length > 1 ? (
            <div className="pagination d-flex justify-content-end mt-3">
              <div
                className="page"
                data-mode="prev"
                onClick={fetchLeaderboardData}
              >
                <span>
                  <img src="/assets/icons/arrow-left.svg" alt="arrow-left" />
                </span>
              </div>
              {pages_array.map((row, index) => {
                return (
                  <div
                    className={
                      activePage === index + 1
                        ? "page page_number active"
                        : "page page_number "
                    }
                    key={index}
                    data-id={index + 1}
                    onClick={fetchLeaderboardData}
                  >
                    {row}
                  </div>
                );
              })}
              <div
                className="page"
                data-mode="next"
                onClick={fetchLeaderboardData}
              >
                <span>
                  <img src="/assets/icons/arrow-right.svg" alt="arrow-right" />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
      {loader}
      {alert}
    </React.Fragment>
  );
}
